// Packages
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import Stage from 'components/UI/Stage';
import Duration from 'components/Duration';
import SelectList from 'components/UI/SelectList';

// Assets
import './styles.css';

// Others
import Api from 'other/Api';


const AccountStatementScreen = () => {

    // URL Parameters
    const { accountId } = useParams();


    // State
    const [account, setAccount] = useState([]);
    const [loading, setLoading] = useState(true);

    const [statement, setStatement] = useState({});
    const [statementLoading, setStatementLoading] = useState(true);

    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    // Select options
    const [yearOptions, setYearOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([]);



    useEffect(() => {

        // Set month options

        setMonthOptions([
            {id: 1,
            name: 'January'
            },
            {id: 2,
            name: 'February'
            },
            {id: 3,
            name: 'March'
            },
            {id: 4,
            name: 'April'
            },
            {id: 5,
            name: 'May'
            },
            {id: 6,
            name: 'June'
            },
            {id: 7,
            name: 'July'
            },
            {id: 8,
            name: 'August'
            },
            {id: 9,
            name: 'September'
            },
            {id: 10,
            name: 'October'
            },
            {id: 11,
            name: 'November'
            },
            {id: 12,
            name: 'December'
            }
        ]);

        setMonth((dayjs().month()) + 1);

    }, []);

    useEffect(() => {

        // Set year options
        let options = [];
        let year = dayjs().format('YYYY');
        for(let i=1; i<11; i++) {

            options.push({id: i, name: year});
            year = (parseInt(year) - 1).toString();
        }

        setYearOptions(options);
        setYear(1);

    }, []);



    const downloadPdf = () => {
        Api.accounts.statementPdf(accountId, {
            params: {
                'year': year,
                'month': month,
            },
            success: (response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', account.name + '.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        });
    }


    useEffect(() => {

        Api.accounts.get(accountId, {
            success: (response) => {
                setAccount(response);
            },
            after: () => {
                setLoading(false);
            }
        });

        

    }, [accountId]);



    useEffect(() => {

        Api.accounts.statement(accountId, {
            params: {
                'year': year,
                'month': month,
            },
            success: (response) => {
                setStatement(response);
            },
            after: () => {
                setStatementLoading(false);
            }
        });

    }, [accountId, year, month]);



    if ( loading )
    {
        return <Loading/>;
    }


    return (
        <>
            <ScreenTitle title={`Statement - ${account.name}`} breadcrumb={[
                {label: 'Accounts', url: '/accounts'},
                {label: account.name, url: '/accounts/' + account.id },
                {label: 'Statement', url: '/accounts/' + account.id + '/statement' }
            ]}>
                <Button onClick={() => {
                    downloadPdf()
                }} label="Download PDF" colour="blue"/>
            </ScreenTitle>

            <h3 className='statement__subtitle'>Filters:</h3>

            <div className='statement__filters'>

				<div className="statement__filters__filter">
					<label className="statement__filters__filter__label form__field__label">Month</label>
					<SelectList options={monthOptions} blank={false} onChange={(e) => {
						setMonth(e.target.value);
					}} value={month} />
				</div>

                <div className="statement__filters__filter">
					<label className="statement__filters__filter__label form__field__label">Year</label>
					<SelectList options={yearOptions} blank={false} onChange={(e) => {
						setYear(e.target.value);
					}} value={year} />
				</div>
				
			</div>


            { (! statementLoading) && statement.jobs && statement.jobs.length > 0 &&

                <table className="table">
                <thead>
                <tr>
                    <th>Description</th>
                    <th className="r">Date Received</th>
                    <th>Contact</th>
                    <th className="c">Status</th>
                    <th className="r">Time spent</th>
                </tr>
                </thead>
                <tbody>

                {statement.jobs.map((job) => {

                    return (
                        <tr key={job.id}>
                            <td><Link to={`/jobs/${job.id}`}>{job.name}</Link></td>
                            <td className="r">{dayjs(job.created_at).format('DD/MM/YYYY')}</td>
                            <td>{job.person ? job.person.firstname : ''} {job.person ? job.person.lastname : ''}</td>
                            <td><Stage {...job.stage}/></td>
                            <td className="r"><Duration seconds={job.seconds_taken}/></td>
                        </tr>
                    );
                })}


                </tbody>
                <tfoot>
                <tr>
                    <td colSpan="4" className="r"><strong>Total:</strong></td>
                    <td className="r"><strong><Duration seconds={statement.total_time}/></strong></td>
                </tr>
                </tfoot>
                </table>
            }

            { ! statementLoading && ! statement.jobs && 
            
            <h4 className='statement_noJobsMessage'>There are no active jobs in this period.</h4>
            
            }


        </>
    );
}

export default AccountStatementScreen;