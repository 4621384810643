// Packages
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';
import { useAuth0 } from '@auth0/auth0-react';

// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';
import Pipeline from 'components/Pipeline';

// Context
import { useSnackbar } from 'context/Snackbar';

// Other
import Api from 'other/Api';

// Assets
import "react-datepicker/dist/react-datepicker.css";


const JobForm = ({ jobId }) => {

	// Import context from Auth0
	const {	user } = useAuth0();

	// URL parameters
	const [ queryParams ] = useSearchParams();

	const { showSnackbar } = useSnackbar();

	// State - Form fields
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [accountId, setAccountId] = useState(queryParams.get('account_id')??'');
	const [contractId, setContractId] = useState(queryParams.get('contract_id')??'');
	const [personId, setPersonId] = useState(queryParams.get('person_id')??'');
	const [stageId, setStageId] = useState(1);
    const [userId, setUserId] = useState('');
	// const [price, setPrice] = useState('');
	// const [poNumber, setPoNumber] = useState('');
    const [dueDate, setDueDate] = useState('');
    const [priority, setPriority] = useState(2);
    const [billingStatus, setBillingStatus] = useState('');


	// state - Select options
	const [ accountOptions, setAccountOptions] = useState(null);
	const [ contractOptions, setContractOptions] = useState([]);
	const [ personOptions, setPersonOptions] = useState([]);
    const [ userOptions, setUserOptions] = useState([]);
	const billingStatusOptions = [
		{id: 'not applicable', name: 'Not Applicable'},
		{id: 'contracted', name: 'Contracted'},
		{id: 'pending', name: 'Pending'},
		{id: 'billed', name: 'Billed'}
	];


	// State
	const [loading, setLoading] = useState(accountId);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	useEffect(() => {

		if( user ) {

			let currentUser = userOptions.find(optionUser => optionUser.email == user.email);

			if(currentUser) {

				setUserId(currentUser.id);
			}
		}
	}, [user, userOptions])

	
	/**
	 * Form submit
	 */
	const submit = (duplicate) => {

		/**	
		 * Build generic request to either create or update 
		 * the contract 
		 */
		const request = {
			data: {
				name,
				description,
				account_id: accountId,
				contract_id: contractId,
				person_id: personId,
				// price,
				// po_number: poNumber,
				stage_id: stageId,
                due_at: dueDate !== '' ? dayjs(dueDate).format('YYYY-MM-DD') + ' 16:00:00' : null,
                user_id: userId !== '' && userId > 0 ? userId : null,
                priority: priority,
				billing_status: billingStatus
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				showSnackbar('Job saved!', 'success');
				if(! duplicate) {

					navigate('/jobs/' + response.id);
				}
			},
            error: (error) => {
				setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( jobId )
		{
			// Update existing person
			Api.jobs.update(jobId, request);
	    }
	    else
	    {
	    	// Add a new person
	    	Api.jobs.create(request);
	    }
	}


	useEffect(() => {

		if ( jobId )
		{
			/**
			 * Load the job into the form
			 */
	        Api.jobs.get(jobId, {
	            success: (response) => {
	                setName(response.name);
					setDescription(response.description);
					setAccountId(response.account_id);
					setContractId(response.contract_id);
					setPersonId(response.person_id);
					// setPoNumber(response.po_number);
					// setPrice(response.price);
                    setUserId(response.user_id);
					setStageId(response.stage_id);
                    setPriority(response.priority);
                    setDueDate(response.due_at !== null ? new Date(response.due_at) : '');
					setBillingStatus(response.billing_status);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

	    
    }, [jobId]);


    useEffect(() => {

    	/**
		 * Load the account options
		 */
        Api.accounts.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setAccountOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


        /**
         * Load the user options
         */
        Api.users.list({
            params: {
                per_page: 500
            },
            success: (response) => {
                setUserOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, [stageId]);


    useEffect(() => {

    	if ( accountId === '' )
    	{
    		setContractOptions([]);
    		setPersonOptions([]);
    		return;
    	}

    	/**
		 * Load the contract options
		 */
        Api.contracts.list({
        	params: {
        		per_page: 500,
        		account_id: accountId
        	},
            success: (response) => {
                setContractOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


         /**
		 * Load the person options
		 */
        Api.persons.list({
        	params: {
        		per_page: 500,
        		account_id: accountId
        	},
            success: (response) => {
                setPersonOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, [accountId])


	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
		}}>


		<Pipeline onChange={(stageId) => {

			setStageId(stageId)

		}} defaultStageId={stageId}/>


			<div className={`form__field ${errors.account_id?'form__field--error':''}`}>
				<label className="form__field__label">Account</label>
				<SelectList options={accountOptions} blank={true} onChange={(e) => {
					setAccountId(e.target.value);
				}} value={accountId}/>
				{ errors.account_id &&
					<div className="form__field__error">
						{errors.account_id.join('<br/>')}
					</div>
				}
			</div>


			{ personOptions.length > 0 &&
				<div className={`form__field ${errors.person_id?'form__field--error':''}`}>
					<label className="form__field__label">Person</label>
					<SelectList options={personOptions} blank={true} onChange={(e) => {
						setPersonId(e.target.value);
					}} value={personId}/>
					{ errors.person_id &&
						<div className="form__field__error">
							{errors.person_id.join('<br/>')}
						</div>
					}
				</div>
			}


			{ contractOptions.length > 0 &&
				<div className={`form__field ${errors.contract_id?'form__field--error':''}`}>
					<label className="form__field__label">Contract</label>
					<SelectList options={contractOptions} blank={true} onChange={(e) => {
						setContractId(e.target.value);
					}} value={contractId}/>
					{ errors.contract_id &&
						<div className="form__field__error">
							{errors.contract_id.join('<br/>')}
						</div>
					}
				</div>
			}


			{ billingStatusOptions.length > 0 &&
				<div className={`form__field ${errors.billing_status?'form__field--error':''}`}>
					<label className="form__field__label">Billing Status</label>
					<SelectList options={billingStatusOptions} blank={true} onChange={(e) => {
						setBillingStatus(e.target.value);
					}} value={billingStatus}/>
					{ errors.billing_status &&
						<div className="form__field__error">
							{errors.billing_status.join('<br/>')}
						</div>
					}
				</div>
			}


			
			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Job name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>



			<div className={`form__field ${errors.description?'form__field--error':''}`}>
				<label className="form__field__label">Description</label>
				<textarea onChange={(e) => {
					setDescription(e.target.value)
				}} name="description" rows="10" value={description ?? ''} className="form__field__input"/>
				{ errors.description &&
					<div className="form__field__error">
						{errors.description.join('<br/>')}
					</div>
				}
			</div>


			{/* <div className={`form__field ${errors.price?'form__field--error':''}`}>
				<label className="form__field__label">Price</label>
				<input onChange={(e) => {
					setPrice(e.target.value)
				}} name="price" value={price ?? ''} className="form__field__input"/>
				{ errors.price &&
					<div className="form__field__error">
						{errors.price.join('<br/>')}
					</div>
				}
			</div>
 */}



            <div className={`form__field ${errors.due_at?'form__field--error':''}`}>
                <label className="form__field__label">Due Date</label>
                
                <DatePicker 
                    selected={dueDate !== '' ? dueDate : null} 
                    onChange={(date) => setDueDate(date)} 
                    dateFormat="yyyy-MM-dd" 
                    className="form__field__input"/>
            
                { errors.due_at &&
                    <div className="form__field__error">
                        {errors.due_at.join('<br/>')}
                    </div>
                }
            </div>



            <div className={`form__field ${errors.user_id?'form__field--error':''}`}>
                <label className="form__field__label">User Assignment</label>
                <SelectList options={userOptions} blank={true} onChange={(e) => {
                    setUserId(e.target.value);
                }} value={userId}/>
                { errors.user_id &&
                    <div className="form__field__error">
                        {errors.user_id.join('<br/>')}
                    </div>
                }
            </div>



			{/* <div className={`form__field ${errors.po_number?'form__field--error':''}`}>
				<label className="form__field__label">PO Number</label>
				<input onChange={(e) => {
					setPoNumber(e.target.value)
				}} name="poNumber" value={poNumber ?? ''} className="form__field__input"/>
				{ errors.po_number &&
					<div className="form__field__error">
						{errors.po_number.join('<br/>')}
					</div>
				}
			</div> */}


            <div className={`form__field ${errors.priority?'form__field--error':''}`}>
                <label className="form__field__label">Priority</label>
                <SelectList options={[
                    {id: 1, name: 'Low'},
                    {id: 2, name: 'Default'},
                    {id: 3, name: 'High'}

                    ]} onChange={(e) => {
                    setPriority(e.target.value);
                }} value={priority}/>
                { errors.priority &&
                    <div className="form__field__error">
                        {errors.priority.join('<br/>')}
                    </div>
                }
            </div>
			


	
		

		



			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
				<div style={{display: 'flex', justifyContent: 'space-between', maxWidth: '500px', width: '100%'}}>
					<Button 
						label="Save Job"
						onClick={() => submit(false)}
					/>
					<Button 
						label="Save and Duplicate Job"
						onClick={() => submit(true)}
						style={{marginRight: '0'}}
					/>
				</div>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
JobForm.propTypes = {
	jobId: PropTypes.string
}


export default JobForm;