// Packages
import PropTypes from 'prop-types';


// Assets
import './styles.css';


const Overlay = props => {

    // Props
    const { onClose } = props;


    return (
        <div className="Overlay">

            <div onClick={onClose} className="Overlay__close"/>

            {props.children} 

        </div>
    );
}

Overlay.propTypes = {
    onClose: PropTypes.func.isRequired
}

export default Overlay;