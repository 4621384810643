/*

Main configuration file for this app

React only allows two environments natively (dev and prod), so
we have to manage our own env config :(

*/


/*
General Configuration
*/
const generalConfig = {
	STAGE: process.env.REACT_APP_STAGE,
	AUTH0_SCOPES: "openid profile email"
}


/*
Environment-specific Configuration
*/
const environmentConfig = {

	/*
	Production Environment Configuration
	*/
	production: {
		API_URL: "https://api.crm.behindthedot.com",
		AUTH0_DOMAIN: "behindthedot.eu.auth0.com",
		AUTH0_CLIENT_ID: "cBcEeI8sF7rlgJrHkfxWMd6VPh5CUwuo"
	},


	/*
	Development Environment Configuration
	*/
	development: {
		API_URL: "http://api.crm.btd.local",
		AUTH0_DOMAIN: "behindthedot.eu.auth0.com",
		AUTH0_CLIENT_ID: "cBcEeI8sF7rlgJrHkfxWMd6VPh5CUwuo",
	}

}

const config = {
	...generalConfig,
	...environmentConfig[process.env.REACT_APP_STAGE]
}

export default config