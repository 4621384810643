// Packages
import { Link } from 'react-router-dom';


// Components
import EmptyState from 'components/Lists/EmptyState';


const TemplatesList = props => {
	
	const { items } = props;

    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no templates to display here"/>);
    }

	return (
		<table className="table">
		<thead>
		<tr>
			<th>Name</th>
			<th>Type</th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (

					<tr key={item.id}>
						<td><Link to={`/templates/${item.id}`}><strong>{item.name}</strong></Link></td>
						<td>{item.type}</td>
					</tr>

				);

			})}
		</tbody>
		</table>
	);
}

export default TemplatesList;