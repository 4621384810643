// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Templates/List';
import ViewScreen from 'screens/Templates/View';
import CreateScreen from 'screens/Templates/Create';
import EditScreen from 'screens/Templates/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const TemplateRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
				<Route exact path="/:templateId" element={<ViewScreen/>}/>
				<Route exact path="/:templateId/edit" element={<EditScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default TemplateRoutes