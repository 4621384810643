// Libraries
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


// Assets
import './styles.css';



const Button = props => {

	const { label, type, colour, style, url, onClick, size } = props;

	const navigate = useNavigate();

	return (
		<button onClick={() => {

			if ( url )
			{
				navigate(url);
			}
			else if ( onClick )
			{
				onClick();
			}

		}} style={style} className={`Button Button--colour-${colour} Button--size-${size} Button--type-${type}`}>

			{label}

		</button>
	);

}

Button.propTypes = {
	label: PropTypes.string.isRequired,
	colour: PropTypes.string.isRequired,
	style: PropTypes.object.isRequired
}

Button.defaultProps = {
	colour: 'default',
	style: {}
}


export default Button;