// Packages
import dayjs from 'dayjs';
import nl2br from 'react-nl2br';

// Components
import EmptyState from 'components/Lists/EmptyState';


// Other
import Api from 'other/Api';


const NotesList = props => {
    
    const { items, onChange } = props;



    const deleteNote = (id) => {

        if ( ! window.confirm('Are you sure that you want to delete this note?') )
        {
            return;
        }

        Api.notes.delete(id, {
            success: (response) => {
                onChange();
            }
        })
    }


    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no notes to display here"/>);
    }



    return (

        <table className="table">
        <tbody>

            {items.map((item) => {

                return (

                    <tr key={item.id}>
                        <td>{dayjs(item.created_at).format('DD/MM/YYYY HH:mm')}</td>
                        <td>{nl2br(item.body)}</td>
                        <td><div onClick={() => {
                            deleteNote(item.id);
                        }} className="table-delete"/></td>
                    </tr>

                );

            })}
        </tbody>
        </table>
    );
}

export default NotesList;