// Packages
import { Link } from 'react-router-dom';


// Assets
import './styles.css';


const Wrapper = props => {

	const { title, children } = props;


	return (
		<div className="InfoPanel">
			{ title &&
				<div className="InfoPanel__header">
					{title}
				</div>
			}

			<div className="InfoPanel__body">
				{children}
			</div>

		</div>
	);

}


const Column = props => {

	return (
		<div className="InfoPanel__column">

			{props.children}

		</div>
	);
}


const Item = props => {

	const { children, label, value, link, colour } = props;




	return (
		<div className={`InfoPanel__column__item InfoPanel__column__item--colour-${colour}`}>

			<div className="InfoPanel__column__item__header">{label}</div>
			<div className="InfoPanel__column__item__body">
				{link &&
					<Link to={link}>{value} {children}</Link>
				}

				{ ! link && 
					<>{value} {children}</>
				}

			</div>

		</div>
	);
}

const output = {
	Wrapper,
	Column,
	Item
};

export default output;