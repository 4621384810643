// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';


// Other
import Api from 'other/Api';


const ContractForm = props => {

	// Props
	const { contractId } = props;
	

	// URL parameters
	const [ queryParams ] = useSearchParams();


	// State - Form fields
	const [name, setName] = useState('');
	const [accountId, setAccountId] = useState(queryParams.get('account_id')??null);
	const [interval, setInterval] = useState('month');
	const [price, setPrice] = useState(0);
	const [summary, setSummary] = useState('');
	const [hours, setHours] = useState(0);
	const [rolloverHours, setRolloverHours] = useState(0);


	// state - Select options
	const [ accountOptions, setAccountOptions] = useState(null);


	// State
	const [loading, setLoading] = useState(accountId);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {

		/**	
		 * Build generic request to either create or update
		 * the contract
		 */
		const request = {
			data: {
				name, 
				account_id: accountId,
				interval,
				price,
				summary,
				seconds: hours * 3600,
				rollover_seconds: rolloverHours * 3600
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: () => {
				navigate('/contracts');
			},
            error: (error) => {
				setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( contractId )
		{
			// Update existing contract
			Api.contracts.update(contractId, request);
	    }
	    else
	    {
	    	// Add a new contract
	    	Api.contracts.create(request);
	    }
	}


	useEffect(() => {

		if ( contractId )
		{
			/**
			 * Load the account into the form
			 */
	        Api.contracts.get(contractId, {
	            success: (response) => {
	                setName(response.name);
					setAccountId(response.account_id);
					setInterval(response.interval);
					setPrice(response.price);
					setSummary(response.summary);
					setHours(response.seconds / 3600);
					setRolloverHours(response.rollover_seconds / 3600);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

	    

    }, [contractId]);


    useEffect(() => {

    	/**
		 * Load the account options
		 */
        Api.accounts.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setAccountOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);



	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>
			
			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>


			<div className={`form__field ${errors.account_id?'form__field--error':''}`}>
				<label className="form__field__label">Account</label>
				<SelectList options={accountOptions} blank={true} onChange={(e) => {
					setAccountId(e.target.value);
				}} value={accountId}/>
				{ errors.account_id &&
					<div className="form__field__error">
						{errors.account_id.join('<br/>')}
					</div>
				}
			</div>


			<div className={`form__field ${errors.price?'form__field--error':''}`}>
				<label className="form__field__label">Price</label>
				<input onChange={(e) => {
					setPrice(e.target.value)
				}} name="price" value={price ?? ''} className="form__field__input form__field__input--size-small" style={{marginRight: '15px', marginBottom: '20px'}}/>
				per
				<select onChange={(e) => {
					setInterval(e.target.value)
				}} value={interval ?? ''} name="interval" className="form__field__input form__field__input--size-small" style={{marginLeft: '15px', marginRight: '15px'}}>
					<option value="hour">Hour</option>
					<option value="month">Month</option>
					<option value="quarter">Quarter</option>
					<option value="year">Year</option>
				</select>

				for 
				<input onChange={(e) => {
					setHours(e.target.value)
				}} name="hours" value={hours ?? ''} className="form__field__input form__field__input--size-tiny" style={{marginLeft: '15px', marginRight: '15px'}}/>
				
				hours

				<label className="form__field__label">Rollover Hours</label>
				<input onChange={(e) => {
					setRolloverHours(e.target.value)
				}} name="rollover_hours" value={rolloverHours ?? ''} className="form__field__input form__field__input--size-tiny" style={{marginRight: '15px'}}/>

				{ errors.price &&
					<div className="form__field__error">
						{errors.price.join('<br/>')}
					</div>
				}

				{ errors.interval &&
					<div className="form__field__error">
						{errors.interval.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.summary?'form__field--error':''}`}>
				<label className="form__field__label">Summary</label>
				<textarea onChange={(e) => {
					setSummary(e.target.value)
				}} name="summary" rows="10" value={summary ?? ''} className="form__field__input"/>
				{ errors.summary &&
					<div className="form__field__error">
						{errors.summary.join('<br/>')}
					</div>
				}
			</div>



			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Contract"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
ContractForm.propTypes = {
	contractId: PropTypes.string
}


export default ContractForm;