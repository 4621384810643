// Packages
import { Link } from 'react-router-dom';

import './styles.css';

const DocumentationList = props => {
	
	const { items } = props;

	return (
		<table className="table documentationTable">
		<thead>
		<tr>
			<th>Name</th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (

					<tr key={item.id}>
						<td><Link to={`/documentations/${item.id}`}>{item.name}</Link></td>
					</tr>

				);

			})}
		</tbody>
		</table>
	);
}

export default DocumentationList;