// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Job';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { jobId } = useParams();

    const navigate = useNavigate();


    const deleteJob = () => {

        if ( ! window.confirm('Are you sure that you want to delete this job?') )
        {
            return false;
        }

        Api.jobs.delete(jobId, {
            successRedirect: () => {
                navigate('/jobs');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Job"/>

        <Form jobId={jobId}/>

        <Button onClick={deleteJob} type="delete" label="Delete Job"/>
        </>
    )
}

export default EditScreen;