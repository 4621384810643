// Packages
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import nl2br from 'react-nl2br';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import InfoPanel from 'components/UI/InfoPanel';
import Tabs from 'components/UI/Tabs';
import Pipeline from 'components/Pipeline';
import FilesList from 'components/Lists/Files';
import FileUpload from 'components/FileUpload';
import NotesList from 'components/Lists/Notes';
import TimeList from 'components/Lists/Time';
import LinksList from 'components/Lists/Links';
import NoteForm from 'components/Forms/Note';
import Duration from 'components/Duration';


// Others
import Api from 'other/Api';
// import { Currency } from 'other/Utilities';
import Message from 'components/UI/Message';


// Assets
import redAlertIcon from 'assets/red-alert.svg';
import './styles.css';
import helpScoutIcon from '../../../assets/helpscout-logo.svg';


// Context
import TimerContext from 'context/Timer';




const JobViewScreen = () => {

	// URL Parameters
	const { jobId } = useParams();


	// State
	const [job, setJob] = useState([]);
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');

    const [files, setFiles] = useState([]);
    const [filesLoading, setFilesLoading] = useState(true);

    const [notes, setNotes] = useState([]);
    const [notesLoading, setNotesLoading] = useState(true);

    const [time, setTime] = useState([]);
    const [timeLoading, setTimeLoading] = useState(true);

    const [links, setLinks] = useState([]);
    const [linksLoading, setLinksLoading] = useState(true);

    const Timer = useContext(TimerContext);


    const loadNotes = () => {

        Api.notes.list({
            params: {
                job_id: jobId
            },
            before: () => {
                setNotesLoading(true);
            },
            success: (response) => {
                setNotes(response.data);
            },
            after: () => {
                setNotesLoading(false);
            }
        }); 
    }

    const loadLinks = () => {

        Api.links.list({
            params: {
                job_id: jobId
            },
            before: () => {
                setLinksLoading(true);
            },
            success: (response) => {
                setLinks(response.data);
            },
            after: () => {
                setLinksLoading(false);
            }
        }); 
    }

    const loadTime = () => {

        Api.times.list({
            params: {
                job_id: jobId,
                with: ['user']
            },
            before: () => {
                setTimeLoading(true);
            },
            success: (response) => {
                setTime(response.data);
            },
            after: () => {
                setTimeLoading(false);
            }
        }); 
    }

    useEffect(() => {
        loadTime();
    // eslint-disable-next-line
    }, [Timer.lastChange]);


	useEffect(() => {

		Api.jobs.get(jobId, {
			params: {
				with: ['account', 'contract', 'person', 'stage', 'user', 'times', 'recurring_job_template']
			},
			success: (response) => {
				setJob(response);
			},
			after: () => {
				setLoading(false);
			}
		});

        loadFiles();
        loadNotes();
        loadTime();
        loadLinks();
        // eslint-disable-next-line
	}, [jobId]);



    const loadFiles = () => {

        Api.files.list({
            params: {
                job_id: jobId
            },
            before: () => {
                setFilesLoading(true);
            },
            success: (response) => {
                setFiles(response.data);
            },
            after: () => {
                setFilesLoading(false);
            }
        }); 
    }
	



	const startTimer = () => {

        Api.timer.start(jobId, {
            success: (response) => {
                loadTime();
                Timer.check();
            },
            error: (error) => {
                setErrorMessage(error.message);
            }
        })
	}


	const changeStage = (stageId) => {
		Api.jobs.update(jobId, {
			data: {
				stage_id: stageId
			}
		});
	}


    const closeJob = () => {
        Api.jobs.close(jobId, {
            success: () => {
                window.location.reload();
            },
            error: (response) => {
                alert("Error closing job: " + response.message);
            }
        })
    }

    const reopenJob = () => {
        Api.jobs.reopen(jobId, {
            success: () => {
                window.location.reload();
            },
            error: (response) => {
                alert("Error re-opening job: " + response.message);
            }
        })
    }


	if ( loading )
	{
		return <Loading/>;
	}



    const dueDate = job.due_at !== null ? dayjs(job.due_at).format('ddd D MMM YYYY [at] HH:mm') : '--';


	return (
		<>
			<ScreenTitle title={job.name} breadcrumb={[
				{label: 'Jobs', url: '/jobs'}
			]}>
				<Button url={`/jobs/${job.id}/edit`} label="Edit Job" colour="blue"/>

                {job.status === 'open' ?
                    <Button onClick={() => {

                        if ( window.confirm('Are you sure you want to close this job?') )
                        {
                            closeJob();
                        }

                    }} label="Close Job" colour="orange"/>
                    :
                    <Button onClick={() => {

                        if ( window.confirm('Are you sure you want to re-open this job?') )
                        {
                            reopenJob();
                        }

                    }} label="Re-open Job" colour="orange"/>
                }
			</ScreenTitle>


			<Pipeline onChange={changeStage} defaultStageId={job.stage_id}/>

			
			<InfoPanel.Wrapper>

				<InfoPanel.Column>
                    { job.account && 
                        <>
                        <InfoPanel.Item label="Account" value={job.account !== null ? job.account.name : ''} link={`/accounts/${job.account_id}`}/>
                        { job.person &&
                            <InfoPanel.Item label="Person" value={`${job.person !== null ? job.person.firstname : ''} ${job.person !== null ? job.person.lastname : ''}`} link={`/persons/${job.person_id}`}/>
                        }
                        </>
                    }

					{ job.contract &&
						<InfoPanel.Item label="Contract" value={job.contract !== null ? job.contract.name : ''} link={`/contracts/${job.contract_id}`}/>
					}
				</InfoPanel.Column>

				<InfoPanel.Column>
					{/* <InfoPanel.Item label="Price" value={Currency(job.price)}/> */}
					{/* <InfoPanel.Item label="PO Number" value={job.po_number}/> */}
					<InfoPanel.Item label="Due" value={dueDate} colour={job.is_overdue ? 'red' : ''}>
                        {job.is_overdue &&
                            <img src={redAlertIcon} alt="Overdue!" style={{width: '20px'}}/>
                        }
                    </InfoPanel.Item>

				</InfoPanel.Column>

				<InfoPanel.Column>
					<InfoPanel.Item label="Created" value={dayjs(job.created_at).format('ddd D MMM YYYY [at] HH:mm')}/>
					<InfoPanel.Item label="Assigned To" value={job.user ? job.user.name : '--'}/>
				    <InfoPanel.Item label="Time Taken" value={<Duration seconds={job.seconds_taken}/>}/>
				</InfoPanel.Column>


                <InfoPanel.Column>

                    <InfoPanel.Item label="Job Status" colour={job.status === 'closed' ? 'green' : 'orange'}>
                        {job.status === 'open' ? 'Open' : 'Closed'}
                    </InfoPanel.Item>

                    <InfoPanel.Item label="Billing Status" colour={job.billing_status === 'pending' ? 'red' : 'none'}>
                        {job.billing_status ?? ''}
                    </InfoPanel.Item>

                    { job.recurring_job &&
						<InfoPanel.Item label="Recurring Job" value={job.recurring_job_template !== null ? job.recurring_job_template.name : ''} link={`/recurringjobs/${job.recurring_job_template_id}`}/>
					}

                </InfoPanel.Column>

			</InfoPanel.Wrapper>




			{ job.description &&
				<InfoPanel.Wrapper title="Description">
					<InfoPanel.Item value={nl2br(job.description)}/>
                    { job.external_ticket_id &&
                 <div className="external-ticket external-ticket--helpscout">
                    <a href={`https://secure.helpscout.net/conversation/${job.external_ticket_id}`} target="_blank" rel="noreferrer"><img src={helpScoutIcon} alt="Helpscout"/>View ticket in HelpScout &rarr;</a>
                </div>
            }
				</InfoPanel.Wrapper>
			}


            




			<Tabs.Wrapper defaultTab="timesheet" tabs={[
				{ name: 'timesheet', label: 'Timesheet'},
                { name: 'notes', label: 'Notes', count: notes.length},
                { name: 'files', label: 'Files', count: files.length},
                { name: 'links', label: 'Links', count: links.length},
			]}>

				<Tabs.Panel name="timesheet">

					{errorMessage && 
					
						<Message colour="red" text={errorMessage} />
					}

                    { ! Timer.job &&
			 		  <Button label="Start Timer" onClick={startTimer} />
                    }

                    <br/>
                    <br/>

					{ timeLoading &&
                        <Loading message="Loading time"/>
                    }

                    { ! timeLoading &&
                        <TimeList items={time} onChange={loadTime}/>
                    }   


				</Tabs.Panel>


                <Tabs.Panel name="notes">

                    <NoteForm 
                        onNoteCreate={loadNotes} 
                        accountId={job.account_id}
                        contractId={job.contract_id}
                        jobId={job.id}
                    />

                    <br/><br/>

                    { notesLoading &&
                        <Loading message="Loading notes"/>
                    }

                    { ! notesLoading &&
                        <NotesList items={notes} onChange={loadNotes}/>
                    }           

                </Tabs.Panel>



                <Tabs.Panel name="files">


                    <FileUpload accountId={job.account_id} contractId={job.contract_id??null} jobId={job.id} onFileUpload={() => {
                        loadFiles();
                    }}/>


                    { filesLoading &&
                        <Loading message="Loading files"/>
                    }

                    { ! filesLoading &&
                        <FilesList items={files} onChange={loadFiles}/>
                    }
                
                </Tabs.Panel>


                <Tabs.Panel name="links">

                    <Button url={`/links/create?account_id=${job.account_id}&contract_id=${job.contract_id}&job_id=${job.id}`} size="small" label="Create Link"/>

                    <br/>
                    <br/>

                    { linksLoading &&
                        <Loading message="Loading links"/>
                    }

                    { ! linksLoading &&
                        <LinksList items={links} onChange={loadLinks}/>
                    }

                </Tabs.Panel>



			</Tabs.Wrapper>

		</>
	);
}

export default JobViewScreen;