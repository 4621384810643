// Packages
import dayjs from 'dayjs';


const duration = require('dayjs/plugin/duration');
dayjs.extend(duration);




const Duration = props => {
    
    // Props
    const { seconds } = props;

    const d = parseInt(dayjs.duration(seconds, 's').format('D'));
    const h = parseInt(dayjs.duration(seconds, 's').format('H'));
    const m = parseInt(dayjs.duration(seconds, 's').format('m'));
    // const s = parseInt(dayjs.duration(seconds, 's').format('s'));

    let string = '';

    if ( d > 0 )
    {
        string += d + 'd ';
    }

    if ( h > 0 )
    {
        string += h + 'h ';
    }

    if ( m > 0 )
    {
        string += m + 'm ';
    }

    // if ( s > 0 )
    // {
    //     string += s + 's';
    // }


    return (
        <span className="Duration">
        {string.trim()}
        </span>
    );
}


export default Duration;