// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import PersonsList from 'components/Lists/Persons';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';
import Pagination from 'components/Pagination';


const PersonListScreen = () => {

	// State
	const [persons, setPersons] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(1);
	// const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {

		Api.persons.list({
			params: {
				with: ['account'],
				page: page
			},
			success: (response) => {
				setPersons(response.data);
				setPage(response.meta.current_page);
				setTotalPages(response.meta.last_page);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, [page]);


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title="People">
				<Button url="/persons/create" label="Create Person" colour="blue"/>
			</ScreenTitle>

			<PersonsList items={persons}/>

			<Pagination
				currentPage={page}
				totalPages={totalPages}
				onChange={(value) => setPage(value)}
				style={{margin: '30px auto', width: 'max-content'}}
			/>


		</>
	);
}

export default PersonListScreen;