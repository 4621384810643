// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/DocumentationPage';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { documentationId, documentationPageId } = useParams();

    const navigate = useNavigate();


    const deleteDocumentationPage = () => {

        if ( ! window.confirm('Are you sure that you want to delete this documentation page?') )
        {
            return false;
        }

        Api.documentations.delete(documentationPageId, {
            successRedirect: () => {
                
                navigate('/documentations');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Documentation Page"/>

        <Form documentationId={documentationId} documentationPageId={documentationPageId}/>

        <Button onClick={deleteDocumentationPage} type="delete" label="Delete Documentation Page"/>
        </>
    )
}

export default EditScreen;