// Packages
import PropTypes from 'prop-types';


const UserInitials = props => {

	// Props
	const { name } = props;

    const words = name.split(/\s+/);

    const initials = words.map((word) => {
        return word.substring(0, 1);
    })

	return (
		<>
		{initials.join('').trim().toUpperCase()}
		</>
	);

}


// Prop types
UserInitials.propTypes = {
	name: PropTypes.string,
};


export default UserInitials;