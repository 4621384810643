// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Pipelines/List';
import CreateScreen from 'screens/Pipelines/Create';
import EditScreen from 'screens/Pipelines/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const PipelineRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/:pipelineId/edit" element={<EditScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default PipelineRoutes