// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Link';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { linkId } = useParams();

    const navigate = useNavigate();


    const deleteLink = () => {

        if ( ! window.confirm('Are you sure that you want to delete this link?') )
        {
            return false;
        }

        Api.links.delete(linkId, {
            successRedirect: () => {
                
                navigate('/links');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Link" breadcrumb={[
            {label: 'Links', url: '/links'}
        ]}/>

        <Form linkId={linkId}/>

        <Button onClick={deleteLink} type="delete" label="Delete Link"/>
        </>
    )
}

export default EditScreen;