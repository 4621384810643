// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/MeetingNote';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { meetingNoteId } = useParams();

    const navigate = useNavigate();


    const deleteNote = () => {

        if ( ! window.confirm('Are you sure that you want to delete this note?') )
        {
            return false;
        }

        Api.meetingNotes.delete(meetingNoteId, {
            successRedirect: () => {
                navigate('/meetingnotes');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Meeting Note"/>

        <Form meetingNoteId={meetingNoteId}/>

        <Button onClick={deleteNote} type="delete" label="Delete Meeting Note"/>
        </>
    )
}

export default EditScreen;