// Components
import EmptyState from 'components/Lists/EmptyState';


const UsersList = props => {
    
    const { items } = props;

    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no users to display here"/>);
    }

    return (
        <table className="table">
        <thead>
        <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Sub</th>
        </tr>
        </thead>
        <tbody>

        {items.map((item) => {

            return (

                <tr key={item.id}>
                    <td><strong>{item.name}</strong></td>
                    <td>{item.email}</td>
                    <td>{item.sub}</td>
                </tr>

            );

        })}
        </tbody>
        </table>
    );
}

export default UsersList;