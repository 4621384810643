// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Persons/List';
import ViewScreen from 'screens/Persons/View';
import CreateScreen from 'screens/Persons/Create';
import EditScreen from 'screens/Persons/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const PersonRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<ListScreen/>}/>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/:personId" element={<ViewScreen/>}/>
				<Route exact path="/:personId/edit" element={<EditScreen/>}/>				
			</Routes>
		</DefaultLayout>
	)
}


export default PersonRoutes