// Packages
import { Routes, Route } from "react-router-dom"


// Routes
import MiscRoutes from 'routes/misc';
import AccountRoutes from 'routes/accounts';
import ContractRoutes from 'routes/contracts';
import PersonRoutes from 'routes/persons';
import JobRoutes from 'routes/jobs';
import MeetingNoteRoutes from 'routes/meetingnotes';
import TemplateRoutes from 'routes/templates';
import UserRoutes from 'routes/users';
import LinkRoutes from 'routes/links';
import TimeRoutes from 'routes/times';
import StageRoutes from 'routes/stages';
import PipelineRoutes from 'routes/pipelines';
import ReportRoutes from 'routes/reports';
import HelpScoutRoutes from 'routes/helpscout';
import RecurringJobRoutes from 'routes/recurringjobs';
import MonitorRoutes from 'routes/monitors';
import DocumentationRoutes from 'routes/documentations';
import DocumentationPageRoutes from 'routes/documentationpages';

const MainRoutes = () => 
{
	return (
		<Routes>
			<Route path="/accounts/*" element={<AccountRoutes/>}/>
			<Route path="/contracts/*" element={<ContractRoutes/>}/>
			<Route path="/persons/*" element={<PersonRoutes/>}/>
			<Route path="/jobs/*" element={<JobRoutes/>}/>
			<Route path="/templates/*" element={<TemplateRoutes/>}/>
			<Route path="/meetingnotes/*" element={<MeetingNoteRoutes/>}/>
            <Route path="/users/*" element={<UserRoutes/>}/>
            <Route path="/links/*" element={<LinkRoutes/>}/>
            <Route path="/times/*" element={<TimeRoutes/>}/>
            <Route path="/stages/*" element={<StageRoutes/>}/>
            <Route path="/pipelines/*" element={<PipelineRoutes/>}/>
            <Route path="/reports/*" element={<ReportRoutes/>}/>
            <Route path="/helpscout/*" element={<HelpScoutRoutes/>}/>
            <Route path="/recurringjobs/*" element={<RecurringJobRoutes/>}/>
            <Route path="/monitors/*" element={<MonitorRoutes/>}/>
            <Route path="/documentations/:documentationId/documentationpages/*" element={<DocumentationPageRoutes/>}/>
            <Route path="/documentations/*" element={<DocumentationRoutes/>}/>
			<Route path="/*" element={<MiscRoutes/>}/>
		</Routes>
	);
}


export default MainRoutes;