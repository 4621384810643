// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Time';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { timeId } = useParams();

    const navigate = useNavigate();


    const deleteTime = () => {

        if ( ! window.confirm('Are you sure that you want to delete this time?') )
        {
            return false;
        }

        Api.times.delete(timeId, {
            successRedirect: () => {
                navigate('/times');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Time"/>

        <Form timeId={timeId}/>

        <Button onClick={deleteTime} type="delete" label="Delete Time"/>
        </>
    )
}

export default EditScreen;