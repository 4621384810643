// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';


// Other
import Api from 'other/Api';


// Assets
import './styles.css';


const NoteForm = props => {

    // Props
    const { 
        accountId: propAccountId, 
        contractId: propContactId, 
        jobId: propJobId,
        onNoteCreate, 
        noteId } = props;



    // State - Form fields
    const [body, setBody] = useState('');
    const [accountId, setAccountId] = useState();
    const [contractId, setContractId] = useState();
    const [jobId, setJobId] = useState();

    // State
    const [loading, setLoading] = useState(noteId);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({});


    useEffect(() => {
        setAccountId(propAccountId);
        setContractId(propContactId);
        setJobId(propJobId);
    }, [propAccountId, propContactId, propJobId]);

    
    /**
     * Form submit
     */
    const submit = () => {

        /** 
         * Build generic request to either create or update
         * the note
         */
        const request = {
            data: {
                body,
                account_id: accountId,
                contract_id: contractId,
                job_id: jobId
           },
            before: () => {
                setSaving(true);
            },
            success: (response) => {
                onNoteCreate();
                setBody('');
            },
            error: (error) => {
                setErrorMessage(error.message);
                setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
        };

        if ( noteId )
        {
            // Update existing note
            Api.notes.update(noteId, request);
        }
        else
        {
            // Add a new note
            Api.notes.create(request);
        }
    }


    useEffect(() => {

        if ( noteId )
        {
            /**
             * Load the note into the form
             */
            Api.notes.get(noteId, {
                success: (response) => {
                    setBody(response.body);
                },
                error: (error) => {
                    setErrorMessage(error.message);
                },
                after: () => {
                    setLoading(false);
                }
            });
        }

        

    }, [noteId]);





    if ( loading )
    {
        return <Loading/>
    }


    return (
        <>
        
        {errorMessage &&
            <Message colour="red" text={errorMessage}/>
        }
        
        <form className="form" autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            submit();
        }}>
            

                <div style={{margin: 0}} className={`form__field form__field--full ${errors.body?'form__field--error':''}`}>
                    <textarea onChange={(e) => {
                        setBody(e.target.value)
                    }} name="body" rows="4" value={body ?? ''} className="form__field__input" placeholder="Write your note here"/>
                    { errors.body &&
                        <div className="form__field__error">
                            {errors.body.join('<br/>')}
                        </div>
                    }

            </div>


            <div className="form__footer">
                { saving &&
                    <Saving/>
                }

                { ! saving &&
                    <Button label="Save Note"/>
                }
            </div>

        </form>
        </>
    );
}


// Prop Types
NoteForm.propTypes = {
    accountId: PropTypes.number
}


export default NoteForm;