// Packages
import { useState, useEffect } from 'react';


// Components
import SearchResultsList from 'components/Lists/SearchResults';


// Other
import Api from 'other/Api';


// Assets
import './styles.css';


const Search = () => {

    // State
    const [searchWindow, setSearchWindow] = useState(false);

    const [ searchTerm, setSearchTerm ] = useState('');
    const [ results, setResults ] = useState([]);


    useEffect(() => {

        if ( searchTerm.length < 3 ) return;

        Api.search(searchTerm, {
            success: (response) => {
                setResults(response);
            }
        })

    }, [searchTerm])

    
    return (
        <div className="Search">
            <div onClick={() => {
                setSearchWindow(!searchWindow)
            }} className="Search__handle"/>
            { searchWindow &&
                <div className="Search__window">
                    <div className="Search__window__header">
                        <form autoComplete="off" onSubmit={(e) => {
                            e.preventDefault();
                        }}>
                            <input onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }} value={searchTerm} type="text" className="form__field__input" placeholder="Search for anything"/>
                        </form>
                    </div>
                    <div className="Search__window__body">
                        { results.length > 0 &&
                            <SearchResultsList onResultClick={() => {
                                setSearchWindow(false);
                            }} items={results}/>
                        }
                    </div>
                </div>
            }
        </div>
    );
}

export default Search;