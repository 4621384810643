// Packages
import axios from 'axios';


// Other
import Config from 'other/Config';


/** 
 * Adds defaults to all requests
 */
const setDefaults = (args) => {

    const defaults = {
        // Triggered before request
        before: () => {},
        // Triggered after request (success or error)
        after: () => {},
        // Triggered on a successful request
        success: () => { },
        // If the success has a redirect, use this
        successRedirect: () => {},
        // Triggered on an error
        error: (error) => { alert('An unhandled error occurred: ' + error.message); },
        // GET parameters
        params: {},
        // POST body data
        data: {},
        // Response type
        responseType: 'json'
    };

    for ( const name in defaults )
    {
        if ( ! args[name] )
        {
            args[name] = defaults[name]
        }
    }

    return args;
}




/**
 * The code that actually makes the API calls
 */
const apiRequest = async(method, url, request) => {

    const params = setDefaults(request);

    const token = await getAccessToken();

    params.before();

    if ( url.substring(0, 4) !== 'http' )
    {
        url = Config.API_URL + url;
    }


    try 
    {
        const response = await axios.request({
            url: url,
            method: method.toLowerCase(),
            params: params.params,
            data: params.data,
            responseType: params.responseType,
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        params.success(response.data);
        params.after(response.data);
        params.successRedirect(response.data);
    }
    catch ( exception )
    {
        if( exception.response !== undefined ){

            params.error(exception.response.data);
        }
        params.after();
    }

}




/**	
 * Return the access token from local storage
 */
const getAccessToken = async () => {

	return sessionStorage.getItem('access-token');
}


/**
 * Accounts
 * ----------------------------------------------
 */
const getAccounts = async (request) => {
    apiRequest('get', '/accounts', request);
}

const getAccount = async (accountId, request) => {
    apiRequest('get', '/accounts/' + accountId, request);
}

const createAccount = async (request) => {
    apiRequest('post', '/accounts', request);
}

const updateAccount = async (accountId, request) => {
    apiRequest('put', '/accounts/' + accountId, request);
}

const deleteAccount = async (accountId, request) => {
    apiRequest('delete', '/accounts/' + accountId, request);
}

const uploadAccountLogo = async (accountId, request) => {
    apiRequest('post', '/accounts/' + accountId + '/upload-logo', request);
}

const uploadAccountLifestylePhoto = async (accountId, request) => {
    apiRequest('post', '/accounts/' + accountId + '/upload-lifestyle-photo', request);
}

const getAccountStatement = async (accountId, request) => {
    apiRequest('get', '/accounts/' + accountId + '/statement', request);
}

const getAccountStatementPdf = async (accountId, request) => {
    request.responseType = 'blob';
    apiRequest('get', '/accounts/' + accountId + '/statement/pdf', request);
}


/**
 * Countries
 * ----------------------------------------------
 */

const getCountries = async (request) => {
    apiRequest('get', '/countries', request);
}

const getCountry = async (countryId, request) => {
    apiRequest('get', '/countries/' + countryId, request);
}

const createCountry = async (request) => {
    apiRequest('post', '/countries', request);
}

const updateCountry = async (countryId, request) => {
    apiRequest('put', '/countries/' + countryId, request);
}

const deleteCountry = async (countryId, request) => {
    apiRequest('delete', '/countries/' + countryId, request);
}


/**
 * Contracts
 * ----------------------------------------------
 */

const getContracts = async (request) => {
    apiRequest('get', '/contracts', request);
}

const getContract = async (contractId, request) => {
    apiRequest('get', '/contracts/' + contractId, request);
}

const createContract = async (request) => {
    apiRequest('post', '/contracts', request);
}

const updateContract = async (contractId, request) => {
    apiRequest('put', '/contracts/' + contractId, request);
}

const deleteContract = async (contractId, request) => {
    apiRequest('delete', '/contracts/' + contractId, request);
}

const getContractStatement = async (contractId, request) => {
    apiRequest('get', '/contracts/' + contractId + '/statement', request);
}

const getContractStatementPdf = async (contractId, request) => {
    request.responseType = 'blob';
    apiRequest('get', '/contracts/' + contractId + '/statement/pdf', request);
}

const getUsageData = async (contractId, request) => {
    apiRequest('get', '/contracts/' + contractId + '/usage', request);
}

const getAnnualUsageData = async (contractId, request) => {
    apiRequest('get', '/contracts/' + contractId + '/annualUsage', request);
}


/**
 * Persons
 * ----------------------------------------------
 */

const getPersons = async (request) => {
    apiRequest('get', '/persons', request);
}

const getPerson = async (personId, request) => {
    apiRequest('get', '/persons/' + personId, request);
}

const createPerson = async (request) => {
    apiRequest('post', '/persons', request);
}

const updatePerson = async (personId, request) => {
    apiRequest('put', '/persons/' + personId, request);
}

const deletePerson = async (personId, request) => {
    apiRequest('delete', '/persons/' + personId, request);
}


/**
 * Jobs
 * ----------------------------------------------
 */

const getJobs = async (request) => {
    apiRequest('get', '/jobs', request);
}

const getJob = async (jobId, request) => {
    apiRequest('get', '/jobs/' + jobId, request);
}

const createJob = async (request) => {
    apiRequest('post', '/jobs', request);
}

const updateJob = async (jobId, request) => {
    apiRequest('put', '/jobs/' + jobId, request);
}

const closeJob = async (jobId, request) => {
    apiRequest('put', '/jobs/' + jobId + '/close', request);
}

const reopenJob = async (jobId, request) => {
    apiRequest('put', '/jobs/' + jobId + '/reopen', request);
}

const deleteJob = async (jobId, request) => {
    apiRequest('delete', '/jobs/' + jobId, request);
}

const markJobAsBilled = async (request) => {
    apiRequest('post', '/jobs/billed', request);
}

const getUnbilledJobs = async (request) => {
    apiRequest('get', '/jobs/unbilled', request);
}

const getDashboardJobs = async (request) => {
    apiRequest('get', '/jobs/dashboard', request);
}



/**
 * Stages
 * ----------------------------------------------
 */

const getStages = async (request) => {
    apiRequest('get', '/stages', request);
}

const getStage = async (stageId, request) => {
    apiRequest('get', '/stages/' + stageId, request);
}

const createStage = async (request) => {
    apiRequest('post', '/stages', request);
}

const updateStage = async (stageId, request) => {
    apiRequest('put', '/stages/' + stageId, request);
}

const deleteStage = async (stageId, request) => {
    apiRequest('delete', '/stages/' + stageId, request);
}


/**
 * Times
 * ----------------------------------------------
 */

const getTimes = async (request) => {
    apiRequest('get', '/times', request);
}

const getTime = async (timeId, request) => {
    apiRequest('get', '/times/' + timeId, request);
}

const createTime = async (request) => {
    apiRequest('post', '/times', request);
}

const updateTime = async (timeId, request) => {
    apiRequest('put', '/times/' + timeId, request);
}

const deleteTime = async (timeId, request) => {
    apiRequest('delete', '/times/' + timeId, request);
}


/**
 * Meeting Notes
 * ----------------------------------------------
 */

const getMeetingNotes = async (request) => {
    apiRequest('get', '/meetingnotes', request);
}

const getMeetingNote = async (meetingNoteId, request) => {
    apiRequest('get', '/meetingnotes/' + meetingNoteId, request);
}

const createMeetingNote = async (request) => {
    apiRequest('post', '/meetingnotes', request);
}

const updateMeetingNote = async (meetingNoteId, request) => {
    apiRequest('put', '/meetingnotes/' + meetingNoteId, request);
}

const deleteMeetingNote = async (meetingNoteId, request) => {
    apiRequest('delete', '/meetingnotes/' + meetingNoteId, request);
}


/**
 * Templates
 * ----------------------------------------------
 */

const getTemplates = async (request) => {
    apiRequest('get', '/templates', request);
}

const getTemplate = async (templateId, request) => {
    apiRequest('get', '/templates/' + templateId, request);
}

const getTemplatePdf = async (templateId, request) => {
    request.responseType = 'blob';
    apiRequest('get', '/templates/' + templateId + '/pdf', request);
}

const createTemplate = async (request) => {
    apiRequest('post', '/templates', request);
}

const updateTemplate = async (templateId, request) => {
    apiRequest('put', '/templates/' + templateId, request);
}

const deleteTemplate = async (templateId, request) => {
    apiRequest('delete', '/templates/' + templateId, request);
}


/**
 * Files
 * ----------------------------------------------
 */

const getFiles = async (request) => {
    apiRequest('get', '/files', request);
}

const getFile = async (fileId, request) => {
    apiRequest('get', '/files/' + fileId, request);
}

const createFile = async (request) => {
    apiRequest('post', '/files', request);
}

const updateFile = async (fileId, request) => {
    apiRequest('put', '/files/' + fileId, request);
}

const deleteFile = async (fileId, request) => {
    apiRequest('delete', '/files/' + fileId, request);
}

const downloadFile = async (fileId, request) => {
    request.responseType = 'blob';
    apiRequest('get', '/files/' + fileId + '/download', request);
}



/**
 * Users
 * ----------------------------------------------
 */

const getUsers = async (request) => {
    apiRequest('get', '/users', request);
}

const getUser = async (userId, request) => {
    apiRequest('get', '/users/' + userId, request);
}

const createUser = async (request) => {
    apiRequest('post', '/users', request);
}

const updateUser = async (userId, request) => {
    apiRequest('put', '/users/' + userId, request);
}

const deleteUser = async (userId, request) => {
    apiRequest('delete', '/users/' + userId, request);
}


/**
 * Notes
 * ----------------------------------------------
 */

const getNotes = async (request) => {
    apiRequest('get', '/notes', request);
}

const getNote = async (noteId, request) => {
    apiRequest('get', '/notes/' + noteId, request);
}

const createNote = async (request) => {
    apiRequest('post', '/notes', request);
}

const updateNote = async (noteId, request) => {
    apiRequest('put', '/notes/' + noteId, request);
}

const deleteNote = async (noteId, request) => {
    apiRequest('delete', '/notes/' + noteId, request);
}


/**
 * Links
 * ----------------------------------------------
 */

const getLinks = async (request) => {
    apiRequest('get', '/links', request);
}

const getLink = async (linkId, request) => {
    apiRequest('get', '/links/' + linkId, request);
}

const createLink = async (request) => {
    apiRequest('post', '/links', request);
}

const updateLink = async (linkId, request) => {
    apiRequest('put', '/links/' + linkId, request);
}

const deleteLink = async (linkId, request) => {
    apiRequest('delete', '/links/' + linkId, request);
}

/**
 * Search
 * ----------------------------------------------
 */

const search = async (term, request) => {
    apiRequest('get', '/search?q=' + term, request);
}


/**
 * Timer
 * ----------------------------------------------
 */

const getTimerStatus = async (request) => {
    apiRequest('get', '/timer', request);
}

const startTimer = async (jobId, request) => {
    request.data = {
        job_id: jobId
    }
    apiRequest('post', '/timer/start', request);
}

const pauseTimer = async (request) => {
    apiRequest('post', '/timer/pause', request);
}

const continueTimer = async (request) => {
    apiRequest('post', '/timer/continue', request);
}

const stopTimer = async (request) => {
    apiRequest('post', '/timer/stop', request);
}



/**
 * Pipelines
 * ----------------------------------------------
 */

 const getPipelines = async (request) => {
    apiRequest('get', '/pipelines', request);
}

const getPipeline = async (pipelineId, request) => {
    apiRequest('get', '/pipelines/' + pipelineId, request);
}

const createPipeline = async (request) => {
    apiRequest('post', '/pipelines', request);
}

const updatePipeline = async (pipelineId, request) => {
    apiRequest('put', '/pipelines/' + pipelineId, request);
}

const deletePipeline = async (pipelineId, request) => {
    apiRequest('delete', '/pipelines/' + pipelineId, request);
}


/**
 * Helpscout
 * ----------------------------------------------
 */

const getHelpscoutConversations = async (request) => {
    apiRequest('get', '/helpscout/conversations', request);
}

const getHelpscoutConversation = async (id, request) => {
    apiRequest('get', '/helpscout/conversations/' + id, request);
}

const importHelpscoutConversation = async (id, request) => {
    apiRequest('post', '/helpscout/conversations/' + id + '/import', request);
}


/**
 * Recurring Jobs
 * ----------------------------------------------
 */

 const getRecurringJobs = async (request) => {
    apiRequest('get', '/recurringjobs', request);
}

const getRecurringJob = async (recurringJobId, request) => {
    apiRequest('get', '/recurringjobs/' + recurringJobId, request);
}

const createRecurringJob = async (request) => {
    apiRequest('post', '/recurringjobs', request);
}

const updateRecurringJob = async (recurringJobId, request) => {
    apiRequest('put', '/recurringjobs/' + recurringJobId, request);
}

const stopRecurringJob = async (recurringJobId, request) => {
    apiRequest('put', '/recurringjobs/' + recurringJobId + '/stop', request);
}

const restartRecurringJob = async (recurringJobId, request) => {
    apiRequest('put', '/recurringjobs/' + recurringJobId + '/restart', request);
}

const deleteRecurringJob = async (recurringJobId, request) => {
    apiRequest('delete', '/recurringjobs/' + recurringJobId, request);
}



/**
 * Intervals
 * ----------------------------------------------
 */

 const getIntervals = async (request) => {
    apiRequest('get', '/intervals', request);
}

const getInterval = async (intervalId, request) => {
    apiRequest('get', '/intervals/' + intervalId, request);
}




/**
 * Monitors
 * ----------------------------------------------
 */

const getMonitors = async (request) => {
    apiRequest('get', '/monitors', request);
}

const getMonitor = async (monitorId, request) => {
    apiRequest('get', '/monitors/' + monitorId, request);
}

const createMonitor = async (request) => {
    apiRequest('post', '/monitors', request);
}

const updateMonitor = async (monitorId, request) => {
    apiRequest('put', '/monitors/' + monitorId, request);
}

const deleteMonitor = async (monitorId, request) => {
    apiRequest('delete', '/monitors/' + monitorId, request);
}

/**
 * Documentations
 * ----------------------------------------------
 */

const getDocumentations = async (request) => {
    apiRequest('get', '/documentations', request);
}

const getDocumentation = async (documentationId, request) => {
    apiRequest('get', '/documentations/' + documentationId, request);
}

const createDocumentation = async (request) => {
    apiRequest('post', '/documentations', request);
}

const updateDocumentation = async (documentationId, request) => {
    apiRequest('put', '/documentations/' + documentationId, request);
}

const deleteDocumentation = async (documentationId, request) => {
    apiRequest('delete', '/documentations/' + documentationId, request);
}


/**
 * Documentation Pages
 * ----------------------------------------------
 */

const getDocumentationPages = async (request) => {
    apiRequest('get', '/documentationpages', request);
}

const getDocumentationPage = async (documentationPageId, request) => {
    apiRequest('get', '/documentationpages/' + documentationPageId, request);
}

const createDocumentationPage = async (request) => {
    apiRequest('post', '/documentationpages', request);
}

const updateDocumentationPage = async (documentationPageId, request) => {
    apiRequest('put', '/documentationpages/' + documentationPageId, request);
}

const deleteDocumentationPage = async (documentationPageId, request) => {
    apiRequest('delete', '/documentationpages/' + documentationPageId, request);
}

const uploadDocumentationImage = async (documentationPageId, request) => {
    apiRequest('post', '/documentationpages/' + documentationPageId + '/upload-image', request);
}

const deleteDocumentationImage = async (documentationPageId, request) => {
    apiRequest('post', '/documentationpages/' + documentationPageId + '/delete-image', request);
}



const output = {
    accounts: {
        list: getAccounts,
        get: getAccount,
        create: createAccount,
        update: updateAccount,
        delete: deleteAccount,
        uploadLogo: uploadAccountLogo,
        uploadLifestylePhoto: uploadAccountLifestylePhoto,
        statement: getAccountStatement,
        statementPdf: getAccountStatementPdf
    },
    countries: {
        list: getCountries,
        get: getCountry,
        create: createCountry,
        update: updateCountry,
        delete: deleteCountry
    },
    contracts: {
        list: getContracts,
        get: getContract,
        create: createContract,
        update: updateContract,
        delete: deleteContract,
        statement: getContractStatement,
        statementPdf: getContractStatementPdf,
        usage: getUsageData,
        annualUsage: getAnnualUsageData,
    },
    persons: {
        list: getPersons,
        get: getPerson,
        create: createPerson,
        update: updatePerson,
        delete: deletePerson
    },
    jobs: {
        list: getJobs,
        get: getJob,
        create: createJob,
        update: updateJob,
        delete: deleteJob,
        markBilled: markJobAsBilled,
        listUnbilled: getUnbilledJobs,
        close: closeJob,
        reopen: reopenJob,
        listDashboard: getDashboardJobs
    },
    stages: {
        list: getStages,
        get: getStage,
        create: createStage,
        update: updateStage,
        delete: deleteStage
    },
    times: {
        list: getTimes,
        get: getTime,
        create: createTime,
        update: updateTime,
        delete: deleteTime
    },
    meetingNotes: {
        list: getMeetingNotes,
        get: getMeetingNote,
        create: createMeetingNote,
        update: updateMeetingNote,
        delete: deleteMeetingNote
    },
    templates: {
        list: getTemplates,
        get: getTemplate,
        pdf: getTemplatePdf,
        create: createTemplate,
        update: updateTemplate,
        delete: deleteTemplate
    },
    files: {
        list: getFiles,
        get: getFile,
        create: createFile,
        update: updateFile,
        delete: deleteFile,
        download: downloadFile
    },
    users: {
        list: getUsers,
        get: getUser,
        create: createUser,
        update: updateUser,
        delete: deleteUser
    },
    notes: {
        list: getNotes,
        get: getNote,
        create: createNote,
        update: updateNote,
        delete: deleteNote
    },
    links: {
        list: getLinks,
        get: getLink,
        create: createLink,
        update: updateLink,
        delete: deleteLink
    },
    search,
    timer: {
        getStatus: getTimerStatus,
        start: startTimer,
        pause: pauseTimer,
        continue: continueTimer,
        stop: stopTimer
    },
    pipelines: {
        list: getPipelines,
        get: getPipeline,
        create: createPipeline,
        update: updatePipeline,
        delete: deletePipeline
    },
    helpscout: {
        conversations: {
            list: getHelpscoutConversations,
            get: getHelpscoutConversation,
            import: importHelpscoutConversation
        }
    },
    recurringjobs: {
        list: getRecurringJobs,
        get: getRecurringJob,
        create: createRecurringJob,
        update: updateRecurringJob,
        delete: deleteRecurringJob,
        stop: stopRecurringJob,
        restart: restartRecurringJob
    },
    intervals: {
        list: getIntervals,
        get: getInterval
    },
    monitors: {
        list: getMonitors,
        get: getMonitor,
        create: createMonitor,
        update: updateMonitor,
        delete: deleteMonitor
    },
    documentations: {
        list: getDocumentations,
        get: getDocumentation,
        create: createDocumentation,
        update: updateDocumentation,
        delete: deleteDocumentation
    },
    documentationpages: {
        list: getDocumentationPages,
        get: getDocumentationPage,
        create: createDocumentationPage,
        update: updateDocumentationPage,
        delete: deleteDocumentationPage,
        uploadImage: uploadDocumentationImage,
        deleteImage: deleteDocumentationImage
    },
};

export default output;
