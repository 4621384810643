// Packages
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import Duration from 'components/Duration';
import Checkbox from 'components/UI/Checkbox';

const UnbilledList = props => {
	
	const { items } = props;

	
	return (
		
		<table className="table">
			<thead>
			<tr>
				<th style={{width:'0px'}}></th>
				<th>Job Name</th>
				<th className='r'>Date Started</th>
				<th>Account Name</th>
				<th>User</th>
				<th>Time</th>
				<th className='r' style={{'paddingRight': '30px'}}>Price</th>
			</tr>
			</thead>
			<tbody>

				{items.map((item) => {

					return (

						<tr key={item.id}>
							<td><Checkbox style={{width:'10px',marginTop:'15px',marginBottom:'0',padding:0}} option={{id: item.id, name: '', isChecked: false}} name="unbilled" /></td>
							<td><Link to={`/jobs/${item.id}`}><strong>{item.name}</strong></Link></td>
							<td className='r'>{dayjs(item.started_at).format('DD MM YYYY')}</td>
							<td><Link to={`/accounts/${item.account_id}`}><strong>{item.account.name}</strong></Link></td>
							<td>{item.user ? item.user.name : ''}</td>
							<td><Duration seconds={item.seconds_taken}/></td>
							<td className='r' style={{'paddingRight': '30px'}}>{item.price > 0 ? `£${item.price}` : ''}</td>
						</tr>

					);


				})}
			</tbody>
		</table>
	
		);
}

export default UnbilledList;