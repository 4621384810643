// Packages
import { useState, useEffect } from "react";
import PropTypes from 'prop-types';

// Assets
import './styles.css';

const Checkbox = props => {

	// Props
	const { option, checked, name, style } = props;


	// State
	const [ isChecked, setIsChecked ] = useState(false);

	useEffect(() => {

		setIsChecked(checked);

	}, [option, name, checked]);


	return (
		<div className="checkboxDiv" style={style}>
			<input type="checkbox" id={`${name}-${option.id}`} name={name} checked={isChecked} onChange={() =>
			{
				setIsChecked(!isChecked);
			}} className="checkboxDiv__checkbox"/>

			<label className="checkboxDiv__label" htmlFor={`checkbox-${option.id}`}>{option.name}</label>
		</div>
	);
	
}


// Prop Types
Checkbox.propTypes = {
	option: PropTypes.object.isRequired,
	checked: PropTypes.bool,
	name: PropTypes.string,
	style: PropTypes.object
}


export default Checkbox;