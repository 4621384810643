// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Stage';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { stageId } = useParams();

    const navigate = useNavigate();


    const deleteStage = () => {

        if ( ! window.confirm('Are you sure that you want to delete this stage?') )
        {
            return false;
        }

        Api.stages.delete(stageId, {
            successRedirect: () => {
                navigate('/stages');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Stage"/>

        <Form stageId={stageId}/>

        <Button onClick={deleteStage} type="delete" label="Delete Stage"/>
        </>
    )
}

export default EditScreen;