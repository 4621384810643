// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Accounts/List';
import ViewScreen from 'screens/Accounts/View';
import CreateScreen from 'screens/Accounts/Create';
import EditScreen from 'screens/Accounts/Edit';
import StatementScreen from 'screens/Accounts/Statement';


// Layouts
import DefaultLayout from 'layouts/Default';


const AccountRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/:accountId" element={<ViewScreen/>}/>
				<Route exact path="/:accountId/edit" element={<EditScreen/>}/>
				<Route exact path="/:accountId/statement" element={<StatementScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default AccountRoutes