// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Person';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { personId } = useParams();

    const navigate = useNavigate();


    const deleteAccount = () => {

        if ( ! window.confirm('Are you sure that you want to delete this person?') )
        {
            return false;
        }

        Api.persons.delete(personId, {
            successRedirect: () => {
                
                navigate('/persons');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Person"/>

        <Form personId={personId}/>

        <Button onClick={deleteAccount} type="delete" label="Delete Person"/>
        </>
    )
}

export default EditScreen;