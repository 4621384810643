// Packages
import { useState, useEffect } from 'react';


// Assets
import './styles.css';


const ListFilter = props => {

    // Props
    const { options, selected, onChange } = props;

    
    // State
    const [selectedOption, setSelectedOption] = useState();


    useEffect(() => {
        setSelectedOption(selected);
    }, [selected])

    
    return (
        <div className="ListFilter">
            {options.map((option) => {

                return (
                    <div key={option.name} onClick={() => {

                        setSelectedOption(option.name);
                        onChange(option.name);

                    }} className={`ListFilter__option ListFilter__option--colour-${option.colour} ${option.name === selectedOption ? 'ListFilter__option--selected' : ''}`}>
                        {option.label}
                    </div>
                )

            })}
        </div>  
    );  
}



ListFilter.defaultProps = {
    colour: '',
    selected: '',
    onChange: () => { alert('No onChange set')}
}

export default ListFilter;