// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/HelpScout/ListConversations';
import ImportScreen from 'screens/HelpScout/Import';


// Layouts
import DefaultLayout from 'layouts/Default';


const HelpScoutRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<ListScreen/>}/>
                <Route exact path="/:conversationId/import" element={<ImportScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default HelpScoutRoutes