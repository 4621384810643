// Packages
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'


// Components
import PropTypes from 'prop-types';
import Overlay from 'components/UI/Overlay';


// Others
import Api from 'other/Api';


// Assets
import './styles.css';


const ImageUpload = props => {

    // Props
    const { accountId, documentationPageId, onClose, onFileUpload, imageUrl, imageArray, type } = props;


    // State
    const [ showUpload, setShowUpload ] = useState(false);
    const [ images, setImages ] = useState(imageArray);

    useEffect(() => {

        setImages(imageArray);
		
		// eslint-disable-next-line
	}, []);



    const onDrop = useCallback((acceptedFiles) => {

        for ( let i=0; i < acceptedFiles.length; i++ )
        {
            let data = new FormData();
            data.append('file', acceptedFiles[i]);

            if ( type === 'logo' )
            {
                Api.accounts.uploadLogo(accountId, {
                data: data,
                success: ( response ) => {
                    onFileUpload(response);
                    setShowUpload(false);
                }
                });
            }
            else if( type === 'lifestyle')
            {
                Api.accounts.uploadLifestylePhoto(accountId, {
                data: data,
                success: ( response ) => {
                    onFileUpload(response);
                    setShowUpload(false);
                }
                });
            }
            else {
                Api.documentationpages.uploadImage(documentationPageId, {
                    data: data,
                success: ( response ) => {
                    onFileUpload(response);
                    setShowUpload(false);
                    setImages(response.data.images);
                    }
                });
            }
        }    
    }, [accountId, documentationPageId, onFileUpload, type]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const hideImages = () => {

        document.getElementById('ImageUpload__overlay').classList.remove('active');
        
        let largeImages = document.getElementsByClassName('ImageUpload__images__largeImage');

        for(let i = 0; i < largeImages.length; i++) {

            largeImages[i].classList.remove('active');
        }
    }

    
    return (
        <>
        <div className="ImageUpload" style={{
            backgroundImage: "url('" + imageUrl + "')"
        }}>

            <div onClick={() => {
                setShowUpload(true);
            }} className="ImageUpload__UploadHandle"/>

        </div>

        {imageArray && 

            <div className='ImageUpload__images'>

                {images.map((image) => {
                        
                    if( image.url !== "" ) {
                    return (
                        <div key={image.filename} className="ImageUpload__images__image" onClick={() => {

                            document.getElementById('ImageUpload__overlay').classList.add('active');
                            document.getElementById(`largeImage${image.filename}`).classList.add('active');

                        }} style={{backgroundImage: "url('" + image.url + "')"}}>
                            <span onClick={() => {
                                if ( window.confirm('Are you sure that you want to delete this image?') )
                                {
                                    Api.documentationpages.deleteImage(documentationPageId, {
                                        data: {file: image.filename},
                                        success: (response) => {
                                            setImages(response.data.images);
                                        }
                                    });
                                }
                            }} className="ImageUpload__images__image__close"></span>
                            
                            <div id={`largeImage${image.filename}`} className='ImageUpload__images__largeImage'>
                                <img src={image.url} className='ImageUpload__images__largeImage__image' alt='Large pic'/>
                            </div>
                        </div>
                        );
                    }
                    else {
                        return(<></>);
                    }
                }
                )}

                <div id='ImageUpload__overlay' onClick={hideImages}>
                    <span  className="ImageUpload__images__largeImage__close" onClick={hideImages}></span>
                </div>
            </div>
        }

        { showUpload &&

            <Overlay onClose={() => {
                setShowUpload(false);
                onClose();
            }}>
            <div className={`FileUpload ${isDragActive?'FileUpload--active': ''}`}>

            <div {...getRootProps({className: "dropzone"})}>
                  <input {...getInputProps()} />

                  <div className="FileUpload__text">
                  {
                    isDragActive ?
                      <p>Drop your file here</p> :
                      <p>Drop your logo file here</p>
                  }
                </div>

            </div>

            </div>
            </Overlay>
        }
        </>
    );
}

ImageUpload.propTypes = {
    onClose: PropTypes.func.isRequired,
    onFileUpload: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['logo', 'lifestyle', 'documentation'])
}

ImageUpload.defaultProps = {
    accountId: null,
    documentationId: null,
    onClose: () => {},
    onFileUpload: () => {},
    type: 'logo'
};

export default ImageUpload;