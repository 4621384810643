// Packages
import { useState, useEffect } from 'react';


// Components
import Loading from 'components/UI/Loading';


// Other
import Api from 'other/Api';


// Assets
import './styles.css';

const ClassNames = require('classnames');


const Pipeline = props => {

	// Props
	const { defaultStageId, onChange } = props;


	// State
	const [ stages, setStages ] = useState([]);
	const [ stageId, setStageId ] = useState(defaultStageId);
	const [ loading, setLoading ] = useState(true);


	useEffect(() => {
		setStageId(defaultStageId);
	}, [defaultStageId]);


	useEffect(() => {

		Api.stages.list({
			success: (response) => {
				setStages(response.data);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);


	const setNewStage = (stageId) => {
		onChange(stageId);
		setStageId(stageId);
	}


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<div className="Pipeline">

			<ul className="Pipeline__list">

			{stages.map((item) => {

				const classes = ClassNames({
					Pipeline__list__item: true,
					[`Pipeline__list__item--colour-${item.colour}`]: stageId===item.id,
					'Pipeline__list__item--selected': stageId===item.id,
					'Pipeline__list__item--notselected': stageId!==item.id
				});

				return (
					<li onClick={() => {
						setNewStage(item.id);
					}} key={item.id} className={classes}>{item.name}</li>
				);

			})}

			</ul>

		</div>
	);


}

export default Pipeline;