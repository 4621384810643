// Packages
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';


// Other
import Api from 'other/Api';


const PersonForm = props => {

	// Props
	const { personId } = props;


	// URL parameters
	const [ queryParams ] = useSearchParams();


	// State - Form fields
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [email, setEmail] = useState('');
	const [position, setPosition] = useState('');
	const [notes, setNotes] = useState('');
	const [accountId, setAccountId] = useState(queryParams.get('account_id')??null);


	// state - Select options
	const [ accountOptions, setAccountOptions] = useState(null);


	// State
	const [loading, setLoading] = useState(accountId);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {

		/**	
		 * Build generic request to either create or update
		 * the contract
		 */
		const request = {
			data: {
				firstname,
				lastname,
				position,
				email, 
				notes,
				account_id: accountId
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				navigate('/persons/' + response.id);
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( personId )
		{
			// Update existing person
			Api.persons.update(personId, request);
	    }
	    else
	    {
	    	// Add a new person
	    	Api.persons.create(request);
	    }
	}


	useEffect(() => {

		if ( personId )
		{
			/**
			 * Load the person into the form
			 */
	        Api.persons.get(personId, {
	            success: (response) => {
	                setFirstname(response.firstname);
					setLastname(response.lastname);
					setEmail(response.email);
					setPosition(response.position);
					setAccountId(response.account_id);
					setNotes(response.notes);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

	    

    }, [personId]);


    useEffect(() => {

    	/**
		 * Load the account options
		 */
        Api.accounts.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setAccountOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);



	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>


			<div className={`form__field ${errors.account_id?'form__field--error':''}`}>
				<label className="form__field__label">Account</label>
				<SelectList options={accountOptions} blank={true} onChange={(e) => {
					setAccountId(e.target.value);
				}} value={accountId}/>
				{ errors.account_id &&
					<div className="form__field__error">
						{errors.account_id.join('<br/>')}
					</div>
				}
			</div>


			
			<div className={`form__field ${errors.firstname?'form__field--error':''}`}>
				<label className="form__field__label">First name</label>
				<input onChange={(e) => {
					setFirstname(e.target.value)
				}} name="firstname" value={firstname ?? ''} className="form__field__input"/>
				{ errors.firstname &&
					<div className="form__field__error">
						{errors.firstname.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.lastname?'form__field--error':''}`}>
				<label className="form__field__label">Last name</label>
				<input onChange={(e) => {
					setLastname(e.target.value)
				}} name="lastname" value={lastname ?? ''} className="form__field__input"/>
				{ errors.lastname &&
					<div className="form__field__error">
						{errors.lastname.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.position?'form__field--error':''}`}>
				<label className="form__field__label">Position</label>
				<input onChange={(e) => {
					setPosition(e.target.value)
				}} name="position" value={position ?? ''} className="form__field__input"/>
				{ errors.position &&
					<div className="form__field__error">
						{errors.position.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.email?'form__field--error':''}`}>
				<label className="form__field__label">Email address</label>
				<input onChange={(e) => {
					setEmail(e.target.value)
				}} name="email" value={email ?? ''} className="form__field__input"/>
				{ errors.email &&
					<div className="form__field__error">
						{errors.email.join('<br/>')}
					</div>
				}
			</div>


			<div className={`form__field ${errors.notes?'form__field--error':''}`}>
				<label className="form__field__label">Notes</label>
				<textarea onChange={(e) => {
					setNotes(e.target.value)
				}} name="notes" rows="10" value={notes ?? ''} className="form__field__input"/>
				{ errors.notes &&
					<div className="form__field__error">
						{errors.notes.join('<br/>')}
					</div>
				}
			</div>

			

			
		

		



			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Person"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
PersonForm.propTypes = {
	personId: PropTypes.string
}


export default PersonForm;