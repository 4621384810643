// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Pipeline';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { pipelineId } = useParams();

    const navigate = useNavigate();


    const deletePipeline = () => {

        if ( ! window.confirm('Are you sure that you want to delete this pipeline?') )
        {
            return false;
        }

        Api.pipelines.delete(pipelineId, {
            successRedirect: () => {
                navigate('/pipelines');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Pipeline"/>

        <Form pipelineId={pipelineId}/>

        <Button onClick={deletePipeline} type="delete" label="Delete Pipeline"/>
        </>
    )
}

export default EditScreen;