// Packages
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'


// Components
import PropTypes from 'prop-types';
import Button from 'components/UI/Button';
import Overlay from 'components/UI/Overlay';


// Others
import Api from 'other/Api';


// Assets
import './styles.css';


const FileUpload = props => {

    // Props
    const { accountId, documentationPageId, contractId, jobId, onClose, onFileUpload } = props;


    // State
    const [ showUpload, setShowUpload ] = useState(false);


    const onDrop = useCallback((acceptedFiles) => {

        for ( let i=0; i < acceptedFiles.length; i++ )
        {
            let data = new FormData();
            data.append('file', acceptedFiles[i]);

            if ( null !== accountId )
            {
                data.append('account_id', accountId);
            }

            if ( null !== contractId )
            {
                data.append('contract_id', contractId);
            }

            if ( null !== jobId )
            {
                data.append('job_id', jobId);
            }

            if ( null !== documentationPageId )
            {
                data.append('documentation_page_id', documentationPageId);
            }
            
            Api.files.create({
              data: data,
              success: ( response ) => {
                onFileUpload(response);
              }
            });
        }    
    }, [accountId, contractId, jobId, onFileUpload, documentationPageId]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    
    return (
        <>

        <Button onClick={() => {
                setShowUpload(true);
            }} label="Upload Files"/>
            
        <br/><br/>

        { showUpload &&

            <Overlay onClose={() => {
                setShowUpload(false);
                onClose()
            }}>
            <div className={`FileUpload ${isDragActive?'FileUpload--active': ''}`}>

            <div {...getRootProps({className: "dropzone"})}>
                  <input {...getInputProps()} />

                  <div className="FileUpload__text">
                  {
                    isDragActive ?
                      <p>Drop your files here</p> :
                      <p>Drag 'n' drop some files here</p>
                  }
                </div>

            </div>

            </div>
            </Overlay>
        }

        </>
    );
}

FileUpload.propTypes = {
    onClose: PropTypes.func.isRequired,
    onFileUpload: PropTypes.func.isRequired
}

FileUpload.defaultProps = {
    accountId: null,
    contractId: null,
    jobId: null,
    documentationPageId: null,
    onClose: () => {},
    onFileUpload: () => {}
};

export default FileUpload;