// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import HelpScoutImportForm from 'components/Forms/HelpScoutImport';


// Others
import Api from 'other/Api';


const ImportScreen = () => {

    // URL Parameters
    const { conversationId } = useParams();


    // State
    const [conversation, setConversation] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Api.helpscout.conversations.get(conversationId, {
            success: (response) => {
                setConversation(response);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, [conversationId]);


    if ( loading )
    {
        return <Loading/>;
    }

    return (
        <>
            <ScreenTitle title="Import HelpScout Ticket"/>

            <HelpScoutImportForm conversation={conversation}/>
        </>
    );
}

export default ImportScreen;