// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Contracts/List';
import ViewScreen from 'screens/Contracts/View';
import CreateScreen from 'screens/Contracts/Create';
import EditScreen from 'screens/Contracts/Edit';
import StatementScreen from 'screens/Contracts/Statement';


// Layouts
import DefaultLayout from 'layouts/Default';


const ContractRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/:contractId" element={<ViewScreen/>}/>
				<Route exact path="/:contractId/edit" element={<EditScreen/>}/>
                <Route exact path="/:contractId/statement" element={<StatementScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default ContractRoutes