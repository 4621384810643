// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import nl2br from 'react-nl2br';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import InfoPanel from 'components/UI/InfoPanel';

// Others
import Api from 'other/Api';


const PersonViewScreen = () => {

	// URL Parameters
	const { personId } = useParams();


	// State
	const [person, setPerson] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {

		Api.persons.get(personId, {
			params: {
				with: ['account']
			},
			success: (response) => {
				setPerson(response);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, [personId]);


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title={`${person.firstname} ${person.lastname}`} breadcrumb={[
				{label: 'People', url: '/persons'}
			]}>
				<Button url={`/persons/${person.id}/edit`} label="Edit Person" colour="blue"/>
			</ScreenTitle>


			<InfoPanel.Wrapper>

				<InfoPanel.Column>

					<InfoPanel.Item label="Account" value={person.account.name} link={`/accounts/${person.account_id}`}/>

					<InfoPanel.Item label="Position" value={person.position}/>

				</InfoPanel.Column>

				<InfoPanel.Column>

					<InfoPanel.Item label="Email" value={person.email}/>
				
				</InfoPanel.Column>

			</InfoPanel.Wrapper>



			{ person.notes &&
				<InfoPanel.Wrapper title="Notes">
					<InfoPanel.Item value={nl2br(person.notes)}/>
				</InfoPanel.Wrapper>
			}


			

		</>
	);
}

export default PersonViewScreen;