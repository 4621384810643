// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import nl2br from 'react-nl2br';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import Tabs from 'components/UI/Tabs';
import ContractsList from 'components/Lists/Contracts';
import PersonsList from 'components/Lists/Persons';
import JobsList from 'components/Lists/Jobs';
import FilesList from 'components/Lists/Files';
import NotesList from 'components/Lists/Notes';
import LinksList from 'components/Lists/Links';
import InfoPanel from 'components/UI/InfoPanel';
import FileUpload from 'components/FileUpload';
import NoteForm from 'components/Forms/Note';
import ImageUpload from 'components/ImageUpload';


// Others
import Api from 'other/Api';


const AccountViewScreen = () => {

	// URL Parameters
	const { accountId } = useParams();


	// State
	const [account, setAccount] = useState([]);
	const [loading, setLoading] = useState(true);

	const [contracts, setContracts] = useState([]);
	const [contractsLoading, setContractsLoading] = useState(true);

	const [persons, setPersons] = useState([]);
	const [personsLoading, setPersonsLoading] = useState(true);

	const [jobs, setJobs] = useState([]);
	const [jobsLoading, setJobsLoading] = useState(true);

	const [files, setFiles] = useState([]);
	const [filesLoading, setFilesLoading] = useState(true);

    const [notes, setNotes] = useState([]);
    const [notesLoading, setNotesLoading] = useState(true);

    const [links, setLinks] = useState([]);
    const [linksLoading, setLinksLoading] = useState(true);


    const loadFiles = () => {

        Api.files.list({
            params: {
                account_id: accountId
            },
            before: () => {
                setFilesLoading(true);
            },
            success: (response) => {
                setFiles(response.data);
            },
            after: () => {
                setFilesLoading(false);
            }
        }); 
    }

    const loadNotes = () => {

        Api.notes.list({
            params: {
                account_id: accountId
            },
            before: () => {
                setNotesLoading(true);
            },
            success: (response) => {
                setNotes(response.data);
            },
            after: () => {
                setNotesLoading(false);
            }
        }); 
    }

    const loadLinks = () => {

        Api.links.list({
            params: {
                account_id: accountId
            },
            before: () => {
                setLinksLoading(true);
            },
            success: (response) => {
                setLinks(response.data);
            },
            after: () => {
                setLinksLoading(false);
            }
        }); 
    }

	useEffect(() => {

		Api.accounts.get(accountId, {
			params: {
				with: ['country']
			},
			success: (response) => {
				setAccount(response);
			},
			after: () => {
				setLoading(false);
			}
		});

		Api.contracts.list({
			params: {
				account_id: accountId
			},
			success: (response) => {
				setContracts(response.data);
			},
			after: () => {
				setContractsLoading(false);
			}
		});


		Api.persons.list({
			params: {
				account_id: accountId
			},
			success: (response) => {
				setPersons(response.data);
			},
			after: () => {
				setPersonsLoading(false);
			}
		});

		Api.jobs.list({
			params: {
				account_id: accountId,
				with: ['account', 'stage', 'user', 'contract', 'person'],
			},
			success: (response) => {
				setJobs(response.data);
			},
			after: () => {
				setJobsLoading(false);
			}
		});

        loadFiles();
        loadNotes();
        loadLinks();
        // eslint-disable-next-line
	}, [accountId]);


    


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title={account.name} breadcrumb={[
				{label: 'Accounts', url: '/accounts'}
			]}>
				<Button url={`/accounts/${account.id}/edit`} label="Edit Account" colour="blue"/>
				<Button url={`/accounts/${account.id}/statement`} label="Generate Statement" colour="orange"/>
			</ScreenTitle>



			<InfoPanel.Wrapper>

				<InfoPanel.Column>

					<InfoPanel.Item label="Address">
						{nl2br(account.address)}<br/>
						{account.city}<br/>
						{account.postcode} 

						{ account.country && 
							<><br/>{account.country.name}</>
						}
					</InfoPanel.Item>

				</InfoPanel.Column>



                <InfoPanel.Column>

                    <InfoPanel.Item label="Logo">

                       <ImageUpload type="logo" onFileUpload={(response) => {
                        setAccount(response);
                       }} imageUrl={account.logo_url} accountId={account.id}/>            
                    
                    </InfoPanel.Item>

                </InfoPanel.Column>

                <InfoPanel.Column>

                    <InfoPanel.Item label="Lifestyle Photo">

                       <ImageUpload type="lifestyle" onFileUpload={(response) => {
                        setAccount(response);
                       }} imageUrl={account.lifestyle_photo_url} accountId={account.id}/>            
                    
                    </InfoPanel.Item>


                </InfoPanel.Column>


			</InfoPanel.Wrapper>

			


			<Tabs.Wrapper defaultTab="notes" tabs={[
                {name: 'notes', label: 'Notes', count: notes.length},
                {name: 'jobs', label: 'Jobs', count: jobs.length},
				{name: 'contracts', label: 'Contracts', count: contracts.length},
				{name: 'files', label: 'Files', count: files.length},
				{name: 'persons', label: 'People', count: persons.length},
                {name: 'links', label: 'Links', count: links.length}
			]}>

				<Tabs.Panel name="contracts">

					<Button url={`/contracts/create?account_id=${account.id}`} size="small" label="Create Contract"/>

				<br/>
				<br/>

					{ contractsLoading &&
						<Loading message="Loading contracts"/>
					}

					{ ! contractsLoading &&
						<ContractsList items={contracts}/>
					}
					

				</Tabs.Panel>

				<Tabs.Panel name="jobs">

					{ jobsLoading &&
						<Loading message="Loading Jobs"/>
					}

					{ ! jobsLoading &&
						<JobsList items={jobs}/>
					}

				</Tabs.Panel>


				<Tabs.Panel name="persons">

				<Button url={`/persons/create?account_id=${account.id}`} size="small" label="Add Person"/>

				<br/>
				<br/>	

					{ personsLoading &&
						<Loading message="Loading people"/>
					}

					{ ! personsLoading &&
						<PersonsList items={persons}/>
					}			

				</Tabs.Panel>


				<Tabs.Panel name="files">

	                <FileUpload accountId={account.id} onFileUpload={() => {
                        loadFiles();
                    }}/>

				<br/>
				<br/>	

					{ filesLoading &&
						<Loading message="Loading files"/>
					}

					{ ! filesLoading &&
						<FilesList items={files} onChange={loadFiles}/>
					}			

				</Tabs.Panel>


                <Tabs.Panel name="notes">

                    <NoteForm 
                        onNoteCreate={loadNotes} 
                        accountId={account.id}
                    />

                    <br/><br/>

                    { notesLoading &&
                        <Loading message="Loading notes"/>
                    }

                    { ! notesLoading &&
                        <NotesList items={notes} onChange={loadNotes}/>
                    }           

                </Tabs.Panel>

                <Tabs.Panel name="links">

                    <Button url={`/links/create?account_id=${account.id}`} size="small" label="Create Link"/>

                    <br/>
                    <br/>

                    { linksLoading &&
                        <Loading message="Loading links"/>
                    }

                    { ! linksLoading &&
                        <LinksList items={links} onChange={loadLinks}/>
                    }

                </Tabs.Panel>

			</Tabs.Wrapper>

		</>
	);
}

export default AccountViewScreen;