// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import DocumentationList from 'components/Lists/Documentation';


// Others
import Api from 'other/Api';


const DocumentationListScreen = () => {

	// State
	const [documentations, setDocumentations] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		Api.documentations.list({
			params: {
			},
			success: (response) => {
				setDocumentations(response.data);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title="Documentation">
				<Button url="/documentations/create" label="Create Documentation" colour="blue"/>
			</ScreenTitle>

			<DocumentationList items={documentations}/>

		</>
	);
}

export default DocumentationListScreen;