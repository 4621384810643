// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Documentations/List';
import ViewScreen from 'screens/Documentations/View';
import CreateScreen from 'screens/Documentations/Create';
import EditScreen from 'screens/Documentations/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const DocumentationRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/:documentationId" element={<ViewScreen/>}/>
				<Route exact path="/:documentationId/edit" element={<EditScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default DocumentationRoutes