// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Account';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { accountId } = useParams();

    const navigate = useNavigate();


    const deleteAccount = () => {

        if ( ! window.confirm('Are you sure that you want to delete this account?') )
        {
            return false;
        }

        Api.accounts.delete(accountId, {
            successRedirect: () => {
                
                navigate('/accounts');
            }
        })

        alert('delete');
    }


    return (
        <>
        <ScreenTitle title="Edit Account"/>

        <Form accountId={accountId}/>

        <Button onClick={deleteAccount} type="delete" label="Delete Account"/>
        </>
    )
}

export default EditScreen;