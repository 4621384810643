// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import InfoPanel from 'components/UI/InfoPanel';


// Others
import Api from 'other/Api';
import dayjs from 'dayjs';

import './styles.css';
import MonitorChecksList from 'components/Lists/MonitorChecks';


const MonitorViewScreen = () => {

	// URL Parameters
	const { monitorId } = useParams();


	// State
	const [monitor, setMonitor] = useState(null);
	const [loading, setLoading] = useState(true);
	const [chartData, setChartData] = useState(null)
	const [last24hrsChecks, setLast24hrsChecks] = useState(null)


	// Retrieve Monitor
	useEffect(() => {

		if(monitorId) {

			Api.monitors.get(monitorId, {
				params: {
					with: ['checks'],
				},
				success: (response) => {
					setMonitor(response);
				},
				after: () => {
					setLoading(false);
				}
			});

		}
		// eslint-disable-next-line
	}, [monitorId]);


	// Build moniter checks chart data
	useEffect(() => {

	if(monitor && monitor.checks) {

		let data = [];
		let filteredChecks = [];
		let unix24hrsAgo = dayjs().unix() - 86400;

		for(let i =0; i < monitor.checks.length; i++) {

			if( dayjs(monitor.checks[i].created_at).unix() > unix24hrsAgo) {

				filteredChecks.push(monitor.checks[i]);

				data.push({
					time: dayjs(monitor.checks[i].created_at).format('HH:mm'),
					status: monitor.checks[i].status === 'error' ? 0 : 1,
					response_time: monitor.checks[i].response_time
				}) 
			}
		}

		filteredChecks.sort((a, b) => (dayjs(a.created_at).unix() < dayjs(b.created_at).unix()) ? 1 : -1)

		setChartData(data);
		setLast24hrsChecks(filteredChecks);
	}

	}, [monitor]);



	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title={monitor.name} breadcrumb={[
				{label: 'Monitors', url: '/monitors'}
			]}>
				<Button url={`/monitors/${monitor.id}/edit`} label="Edit Monitor" colour="blue"/>
			</ScreenTitle>

			<InfoPanel.Wrapper>

				<InfoPanel.Column>

					<InfoPanel.Item label="Name">
						{monitor.name}
					</InfoPanel.Item>
					<InfoPanel.Item label="Type">
						{monitor.type}
					</InfoPanel.Item>
					<InfoPanel.Item label="Host">
						{monitor.host}
					</InfoPanel.Item>
					<InfoPanel.Item label="Interval">
						{monitor.interval}
					</InfoPanel.Item>
					<InfoPanel.Item label="Status">
						{monitor.status}
					</InfoPanel.Item>

				</InfoPanel.Column>

				<InfoPanel.Column>

					<InfoPanel.Item label="Response Time">
						{monitor.response_time}
					</InfoPanel.Item>
					<InfoPanel.Item label="Response Code">
						{monitor.response_code}
					</InfoPanel.Item>
					<InfoPanel.Item label="Last Check">
						{monitor.last_check_at ? dayjs(monitor.last_check_at).format('DD MMM YYYY HH:mm:ss') : ''}
					</InfoPanel.Item>
					<InfoPanel.Item label="Next Check">
						{monitor.next_check_at ? dayjs(monitor.next_check_at).format('DD MMM YYYY HH:mm:ss') : ''}
					</InfoPanel.Item>
					<InfoPanel.Item label="Created">
						{monitor.created_at ? dayjs(monitor.created_at).format('DD MMM YYYY HH:mm:ss') : ''}
					</InfoPanel.Item>

				</InfoPanel.Column>

			</InfoPanel.Wrapper>

			<h3 className='chartTitle'>Checks:</h3>

			<ResponsiveContainer width="100%" height="30%">
				<LineChart width={1000} height={300} data={chartData}>
					<Line type="monotone" dataKey="status" stroke="#8884d8" />
					<XAxis dataKey="time" />
					<YAxis />
					<Tooltip />
				</LineChart>
			</ResponsiveContainer>

			<h3 className='chartTitle'>Response time:</h3>

			<ResponsiveContainer width="100%" height="30%">
				<LineChart width={1000} height={300} data={chartData}>
					<Line type="monotone" dataKey="response_time" stroke="#8884d8" />
					<XAxis dataKey="time" />
					<YAxis />
					<Tooltip />
				</LineChart>
			</ResponsiveContainer>

			<MonitorChecksList items={last24hrsChecks ?? []} /> 

		</>
	);
}

export default MonitorViewScreen;
