// Packages
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';


// Other
import Api from 'other/Api';



const HelpScoutImportForm = props => {

    // Props
    const { conversation } = props;


    // URL parameters
    const [ queryParams ] = useSearchParams();


    // State - Form fields
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [accountId, setAccountId] = useState(queryParams.get('account_id')??'');
    const [contractId, setContractId] = useState(queryParams.get('contract_id')??'');
    const [personId, setPersonId] = useState(queryParams.get('person_id')??'');
    const [userId, setUserId] = useState('');
    const [billingStatus, setBillingStatus] = useState('contracted');


    // state - Select options
    const [ accountOptions, setAccountOptions] = useState(null);
    const [ contractOptions, setContractOptions] = useState([]);
    const [ personOptions, setPersonOptions] = useState([]);
    const [ userOptions, setUserOptions] = useState([]);
    const billingStatusOptions = [
		{id: 'not applicable', name: 'Not Applicable'},
		{id: 'contracted', name: 'Contracted'},
		{id: 'pending', name: 'Pending'},
		{id: 'billed', name: 'Billed'}
	];


    // State
    const [loading, setLoading] = useState(accountId);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({});


    const navigate = useNavigate();



    
    /**
     * Form submit
     */
    const submit = () => {

        /** 
         * Build generic request to either create or update
         * the contract
         */
        const request = {
            data: {
                name,
                description,
                account_id: accountId,
                contract_id: contractId,
                person_id: personId,
                stage_id: 1,
                user_id: userId !== '' && userId > 0 ? userId : null,
                billing_status: billingStatus
            },
            before: () => {
                setSaving(true);
            },
            successRedirect: (response) => {
                navigate('/jobs/' + response.id);
            },
            error: (error) => {
                setErrorMessage(error.message);
                setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
        };

        Api.helpscout.conversations.import(conversation.id, request)
    }


    useEffect(() => {

        setName(conversation.subject);
        setDescription(conversation.preview);
        setAccountId(conversation.account_id);
        setPersonId(conversation.person_id);
        setUserId(conversation.user_id);
        
    }, [conversation]);


    useEffect(() => {

        /**
         * Load the account options
         */
        Api.accounts.list({
            params: {
                per_page: 500
            },
            success: (response) => {
                setAccountOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


        /**
         * Load the user options
         */
        Api.users.list({
            params: {
                per_page: 500
            },
            success: (response) => {
                setUserOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);


    useEffect(() => {

        if ( accountId === '' )
        {
            setContractOptions([]);
            setPersonOptions([]);
            return;
        }

        /**
         * Load the contract options
         */
        Api.contracts.list({
            params: {
                per_page: 500,
                account_id: accountId
            },
            success: (response) => {
                setContractOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


         /**
         * Load the person options
         */
        Api.persons.list({
            params: {
                per_page: 500,
                account_id: accountId
            },
            success: (response) => {
                setPersonOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, [accountId])


    if ( loading )
    {
        return <Loading/>
    }


    return (
        <>
        
        {errorMessage &&
            <Message colour="red" text={errorMessage}/>
        }
        
        <form className="form" autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            submit();
        }}>




            <div className={`form__field ${errors.account_id?'form__field--error':''}`}>
                <label className="form__field__label">Account</label>
                <SelectList options={accountOptions} blank={true} onChange={(e) => {
                    setAccountId(e.target.value);
                }} value={accountId}/>
                { errors.account_id &&
                    <div className="form__field__error">
                        {errors.account_id.join('<br/>')}
                    </div>
                }
            </div>


            { personOptions.length > 0 &&
                <div className={`form__field ${errors.person_id?'form__field--error':''}`}>
                    <label className="form__field__label">Person</label>
                    <SelectList options={personOptions} blank={true} onChange={(e) => {
                        setPersonId(e.target.value);
                    }} value={personId}/>
                    { errors.person_id &&
                        <div className="form__field__error">
                            {errors.person_id.join('<br/>')}
                        </div>
                    }
                </div>
            }


            { contractOptions.length > 0 &&
                <div className={`form__field ${errors.contract_id?'form__field--error':''}`}>
                    <label className="form__field__label">Contract</label>
                    <SelectList options={contractOptions} blank={true} onChange={(e) => {
                        setContractId(e.target.value);
                    }} value={contractId}/>
                    { errors.contract_id &&
                        <div className="form__field__error">
                            {errors.contract_id.join('<br/>')}
                        </div>
                    }
                </div>
            }

            { billingStatusOptions.length > 0 &&
				<div className={`form__field ${errors.billing_status?'form__field--error':''}`}>
					<label className="form__field__label">Billing Status</label>
					<SelectList options={billingStatusOptions} blank={true} onChange={(e) => {
						setBillingStatus(e.target.value);
					}} value={billingStatus}/>
					{ errors.billing_status &&
						<div className="form__field__error">
							{errors.billing_status.join('<br/>')}
						</div>
					}
				</div>
			}


            
            <div className={`form__field ${errors.name?'form__field--error':''}`}>
                <label className="form__field__label">Job name</label>
                <input onChange={(e) => {
                    setName(e.target.value)
                }} name="name" value={name ?? ''} className="form__field__input"/>
                { errors.name &&
                    <div className="form__field__error">
                        {errors.name.join('<br/>')}
                    </div>
                }
            </div>



            <div className={`form__field ${errors.description?'form__field--error':''}`}>
                <label className="form__field__label">Description</label>
                <textarea onChange={(e) => {
                    setDescription(e.target.value)
                }} name="description" rows="10" value={description ?? ''} className="form__field__input"/>
                { errors.description &&
                    <div className="form__field__error">
                        {errors.description.join('<br/>')}
                    </div>
                }
            </div>





            <div className={`form__field ${errors.user_id?'form__field--error':''}`}>
                <label className="form__field__label">User Assignment</label>
                <SelectList options={userOptions} blank={true} onChange={(e) => {
                    setUserId(e.target.value);
                }} value={userId}/>
                { errors.user_id &&
                    <div className="form__field__error">
                        {errors.user_id.join('<br/>')}
                    </div>
                }
            </div>





            


    
        

        



            <div className="form__footer">
                { saving &&
                    <Saving/>
                }

                { ! saving &&
                    <Button label="Import HelpScout Conversation"/>
                }
            </div>

        </form>
        </>
    );
}


// Prop Types
HelpScoutImportForm.propTypes = {
    conversationId: PropTypes.string
}


export default HelpScoutImportForm;