// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Users/List';
//import ViewScreen from 'screens/Templates/View';
//import CreateScreen from 'screens/Templates/Create';
//import EditScreen from 'screens/Templates/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const UserRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<ListScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default UserRoutes