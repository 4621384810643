// Packages
import PropTypes from 'prop-types';


// Assets
import './styles.css';


const EmptyState = props => {

    // Props
    const { message } = props;


    return (
        <div className="EmptyState">

            <div className="EmptyState__header"/>

            <div className="EmptyState__body">

                {message}

            </div>

        </div>
    );
}


EmptyState.defaultProps = {
    message: 'There is nothing to show here... move along now'
}

EmptyState.propTypes = {
    message: PropTypes.string.isRequired
}

export default EmptyState;