// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import ContractsList from 'components/Lists/Contracts';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const ContractListScreen = () => {

	// State
	const [contracts, setContracts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		Api.contracts.list({
			params: {
				with: ['account']
			},
			success: (response) => {
				setContracts(response.data);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title="Contracts">
				<Button url="/contracts/create" label="Create Contract" colour="blue"/>
			</ScreenTitle>

			<ContractsList items={contracts}/>

		</>
	);
}

export default ContractListScreen;