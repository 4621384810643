// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Monitors/List';
import ViewScreen from 'screens/Monitors/View';
import CreateScreen from 'screens/Monitors/Create';
import EditScreen from 'screens/Monitors/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const MonitorRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
			<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
				<Route exact path="/:monitorId" element={<ViewScreen/>}/>
				<Route exact path="/:monitorId/edit" element={<EditScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default MonitorRoutes