// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import MonitorsList from 'components/Lists/Monitors';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const MonitorListScreen = () => {

    // State
    const [monitors, setMonitors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Api.monitors.list({
            params: {
            },
            success: (response) => {
                setMonitors(response.data);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, []);


    if ( loading )
    {
        return <Loading/>;
    }

    return (
        <>
            <ScreenTitle title="Monitors">
                <Button url="/monitors/create" label="Create Monitor" colour="blue"/>
            </ScreenTitle>

            <MonitorsList items={monitors}/>

        </>
    );
}

export default MonitorListScreen;