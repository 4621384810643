// Packages
import { useState } from 'react';
import { Link } from 'react-router-dom';


// Assets
import './styles.css';


const Navigation = () => {

	const MAIN_ITEMS = [
		{ label: 'Dashboard', url: '/'},
		{ label: 'Jobs', url: '/jobs'},
		{ label: 'Accounts', url: '/accounts'},
		{ label: 'People', url: '/persons'},
		{ label: 'Contracts', url: '/contracts'},
        { label: 'Documentation', url: '/documentations'},
		{ label: 'Meeting Notes', url: '/meetingnotes'},
        { label: 'HelpScout Inbox', url: '/helpscout'},
        { label: 'Monitors', url: '/monitors'},
        { label: 'Reports', url: '/reports'},
	];

    const OTHER_ITEMS = [
        { label: 'Templates', url: '/templates'},
        { label: 'Users', url: '/users'},
        { label: 'Times', url: '/times'},
        { label: 'Pipelines', url: '/pipelines'},
        { label: 'Recurring Jobs', url: '/recurringjobs'}
    ];


    // State
    const [ showOther, setShowOther ] = useState(false);


	return (
		<div className="Navigation">

			<ul className="Navigation__list">

				{MAIN_ITEMS.map((item) => {
					return (
						<li key={item.url} className="Navigation__list__item">
							<Link to={item.url} className="Navigation__list__item__link">{item.label}</Link>
						</li>
					);
				})}

            </ul>

            { ! showOther &&
                <div onClick={() => {
                   setShowOther(!showOther); 
                }} className="Navigation__more"></div>
            }

            { showOther &&
                <div className="Navigation__other">
                    <div className="Navigation__other__heading">
                        Other options:
                    </div>
                <ul className="Navigation__list">
                {OTHER_ITEMS.map((item) => {
                    return (
                        <li key={item.url} className="Navigation__list__item">
                            <Link to={item.url} className="Navigation__list__item__link">{item.label}</Link>
                        </li>
                    );
                })}
                </ul>
                </div>
            }

		</div>
	);
}

export default Navigation;