// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Stages/List';
import CreateScreen from 'screens/Stages/Create';
import EditScreen from 'screens/Stages/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const StageRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/:stageId/edit" element={<EditScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default StageRoutes