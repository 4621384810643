// Libraries
import React from "react";
import PropTypes from 'prop-types';

// Assets
import './styles.css';

const Message = props => 
{
    const { text, colour } = props;

    return (
        <div className={`Message Message--colour-${colour}`}>
            <div className="Message__text">{text}</div>
        </div>
    );
}

// Prop types
Message.propTypes = {
    text: PropTypes.string.isRequired,
    colour: PropTypes.oneOf(['red', 'green'])
}

export default Message;