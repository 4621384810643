// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import MeetingNotesList from 'components/Lists/MeetingNotes';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const MeetingNotesListScreen = () => {

	// State
	const [notes, setNotes] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		Api.meetingNotes.list({
			params: {
				with: ['account']
			},
			success: (response) => {
				setNotes(response.data);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title="Meeting Notes">
				<Button url="/meetingnotes/create" label="Create Meeting Note" colour="blue"/>
			</ScreenTitle>

			<MeetingNotesList items={notes}/>

		</>
	);
}

export default MeetingNotesListScreen;