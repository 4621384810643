

// Assets
import './styles.css';


const JobPriority = props => {

    const { priority } = props;


    const getPriorityName = () => {
        switch(priority)
        {
            case 1:
                return 'Low Priority';
            case 3: 
                return 'High Priority';
            default:
                return '';
        }
    }





    return (
        <span className={`JobPriority JobPriority--${priority}`}>{getPriorityName()}</span>
    );
    
}


export default JobPriority;