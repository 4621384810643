// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';
import Checkboxes from 'components/UI/Checkboxes';


// Other
import Api from 'other/Api';


const MeetingNoteForm = props => {

	// Props
	const { meetingNoteId } = props;
	

	// URL parameters
	const [ queryParams ] = useSearchParams();


	// State - Form fields
	const [name, setName] = useState('');
	const [accountId, setAccountId] = useState(queryParams.get('account_id')??null);
	const [accountName, setAccountName] = useState('');
	const [persons, setPersons] = useState([]);
	const [users, setUsers] = useState([]);
	const [body, setBody] = useState('');

	// state - Select options
	const [ accountOptions, setAccountOptions] = useState(null);

	// state - Checkbox options
	const [ personOptions, setPersonOptions] = useState([]);
	const [ userOptions, setUserOptions] = useState([]);
	const [ checkedPersonOptions, setCheckedPersonOptions] = useState([]);
	const [ checkedUserOptions, setCheckedUserOptions] = useState([]);

	// State
	const [loading, setLoading] = useState(accountId);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {


		/**	
		 * Build generic request to either create or update
		 * the meeting note
		 */
		const request = {
			data: {
				name, 
				body: body, 
				account_id: accountId,
				persons: getCheckedBoxes('persons'),
				users: getCheckedBoxes('users'),
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: () => {
				navigate('/meetingnotes');
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( meetingNoteId )
		{
			// Update existing contract
			Api.meetingNotes.update(meetingNoteId, request);
	    }
	    else
	    {
	    	// Add a new contract
	    	Api.meetingNotes.create(request);
	    }
	}

	

	function getCheckedBoxes(checkboxName) {

		var checkboxes = document.getElementsByName(checkboxName);
		var checkboxesChecked = [];

		for (var i=0; i<checkboxes.length; i++) {
		
			if (checkboxes[i].checked) {
				let id = parseInt(checkboxes[i].id.replace(checkboxName + '-', ''));
				checkboxesChecked.push(id);
			}
		}
	
		return checkboxesChecked.length > 0 ? checkboxesChecked : null;
	}
	


	useEffect(() => {

		if ( meetingNoteId )
		{
			/**
			 * Load the note into the form
			 */
	        Api.meetingNotes.get(meetingNoteId, {
				params: {
					with: ['persons', 'users']
				},
	            success: (response) => {
					setPersons(response.persons);
					setUsers(response.users);
					setBody(response.body !== null ? response.body : '');
					setName(response.name);
					setAccountId(response.account_id);
					setAccountName(response.account.name);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

    }, [meetingNoteId]);


	useEffect(() => {

		// Set checked persons
		if( persons.length > 0 ) {

			let checkedIds = [];
				for(let i=0; i< persons.length; i++) {
					checkedIds.push(persons[i].id);
				}
				setCheckedPersonOptions(checkedIds);
		}

	}, [persons]);

	useEffect(() => {

		// Set checked users
		if( users.length > 0 ) {

			let checkedIds = [];
				for(let i=0; i< users.length; i++) {
					checkedIds.push(users[i].id);
				}
				setCheckedUserOptions(checkedIds);
		}

	}, [users]);

    useEffect(() => {

    	/**
		 * Load the account options
		 */
        Api.accounts.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setAccountOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);

	useEffect(() => {

		// Load person options
		Api.persons.list({
        	params: {
				with: ['account'],
        		'account_id': accountId
        	},
            success: (response) => {
                setPersonOptions(accountId !== null ? response.data : []);
				if( response.data[0] !== null ) {
					setAccountName(response.data[0].account.name);
				}
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });

	}, [accountId]);

	useEffect(() => {

		// Load user options
		Api.users.list({
            success: (response) => {
                setUserOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });

	}, [users]);

	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>


			<div className={`form__field ${errors.account_id?'form__field--error':''}`}>
				<label className="form__field__label">Account</label>
				<SelectList options={accountOptions} blank={true} onChange={(e) => {
					setAccountId(e.target.value);
				}} value={accountId}/>
				{ errors.account_id &&
					<div className="form__field__error">
						{errors.account_id.join('<br/>')}
					</div>
				}
			</div>
			
			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>
			
			{personOptions.length > 0 &&
				<Checkboxes options={personOptions} checkedOptions={checkedPersonOptions} name="persons" title={accountName} />
			}

			{userOptions.length > 0 &&
				<Checkboxes options={userOptions} checkedOptions={checkedUserOptions} name="users" title="Behind The Dot" />
			}

		
			<div className='form__field__textEditor'>
				<Editor
					apiKey={'zk1783nnv3566cb03f9k4ze615o2ofnhtezwz0jobn5c790t'}
					value={body}
					onEditorChange={(e) => setBody(e)}
					init={{
						menubar: true,
						plugins: [
						'advlist', 'autolink', 'lists', 'link', 'charmap',
						'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
						'insertdatetime', 'media', 'table', 'help', 'wordcount'
						],
						toolbar: 'undo redo | blocks | ' +
						'bold italic forecolor | alignleft aligncenter ' +
						'alignright alignjustify | bullist numlist outdent indent | ' +
						'removeformat | help',
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
					}}
				/>
			</div>


			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Meeting Note"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
MeetingNoteForm.propTypes = {
	contractId: PropTypes.string
}


export default MeetingNoteForm;