// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';



// Other
import Api from 'other/Api';


const DocumentationPageForm = props => {

	// Props
	const { documentationId, documentationPageId } = props;
	

	// URL Params
	const search = useLocation().search;
	
	// State - Form fields
	const [name, setName] = useState('');
	const [docId, setDocId] = useState(documentationId);
	const [parentPageId, setParentPageId] = useState(new URLSearchParams(search).get('pageId'));
	const [body, setBody] = useState('');
	const [isHomepage, setIsHomepage] = useState('n');

	// State
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});
	
	// state - Select options
	const [ documentationOptions, setDocumentationOptions] = useState(null);
	const [ parentPageOptions, setParentPageOptions] = useState(null);
	const [isHomepageOptions, setIsHompageOptions] = useState(null);

	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {


		/**	
		 * Build generic request to either create or update
		 * the documentation page
		 */
		const request = {
			data: {
				name, 
				documentation_id: parseInt(docId), 
				parent_page_id: parseInt(parentPageId) !== 0 ? parseInt(parentPageId) : null,
				body: body,
				is_homepage: isHomepage,
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				navigate('/documentations/' + response.data.documentation_id + '?pageId=' + response.data.id);
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( documentationPageId )
		{
			// Update existing documentation page
			Api.documentationpages.update(documentationPageId, request);
	    }
	    else
	    {
	    	// Add a new documentation page
	    	Api.documentationpages.create(request);
	    }
	}


	useEffect(() => {

		if ( documentationPageId )
		{
			/**
			 * Load the documentation page into the form
			 */
	        Api.documentationpages.get(documentationPageId, {
	            success: (response) => {
					setName(response.data.name);
					setDocId(response.data.documentation_id);
					setParentPageId(response.data.parent_page_id);
					setBody(response.data.body);
					setIsHomepage(response.data.is_homepage);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }
		// eslint-disable-next-line
    }, [documentationPageId]);

	useEffect(() => {

		// load is_homepage options
		setIsHompageOptions([
			{id: 'n', name: 'No'},
			{id: 'y', name: 'Yes'}
		]);

	}, []);



	useEffect(() => {

		// Load documentation options
		Api.documentations.list({
			success: (response) => {
				setDocumentationOptions(response.data);
			},
			error: (error) => {
				setErrorMessage(error.message);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);


	useEffect(() => {

		// Load documentation parent page options

		if( docId ) {

			Api.documentations.get(docId, {
				params: {
					with: ['pages']
				},
				before: () => {
					setLoading(true);
				},
				success: (response) => {

					let options = [];
					for(let i = 0; i < response.data.pages.length; i++ ) {

						if( response.data.pages[i].id !== parseInt(documentationPageId) &&  response.data.pages[i].parent_page_id !== parseInt(documentationPageId)) {
							options.push(response.data.pages[i])
						}
					}

					setParentPageOptions(options);
				},
				error: (error) => {
					setErrorMessage(error.message);
				},
				after: () => {
					setLoading(false);
				}
			});
		}
		// eslint-disable-next-line
	}, [docId]);


	// helper function to get pages for select options
	// var flattenedArray = [];
	// function flattenPages(pageArray) {

	// 	for( let i = 0; i < pageArray.length; i++ ) {

	// 		flattenedArray.push(pageArray[i]);

	// 		if( pageArray[i].hasChild === 'y' ) {

	// 			flattenPages(pageArray[i].pages);
	// 		}
	// 	}

	// 	return flattenedArray;
	// }


	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>
			
			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.documentation_id?'form__field--error':''}`}>
				<label className="form__field__label">Documentation</label>
				<SelectList options={documentationOptions} blank={false} onChange={(e) => {
					setDocId(e.target.value);
				}} value={docId}/>
				{ errors.documentation_id &&
					<div className="form__field__error">
						{errors.documentation_id.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.documentation_parent_page_id?'form__field--error':''}`}>
				<label className="form__field__label">Parent Page</label>
				<SelectList options={parentPageOptions} blank={true} onChange={(e) => {
					setParentPageId(e.target.value);
				}} value={parentPageId}/>
				{ errors.documentation_parent_page_id &&
					<div className="form__field__error">
						{errors.documentation_parent_page_id.join('<br/>')}
					</div>
				}
			</div>
		
			<label className="form__field__label">Body</label>
			
			<div className='form__field__textEditor'>
				<Editor
					apiKey={'zk1783nnv3566cb03f9k4ze615o2ofnhtezwz0jobn5c790t'}
					value={body}
					onEditorChange={(e) => setBody(e)}
					init={{
						menubar: true,
						plugins: [
						'advlist', 'autolink', 'lists', 'link', 'charmap',
						'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
						'insertdatetime', 'media', 'table', 'help', 'wordcount'
						],
						toolbar: 'undo redo | blocks | ' +
						'bold italic forecolor | alignleft aligncenter ' +
						'alignright alignjustify | bullist numlist outdent indent | ' +
						'removeformat | help',
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
					}}
				/>
			</div>

			
			<div className={`form__field ${errors.is_homepage?'form__field--error':''}`}>
				<label className="form__field__label">Set as homepage</label>
				<SelectList options={isHomepageOptions} blank={false} onChange={(e) => {
					setIsHomepage(e.target.value);
				}} value={isHomepage}/>
				{ errors.is_homepage &&
					<div className="form__field__error">
						{errors.is_homepage.join('<br/>')}
					</div>
				}
			</div>



			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Page"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
DocumentationPageForm.propTypes = {
	documentationPageId: PropTypes.string
}


export default DocumentationPageForm;