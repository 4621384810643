// Packages
import { useState, useEffect } from "react";
import PropTypes from 'prop-types';

// Components
import Checkbox from "../Checkbox";

// Assets
import './styles.css';

const Checkboxes = props => {

    const { options, checkedOptions, name, title } = props;

    // State
    const [checkboxOptions, setCheckboxOptions] = useState([]);
    const [optionsChecked, setOptionsChecked] = useState([]);
    const [heading, setHeading] = useState(title);
    const [ checkboxName, setCheckboxName ] = useState(name);

    useEffect(() => {

        setCheckboxOptions(options);
        setHeading(title);
        setCheckboxName(name);
        setOptionsChecked(checkedOptions);

    }, [options, title, name, checkedOptions]);



    return (
        <div className="checkboxes">

            <h6 className="checkboxes__title">{heading}</h6>

            {checkboxOptions && checkboxOptions.map((option) => {

                return(
                    <Checkbox option={option} checked={optionsChecked.includes(option.id)} name={checkboxName} key={option.id} />
                );

                })
            }

        </div>
    )
}

// Prop Types
Checkboxes.propTypes = {
    options: PropTypes.array.isRequired,
    checkedOptions: PropTypes.array,
	title: PropTypes.string,
    name: PropTypes.string
}

export default Checkboxes;