// Packages
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";


// Context
import TimerState from 'context/Timer/State';
import { SnackbarProvider } from 'context/Snackbar';


// Routes
import MainRoutes from 'routes/main';


// Other
import Auth from './other/Auth';
import Config from './other/Config';
//import Api from 'other/Api';


// Assets
import './assets/styles.css';
import './assets/forms.styles.css';


const App = () => 
{


  const navigate = useNavigate();



  const onRedirectCallback = (appState) => {
    
      navigate(appState?.returnTo || window.location.pathname);
  }; 
  
  return (
    
    <Auth0Provider
            domain={Config.AUTH0_DOMAIN}
            clientId={Config.AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            scope={Config.AUTH0_SCOPES}
            audience="https://api.crm.behindthedot.com"
            onRedirectCallback={onRedirectCallback}
        >
      <Auth>
        <TimerState>
          <SnackbarProvider>
            <MainRoutes/>
          </SnackbarProvider>
        </TimerState>
      </Auth>
    </Auth0Provider>
  );

}

export default App;