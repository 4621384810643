// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import RecurringJobsList from 'components/Lists/RecurringJobs';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const RecurringJobListScreen = () => {

	// State
	const [recurringJobs, setRecurringJobs] = useState([]);
	const [LoadingJobs, setLoadingJobs] = useState(true);

	
    useEffect(() => {

        Api.recurringjobs.list({
            params: {
                with: ['account', 'stage', 'user', 'contract', 'person', 'interval'],
            },
            success: (response) => {
                setRecurringJobs(response.data);
            },
            after: () => {
                setLoadingJobs(false);
            }
        });

    }, []);



	return (
		<>
			<ScreenTitle title="Recurring Jobs">
				<Button url="/recurringjobs/create" label="Create Recurring Job" colour="blue"/>
			</ScreenTitle>
			

			{ LoadingJobs &&
				<Loading message="Loading recurring jobs"/>
			}

			{ ! LoadingJobs &&

				<>
				
				<RecurringJobsList items={recurringJobs}/>

				</>
			}
		

			
		</>
	);
}

export default RecurringJobListScreen;