// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/Reports/List';

// Layouts
import DefaultLayout from 'layouts/Default';


const ReportRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<ListScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default ReportRoutes