// Packages
import { useState, useEffect } from 'react';

// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import PipelineList from 'components/Lists/Pipeline';
import StageList from 'components/Lists/Stage';
import Button from 'components/UI/Button';
import Message from 'components/UI/Message';
import Tabs from 'components/UI/Tabs';


// Others
import Api from 'other/Api';


const PipelineListScreen = () => {

	// State
	const [pipelines, setPipelines] = useState([]);
	const [pipelinesLoading, setPipelinesLoading] = useState(true);

	const [stages, setStages] = useState([]);
	const [stagesLoading, setStagesLoading] = useState(true);

	const [errorMessage, setErrorMessage] = useState('');

	// Load pipelines
	useEffect(() => {

		Api.pipelines.list({
			success: (response) => {
				setPipelines(response.data);
			},
			error: (error) => {
				setErrorMessage(error.message);
			},
			after: () => {
				setPipelinesLoading(false);
			}
		});

	}, []);

	useEffect(() => {

		// Load stages
		Api.stages.list({
			params: {
				with: ['pipeline']
			},
			success: (response) => {
				setStages(response.data);
			},
			error: (error) => {
				setErrorMessage(error.message);
			},
			after: () => {
				setStagesLoading(false);
			}
		});

	}, []);



	return (
		<>

			{errorMessage &&
				<Message colour="red" text={errorMessage}/>
			}

			<ScreenTitle title="Pipeline Stages">
				<Button url="/pipelines/create" label="Create Pipeline" colour="blue"/>
				<Button url="/stages/create" label="Create Stage" colour="blue"/>
			</ScreenTitle>

			<Tabs.Wrapper defaultTab="pipelines" tabs={[
				{name: 'pipelines', label: 'Pipelines'},
				{name: 'stages', label: 'Stages'},
			]}>

				<Tabs.Panel name="pipelines">

					{ pipelinesLoading &&
						<Loading message="Loading pipelines"/>
					}

					{ ! pipelinesLoading &&
						<PipelineList items={pipelines}/>
					}
				
				</Tabs.Panel>

				<Tabs.Panel name="stages">

					{ stagesLoading &&
						<Loading message="Loading stages"/>
					}

					{ ! stagesLoading &&
						<StageList items={stages}/>
					}
				
				</Tabs.Panel>

			</Tabs.Wrapper>


		</>
	);
}

export default PipelineListScreen;