// Components
import NavigationBar from 'components/NavigationBar';
import SideBar from 'components/Sidebar';
import Timer from 'components/Timer';

// Assets 
import './styles.css';



const DefaultLayout = props => {

	function showMenu() {
		if(document.getElementById('DefaultLayout__header') !== null) {
			console.log('element: ', document.getElementById('DefaultLayout__header'));
			document.getElementById('DefaultLayout__header').classList.add('active');
			document.getElementById('menu__overlay').classList.add('active');
		}
	}

	function removeMenu() {
		if(document.getElementById('DefaultLayout__header') !== null) {
			document.getElementById('DefaultLayout__header').classList.remove('active');
			document.getElementById('menu__overlay').classList.remove('active');
		}
	}

	return (
		<div className="DefaultLayout">
			<div className="DefaultLayout__header-mobile">
				<div onClick={showMenu} className="DefaultLayout__header-mobile__logo"></div>
			</div>
			<div id="DefaultLayout__header" className="DefaultLayout__header">
				<NavigationBar/>
			</div>
			<div className="DefaultLayout__body">
                <Timer/>
                <div className="DefaultLayout__body__main">
				{props.children}
                </div>
			</div>
			<div className="DefaultLayout__footer">
				<SideBar/>
			</div>
			<div onClick={removeMenu} id='menu__overlay'></div>
		</div>
	);
}


export default DefaultLayout;