// Packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';



const SelectList = props => {

	// Props
	const { options, onChange, value, blank } = props;


	// State
	const [ selectOptions, setSelectOptions ] = useState([]);
	const [ selectedOption, setSelectedOption ] = useState(value);


	useEffect(() => {

		setSelectOptions(options);

		setSelectedOption(value);

	}, [options, value]);


	if ( ! selectOptions )
	{
		return (<>Loading...</>);
	}

	return (
		<select value={selectedOption ?? ""} onChange={(e) => {
				setSelectedOption(e.target.value);
				onChange(e);
			}} className="form__field__input">

			{ true === blank &&
				<option value="0"></option>
			}

			{selectOptions.map((option) => {
				return (
					<option key={option.id} value={option.id}>{option.name}</option>
				);
			})} 
		</select>
	);
	
}


// Prop Types
SelectList.propTypes = {
	onChange: PropTypes.func.isRequired,
	blank: PropTypes.bool
}


// Default Props
SelectList.defaultProps = {
	value: 0
}

export default SelectList;