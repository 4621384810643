// Packages
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import DocumentationMenu from 'components/DocumentationMenu';
import LinksList from 'components/Lists/Links';
import ImageUpload from 'components/ImageUpload';
import FileUpload from 'components/FileUpload';
import FilesList from 'components/Lists/Files';


// Others
import Api from 'other/Api';
import './styles.css';


const DocumentationViewScreen = () => {

	// URL Parameters
	const { documentationId } = useParams();

	const search = useLocation().search;
	const pageId = new URLSearchParams(search).get('pageId');

	// State
	const [documentation, setDocumentation] = useState([]);
	const [loading, setLoading] = useState(true);
	const [activePage, setActivePage] = useState(null)
	const [menuPages, setMenuPages] = useState([])
	const [links, setLinks] = useState([]);
    const [linksLoading, setLinksLoading] = useState(true);
	const [files, setFiles] = useState([]);
    const [filesLoading, setFilesLoading] = useState(false);


	const loadLinks = () => {

        Api.links.list({
            params: {
                documentation_id: documentationId
            },
            before: () => {
                setLinksLoading(true);
            },
            success: (response) => {
                setLinks(response.data);
            },
            after: () => {
                setLinksLoading(false);
            }
        }); 
    }

	useEffect(() => {

		// Retrieve documenataion
		Api.documentations.get(documentationId, {
			params: {
				with: ['pages']
			},
			success: (response) => {
				setDocumentation(response.data);
				// setInitialActivePage();
			},
			after: () => {
				setLoading(false);
			}
		});

		loadLinks();

		// eslint-disable-next-line
	}, [documentationId]);


	// Helper functions for building nested menu pages
	function getChildPages(page) {

		if( page.hasChild === 'y' ) {

			var pageArray = [];
			for( let i = 0; i < documentation.pages.length; i++ ) {
				
				if( documentation.pages[i].parent_page_id === page.id ) {

					pageArray.push(documentation.pages[i]);
				}
			}

			pageArray.sort((a, b) => (a.name > b.name) ? 1 : -1);
		}
		return pageArray ?? null;
	}

	function buildNestedPagesArray(tierPages) {
		
		var nestedArray = [];
		for (let i = 0; i < tierPages.length; i++) {

			var childPages = getChildPages(tierPages[i]);

			if ( childPages !== null ) {

			nestedArray.push({...tierPages[i], pages: buildNestedPagesArray(childPages)});
			
			} else {
				nestedArray.push(tierPages[i]);
			}
		}
		
		return nestedArray ?? null;
	}

	
	useEffect(() => {

		// Build menu pages
		if( documentation && documentation.pages ) {

			let topLevelPages = [];
			for( let i = 0; i < documentation.pages.length; i++ ) {

				if( documentation.pages[i].parent_page_id === null ) {

					topLevelPages.push(documentation.pages[i]);
				}
			}

			let menuArray = buildNestedPagesArray(topLevelPages);

			menuArray.sort((a, b) => (a.name > b.name) ? 1 : -1);

			setMenuPages(menuArray);

		}
		// eslint-disable-next-line
	}, [documentation, documentation.pages]);




	useEffect(() => {
		// Load active page
		if( pageId && documentation.pages ) {

			for( let i = 0; i < documentation.pages.length; i++ ) {

				if( parseInt(pageId) === parseInt(documentation.pages[i].id) ) {

					setActivePage(documentation.pages[i]);
					break;

				}
			}
		}

		else if( documentation.pages ) {

			for( let i = 0; i < documentation.pages.length; i++ ) {
					

				if(documentation.pages[i].is_homepage === 'y') {

					setActivePage(documentation.pages[i]);
					break;
				}
			}

		} 

		loadFiles();

		// eslint-disable-next-line
	}, [pageId, documentation.pages, menuPages]);



	const loadFiles = () => {

		if(activePage) {

			Api.files.list({
				params: {
					documentation_page_id: activePage.id
				},
				before: () => {
					setFilesLoading(true);
				},
				success: (response) => {
					setFiles(response.data);
				},
				after: () => {
					setFilesLoading(false);
				}
			}); 
		}
	}

	
	useEffect(() => {

		loadFiles();

		// eslint-disable-next-line
	}, [activePage])


	if ( loading )
	{
		return <Loading/>;
	}
	
	return ( 
		<>
			<ScreenTitle title={`${documentation.name}`} breadcrumb={[
				{label: 'Documentations', url: '/documentations'}
			]}>
				<Button url={`/documentations/${documentation.id}/edit`} label="Edit Documentation" colour="blue"/>

			</ScreenTitle>

			<p className='documentation__updated'>Last updated {documentation.updated_at ? dayjs(documentation.updated_at).format('HH:mm DD-MM-YYYY') : dayjs(documentation.created_at).format('HH:mm DD-MM-YYYY')}</p>


			{documentation.pages.length > 0 && 

				<div className='documentation'>

						<div className='documentation__page'>

							<h2 className='documentation__page__title'>{activePage ? activePage.name : ''}</h2>

							{activePage && 
							<>
								<div className='documentation__page__body' dangerouslySetInnerHTML={{__html: activePage.body}}></div>

								<label className='documentation__page__label'>Upload Image</label>
								<ImageUpload type="documentation" onFileUpload={(response) => {
									setActivePage(response);
								}} imageArray={activePage.images} documentationPageId={activePage.id}/>

								<FileUpload documentationPageId={activePage.id} onFileUpload={loadFiles}/>

								{ filesLoading &&
									<Loading message="Loading files"/>
								}

								{ ! filesLoading &&
									<FilesList items={files} onChange={loadFiles}/>
								}
							</>
							}

							<div className='documentation__page__buttons'>
								{documentation.pages && activePage &&
									<Button url={`/documentations/${documentation.id}/documentationpages/${activePage ? activePage.id : ''}/edit`} label="Edit Page" colour="blue" style={{'marginBottom':'20px'}}/>
								}
								<Button url={`/documentations/${documentation.id}/documentationpages?pageId=${activePage ? activePage.id : ''}`} label="Create Sub-Page" colour="blue"/>
							</div>

						</div>
					
					
					<DocumentationMenu documentation={menuPages} onChange={(page) => {
						setActivePage(page);
					}} firstPage={activePage ?? null}/>

				</div>

			}

			{documentation.pages.length === 0 &&

				<div className='documentation__page__buttons'>
					<Button url={`/documentations/${documentation.id}/documentationpages`} label="Create Page" colour="blue"/>
				</div>
			}

			<div className='documentation__links'>

				<h3 className='documentation__links__title'>Links</h3>

				<Button url={`/links/create?documentation_id=${documentation.id}`} size="small" label="Create Link"/>

					<br/>
					<br/>

					{ linksLoading &&
						<Loading message="Loading links"/>
					}

					{ ! linksLoading &&
						<LinksList items={links} onChange={loadLinks}/>
					}

			</div>




		</>
	);
}

export default DocumentationViewScreen;