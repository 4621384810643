// Packages
import { useState, useEffect } from 'react';

// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import JobsList from 'components/Lists/Jobs';
import Loading from 'components/UI/Loading';

// Others
import Api from 'other/Api';

const DashboardScreen = () => {

    // State
	const [jobs, setJobs] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {

        Api.jobs.listDashboard({
            params: {
                with: ['account', 'stage', 'user'],
            },
            success: (response) => {
                setJobs(response.data);            },
            after: () => {
                setLoading(false);
            }
        });

    }, []);

	return (
		<>
			<ScreenTitle title="Dashboard"/>

			{ loading &&
				<Loading message="Loading your current jobs"/>
			}

			{ ! loading &&
				<>
					<h3>Your current jobs:</h3>
					<JobsList items={jobs}/>
				</>
			}
		</>
	);
}

export default DashboardScreen;