// Packages
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';


// Components
import Avatar from 'components/Avatar';
import Overlay from 'components/UI/Overlay';


// Assets
import './styles.css';
import helpScoutIcon from './assets/helpscout-logo.svg';
import gmailIcon from './assets/gmail-logo.svg';
import trelloIcon from './assets/trello-logo.svg';
import githubIcon from './assets/github-logo.svg';
import awsIcon from './assets/aws-logo.svg';
import formIcon from './assets/form-logo.svg';



const Sidebar = () => {

    // Import context from Auth0
	const {
    	user,
        logout
  	} = useAuth0();


    // State
    const [ userMenu, setUserMenu ] = useState(false);


	const ITEMS = [
		{ label: 'Gmail', icon: gmailIcon, url: 'https://mail.google.com' },
		{ label: 'HelpScout', icon: helpScoutIcon, url: 'https://secure.helpscout.net' },
		{ label: 'FormCapture', icon: formIcon, url: 'https://formcapture.behindthedot.com' },
		{ label: 'Trello', icon: trelloIcon, url: 'https://trello.com' },
		{ label: 'GitHub', icon: githubIcon, url: 'https://github.com' },
		{ label: 'AWS', icon: awsIcon, url: 'https://console.aws.amazon.com' },
	];

	return (
		<div className="Sidebar">

            { userMenu &&
                <Overlay onClose={() => {
                    setUserMenu(false);
                }}>
                <div className="Sidebar__usermenu">
                    <div className="Sidebar__usermenu__body">
                        <div onClick={() => {

                            logout({ returnTo: window.location.origin })

                        }}>Logout</div>
                    </div>
                    
                </div>
                
                </Overlay>
            }

			<ul className="Sidebar__list">

				<li className="Sidebar__list__item" onClick={() => {
                    setUserMenu(!userMenu);
                }}>
					<Avatar showName={false} name={user.given_name+' '+user.family_name} picture={user['https://id.behindthedot.com/picture_url']}/>
				</li>

			{ITEMS.map((item) => {

				return (
					<li key={item.label} className="Sidebar__list__item">
						<a className="Sidebar__list__item__link" rel="noreferrer" target="_blank" href={item.url}>
							<img className={`Sidebar__list__item__link__img ${item.label}`} src={item.icon} alt={item.label}/>
						</a>
					</li>
				);

			})}

			</ul>

		</div>
	);
}

export default Sidebar;