// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import DashboardScreen from 'screens/Dashboard';
import SearchScreen from 'screens/Search';


// Layouts
import DefaultLayout from 'layouts/Default';


const MiscRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<DashboardScreen/>}/>
                <Route exact path="/search" element={<SearchScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default MiscRoutes