// Packages
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';



// Other
import Api from 'other/Api';


const TimeForm = props => {

	// Props
	const { timeId } = props;


	// State - Form fields
	const [timeStarted, setTimeStarted] = useState('');
	const [timeEnded, setTimeEnded] = useState('');
	const [jobId, setJobId] = useState('');
	const [userId, setUserId] = useState('');
	const [accountId, setAccountId] = useState('');
	const [contractId, setContractId] = useState('');

    
	// state - Select options
	const [ jobOptions, setJobOptions] = useState(null);
	const [ userOptions, setUserOptions] = useState(null);
	const [ accountOptions, setAccountOptions] = useState(null);
	const [ contractOptions, setContractOptions] = useState(null);
	

	// State
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {

		/**	
		 * Build generic request to either create or update
		 * the time
		 */
		const request = {
			data: {
				'started_at': dayjs(timeStarted).format('YYYY-MM-DD HH:mm:ss'),
				'ended_at': dayjs(timeEnded).format('YYYY-MM-DD HH:mm:ss'),
				'job_id': jobId,
				'user_id': userId,
				'account_id': accountId,
				'contract_id': contractId 
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				navigate('/jobs/' + response.data.job_id);
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( timeId )
		{
			// Update existing time
			Api.times.update(timeId, request);
	    }
	    else
	    {
	    	// Add a new time
	    	Api.times.create(request);
	    }
	}


	useEffect(() => {

		if ( timeId )
		{
			/**
			 * Load the time into the form
			 */
	        Api.times.get(timeId, {
	            success: (response) => {
	                setTimeStarted(Date.parse(response.data.started_at));
					setTimeEnded(Date.parse(response.data.ended_at));
					setJobId(response.data.job_id);
					setUserId(response.data.user_id);
					setAccountId(response.data.account_id);
					setContractId(response.data.contract_id);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

	    

    }, [timeId]);


    useEffect(() => {

    	/**
		 * Load the job options
		 */
        Api.jobs.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setJobOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);


	useEffect(() => {

    	/**
		 * Load the user options
		 */
        Api.users.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setUserOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);

	useEffect(() => {

    	/**
		 * Load the account options
		 */
        Api.accounts.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setAccountOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);

	useEffect(() => {

    	/**
		 * Load the contract options
		 */
        Api.contracts.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setContractOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);




	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>

			<div className={`form__field ${errors.user_id?'form__field--error':''}`}>
				<label className="form__field__label">User</label>
				<SelectList options={userOptions} blank={true} onChange={(e) => {
					setUserId(e.target.value);
				}} value={userId}/>
				{ errors.user_id &&
					<div className="form__field__error">
						{errors.user_id.join('<br/>')}
					</div>
				}
			</div>


			<div className={`form__field ${errors.job_id?'form__field--error':''}`}>
				<label className="form__field__label">Job</label>
				<SelectList options={jobOptions} blank={true} onChange={(e) => {
					setJobId(e.target.value);
				}} value={jobId}/>
				{ errors.job_id &&
					<div className="form__field__error">
						{errors.job_id.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.job_id?'form__field--error':''}`}>
				<label className="form__field__label">Account</label>
				<SelectList options={accountOptions} blank={true} onChange={(e) => {
					setAccountId(e.target.value);
				}} value={accountId}/>
				{ errors.job_id &&
					<div className="form__field__error">
						{errors.account_id.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.job_id?'form__field--error':''}`}>
				<label className="form__field__label">Contract</label>
				<SelectList options={contractOptions} blank={true} onChange={(e) => {
					setContractId(e.target.value);
				}} value={contractId}/>
				{ errors.contract_id &&
					<div className="form__field__error">
						{errors.contract_id.join('<br/>')}
					</div>
				}
			</div>


			
			<div className={`form__field ${errors.time_started?'form__field--error':''}`}>
				<label className="form__field__label">Time Started</label>
				<DatePicker 
                    selected={timeStarted !== null ? timeStarted : ''} 
                    onChange={(date) => setTimeStarted(date)} 
					showTimeSelect
					timeFormat="HH:mm"
					timeIntervals={5}
					timeCaption="time"
					dateFormat="yyyy-MM-dd h:mm aa"
                    className="form__field__input"/>
				{ errors.time_started &&
					<div className="form__field__error">
						{errors.time_started.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.time_ended?'form__field--error':''}`}>
				<label className="form__field__label">Time Ended</label>
				<DatePicker 
                    selected={timeEnded !== null ? timeEnded : ''} 
                    onChange={(date) => setTimeEnded(date)} 
					showTimeSelect
					timeFormat="HH:mm"
					timeIntervals={5}
					timeCaption="time"
					dateFormat="yyyy-MM-dd h:mm aa"
                    className="form__field__input"/>
				{ errors.time_ended &&
					<div className="form__field__error">
						{errors.time_ended.join('<br/>')}
					</div>
				}
			</div>



			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Time"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
TimeForm.propTypes = {
	timeId: PropTypes.string
}


export default TimeForm;