// Packages
import { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';


// Context
import TimerContext from 'context/Timer';


// Assets
import './styles.css';


const Timer = props => {

    // Context
    const Timer = useContext(TimerContext);

    const [ seconds, setSeconds] = useState(null);

    useEffect(() => {

        if ( Timer.time )
        {
            setSeconds(Timer.time.seconds);
        }

    }, [Timer.time]);

    if ( ! Timer.show )
    {
        return (<></>);
    }

    return (
        <div className="Timer">
            <div className="Timer__header">
                <div className="Timer__description">
                    You are currently tracking time for:
                </div>
                <div className="Timer__job">
                    { Timer.job &&
                        <Link to={`/jobs/${Timer.job.id}`}>{Timer.job.name}</Link>
                    }
                </div>
            </div>
            <div className="Timer__body">
                <div className="Timer__clock">
                    <div className={`Timer__clock__header ${Timer.time ? 'Timer__clock__header--running' : ''}`}/>
                    <div className="Timer__clock__body">
                        {Timer.time &&
                            <>
                            {Math.floor(seconds/60)}
                            <span className="Timer__clock__unit">m</span>
                            </>
                        }

                    </div>
                </div>
            </div>
            <div className="Timer__footer">
                { Timer.time &&
                    <div onClick={() => {
                        Timer.pause();
                    }} className="Timer__button Timer__button--pause"/>
                }


                { ! Timer.time &&
                    <div onClick={() => {
                        Timer.continue();
                    }} className="Timer__button Timer__button--start"/>
                }

                <div onClick={() => {
                    Timer.stop();
                }} className="Timer__button Timer__button--stop"/>
            </div>

        </div>
    );
}

export default Timer;