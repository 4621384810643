// Packages
import { Link } from 'react-router-dom';


// Components
import Avatar from 'components/Avatar';


// Assets
import './styles.css';



const Item = props => {
    
    const { recurringJob } = props;


    // const startFrom = recurringJob.start_from !== null ? dayjs(recurringJob.start_from).format('DD MMM YYYY') : '';



    return (
        
        <div className="RecurringJobItem">

            <div className="RecurringJobItem__body">
                <div>
                    <div className="RecurringJobItem__name">
                        <Link to={`/recurringjobs/${recurringJob.id}`}>{recurringJob.name}</Link>
                    </div>
                        <div className="RecurringJobItem__account">
                            { recurringJob.account &&
                                <> / {recurringJob.account.name}</>
                            }
                            { recurringJob.contract &&
                                <> / {recurringJob.contract.name}</>
                            }
                        </div>
                </div>

                <div>
                    { recurringJob.user &&
                        <Avatar
                            name={recurringJob.user.name}
                            showName={false}
                            size="small"
                        />
                    }
                </div>
            </div>

            <div className="RecurringJobItem__footer">

                <div className="RecurringJobItem__info">
                    <div className="RecurringJobItem__info__name">Running every </div>
                        <div className="RecurringJobItem__info">
                            <div className="RecurringJobItem__info__value">{recurringJob.period} {recurringJob.interval.name}{recurringJob.period > 1 ? 's' : ''}</div>
                        </div>
                </div>
                

                <div className="RecurringJobItem__info">
                    { recurringJob.person &&
                        <div className="RecurringJobItem__info">
                            <div className="RecurringJobItem__info__name">With</div>
                            <div className="RecurringJobItem__info__value">{recurringJob.person.firstname} {recurringJob.person.lastname}</div>
                        </div>
                    }
                </div>
            
                <div className="RecurringJobItem__info">
                    <div className="RecurringJobItem__info">
                        <div className="RecurringJobItem__info__value">
                            { recurringJob.is_active &&
                                <span style={{color: 'rgb(3, 174, 14)', fontWeight: 'bold'}}>Active</span>
                            }

                            { ! recurringJob.is_active &&
                                <span style={{color: '#AE0303', fontWeight: 'bold'}}>Paused</span>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Item;