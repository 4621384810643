// Packages
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const ConversationsListScreen = () => {

    // State
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Api.helpscout.conversations.list({
            success: (response) => {
                setConversations(response);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, []);


    if ( loading )
    {
        return <Loading/>;
    }

    return (
        <>
            <ScreenTitle title="HelpScout Inbox"/>

            <table className="table">
            <thead>
            <tr>
                <th>#</th>
                <th>Date</th>
                <th>Subject</th>
                <th>Contact</th>
                <th></th>
            </tr>
            </thead>
            <tbody>

            {conversations.map((item) => {

                return (
                    <tr key={item.id}>
                        <td>{item.number}</td>
                        <td>{dayjs(item.created_at.date).format('DD/MM/YYYY')}</td>
                        <td><strong>{item.subject}</strong></td>
                        <td>{item.customer_firstname} {item.customer_lastname} ({item.email})</td>
                        <td>
                            { item.imported &&
                                <Button url={`/jobs/${item.job.id}`} colour="green" label="View Job &rarr;"/>
                            }
                                
                            { ! item.imported &&

                              <Button url={`/helpscout/${item.id}/import`} colour="orange" label="Create as Job"/>
                            }
                        
                        </td>
                    </tr>
                );

            })}
            </tbody>


            </table>
        </>
    );
}

export default ConversationsListScreen;