// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import InfoPanel from 'components/UI/InfoPanel';


// Others
import Api from 'other/Api';


const MeetingNoteViewScreen = () => {

	// URL Parameters
	const { meetingNoteId } = useParams();


	// State
	const [note, setNote] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {

		Api.meetingNotes.get(meetingNoteId, {
			params: {
				with: ['account']
			},
			success: (response) => {
				setNote(response);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, [meetingNoteId]);


	if ( loading )
	{
		return <Loading/>;
	}


	return (
		<>
			<ScreenTitle title={note.name} breadcrumb={[
				{label: 'Meeting Notes', url: '/meetingnotes'}
			]}>
				<Button url={`/meetingnotes/${note.id}/edit`} label="Edit Note" colour="blue"/>
			</ScreenTitle>

			
			<InfoPanel.Wrapper>

				<InfoPanel.Column>
					<InfoPanel.Item label="Account" value={note.account.name} link={`/accounts/${note.account_id}`}/>
					<InfoPanel.Item label="Created" value={dayjs(note.created_at).format('ddd D MMM YYYY [at] HH:mm')}/>
				</InfoPanel.Column>

			</InfoPanel.Wrapper>

			{ note.persons && note.persons.length > 0 &&
			<InfoPanel.Wrapper title={note.account.name}>
				{note.persons.map((person) => {
					return (
					<InfoPanel.Item value={`${person.firstname} ${person.lastname}`} link={`/persons/${person.id}`} key={person.id}/>
					);
				})}
				
			</InfoPanel.Wrapper>
			}

			{ note.users && note.users.length > 0 &&
				<InfoPanel.Wrapper title="Behind The Dot">
					{note.users.map((user) => {
						return (
						<InfoPanel.Item value={user.name} link={`/users/${user.id}`} key={user.id}/>
						);
					})}
					
				</InfoPanel.Wrapper>
			}	

			{ note.body &&
				<InfoPanel.Wrapper title="Description">
					<InfoPanel.Item value={<div dangerouslySetInnerHTML={{ __html: note.body }} />}/>
				</InfoPanel.Wrapper>
			}

		</>
	);
}

export default MeetingNoteViewScreen;