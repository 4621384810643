// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Contract';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { contractId } = useParams();

    const navigate = useNavigate();


    const deleteContract = () => {

        if ( ! window.confirm('Are you sure that you want to delete this contract?') )
        {
            return false;
        }

        Api.contracts.delete(contractId, {
            successRedirect: () => {
                
                navigate('/contracts');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Contract" breadcrumb={[
            {label: 'Contracts', url: '/contracts'}
        ]}/>

        <Form contractId={contractId}/>

        <Button onClick={deleteContract} type="delete" label="Delete Contract"/>
        </>
    )
}

export default EditScreen;