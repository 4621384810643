
// Components
import Navigation from 'components/Navigation';
import Search from 'components/Search';


// Assets
import './styles.css';


const NavigationBar = () => {

	function removeMenu() {
		if(document.getElementById('DefaultLayout__header') !== null) {
			document.getElementById('DefaultLayout__header').classList.remove('active');
			document.getElementById('menu__overlay').classList.remove('active');
		}
	}

	return (
		<div className="NavigationBar">

			<div onClick={removeMenu} className="NavigationBar__logo"></div>

            <Search/>

			<Navigation/>

		</div>
	);
}

export default NavigationBar;