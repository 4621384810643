// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import LinksList from 'components/Lists/Links';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const LinksListScreen = () => {

    // State
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Api.links.list({
            params: {
                with: ['account', 'documentation']
            },
            success: (response) => {
                setLinks(response.data);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, []);


    if ( loading )
    {
        return <Loading/>;
    }

    return (
        <>
            <ScreenTitle title="Links">
                <Button url="/links/create" label="Create Link" colour="blue"/>
            </ScreenTitle>

            <LinksList items={links} showSource={true} />

        </>
    );
}

export default LinksListScreen;