import { useParams } from 'react-router-dom';

// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/DocumentationPage';


const Screen = () => {

    // URL Parameters
	const { documentationId } = useParams();

    return (
        <>
        <ScreenTitle title="Create Documentation Page"/>

        <Form documentationId={documentationId}/>
        </>
    )
}

export default Screen;