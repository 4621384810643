// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/MeetingNotes/List';
import ViewScreen from 'screens/MeetingNotes/View';
import CreateScreen from 'screens/MeetingNotes/Create';
import EditScreen from 'screens/MeetingNotes/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const MeetingNoteRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
				<Route exact path="/:meetingNoteId" element={<ViewScreen/>}/>
				<Route exact path="/:meetingNoteId/edit" element={<EditScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default MeetingNoteRoutes