// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import EmptyState from 'components/Lists/EmptyState';
import SearchResultsList from 'components/Lists/SearchResults';


// Other
import Api from 'other/Api';

const SearchScreen = () => {

    // State
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ results, setResults ] = useState([]);


    useEffect(() => {

        if ( searchTerm.length < 3 ) return;

        Api.search(searchTerm, {
            success: (response) => {
                setResults(response);
            }
        })

    }, [searchTerm])

    return (
        <>

        <ScreenTitle title="Search"/>

        <form onSubmit={(e) => {
            e.preventDefault();
            return false
        }}>
            <input onChange={(e) => {
                setSearchTerm(e.target.value);
            }} value={searchTerm} type="text" className="form__field__input" placeholder="Search for anything"/>
        </form>

        { results.length === 0 && searchTerm.length >= 3 &&
            <EmptyState message="There are no results for that search term"/>
        }

        <br/><br/>

        { results.length > 0 &&
            <SearchResultsList items={results}/>
        }

        </>
    );
}

export default SearchScreen;