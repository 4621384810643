// Packages
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import EmptyState from 'components/Lists/EmptyState';
import Duration from 'components/Duration';


const TimeList = props => {
    
    const { items } = props;

    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There is no time to display here"/>);
    }

    return (
        <table className="table">
        <thead>
        <tr>
            <th>Started</th>
            <th>Ended</th>
            <th>Time Taken</th>
            <th>User</th>
        </tr>
        </thead>
        <tbody>

            {items.map((item) => {

                return (
                    <tr key={item.id}>
                        <td><Link to={`/times/${item.id}/edit`}>{dayjs(item.started_at).format('DD/MM/YYYY HH:mm')}</Link></td>
                        <td>{item.ended_at ? dayjs(item.ended_at).format('DD/MM/YYYY HH:mm') : ''}</td>
                        <td><Duration seconds={item.seconds}/></td>
                        <td>{item.user ? item.user.name : ''}</td>  
                    </tr>
                );

            })}
        </tbody>
        </table>
    );
}

export default TimeList;