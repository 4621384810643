

// Assets
import './styles.css';

const Stage = props => {

	const { name, colour } = props;

	return (
		<div className={`Stage Pipeline__list__item--colour-${colour}`}>
			{name}
		</div>
	)

}


export default Stage;