// Packages
import { Link } from 'react-router-dom';
import dayjs from 'dayjs'


// Components
import EmptyState from 'components/Lists/EmptyState';


const MonitorsList = props => {
    
    const { items } = props;


    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no monitors to display here"/>);
    }

    return (
        <table className="table">
        <thead>
        <tr>
            <th>Name</th>
            <th>Last Check</th>
            <th>Status</th>
            <th>Response Time</th>
        </tr>
        </thead>
        <tbody>

            {items.map((item) => {

                return (

                    <tr key={item.id}>
                        <td><Link to={`/monitors/${item.id}`}><strong>{item.name}</strong></Link></td>
                        <td>{dayjs(item.last_check_at).format('YYYY-MM-DD HH:mm')}</td>
                        <td>{item.status}</td>
                        <td>{item.response_time}</td>
                    </tr>

                );

            })}
        </tbody>
        </table>
    );
}

export default MonitorsList;