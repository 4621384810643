// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/RecurringJob';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { recurringJobId } = useParams();

    const navigate = useNavigate();


    const deleteRecurringJob = () => {

        if ( ! window.confirm('Are you sure that you want to delete this recurring job?') )
        {
            return false;
        }

        Api.recurringjobs.delete(recurringJobId, {
            successRedirect: () => {
                navigate('/recurringjobs');
            }
        })
    }

    


    return (
        <>
        <ScreenTitle title="Edit Recurring Job"/>

        <Form recurringJobId={recurringJobId}/>

        <Button onClick={deleteRecurringJob} type="delete" label="Delete Recurring Job"/>
        </>
    )
}

export default EditScreen;