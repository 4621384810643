// Packages
import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import TimesList from 'components/Lists/Times';
import Button from 'components/UI/Button';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';


// Others
import Api from 'other/Api';
import Duration from 'components/Duration';

// Assets
import './styles.css';


const TimeListScreen = () => {

	// State
	const [times, setTimes] = useState([]);
	const [totalTime, setTotalTime] = useState(null);
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [accountId, setAccountId] = useState('');
	const [contractId, setContractId] = useState('');
	const [userId, setUserId] = useState('');
	const [dateAfter, setDateAfter] = useState('');
	const [dateBefore, setDateBefore] = useState('');

	// state - Select options
	const [ accountOptions, setAccountOptions] = useState(null);
	const [ contractOptions, setContractOptions] = useState(null);
	const [ userOptions, setUserOptions] = useState(null);

	useEffect(() => {

		Api.times.list({
			params: {
				with: ['job', 'account', 'contract', 'user'],
				'account_id': accountId !== '0' ? accountId : '',
				'contract_id': contractId !== null ? contractId : '',
				'user_id': userId !== '0' ? userId : '',
				'started_at': dateAfter !== '' ? dayjs(dateAfter).format('YYYY-MM-DD') : '',
				'ended_at': dateBefore !== '' ? dayjs(dateBefore).format('YYYY-MM-DD') : ''
			},
			data: {
			},
			success: (response) => {
				setTimes(response.data);

			},
			after: () => {
				setLoading(false);
			}
		});

	}, [accountId, contractId, userId, dateAfter, dateBefore]);



	useEffect(() => {

		if( times ) {

			let total = 0;
				for( let i = 0; i < times.length; i++ ) {
					total += times[i].seconds;
				}
				setTotalTime(total);
		}
	}, [times]);


	useEffect(() => {

    	/**
		 * Load the account options
		 */
        Api.accounts.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setAccountOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, []);

	useEffect(() => {

    	/**
		 * Load the contract options
		 */
        Api.contracts.list({
        	params: {
        		per_page: 500,
				'account_id': accountId !== '' ? accountId : ''
        	},
            success: (response) => {
                setContractOptions(response.data);
				setContractId('');
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, [accountId]);

	useEffect(() => {

    	/**
		 * Load the user options
		 */
        Api.users.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setUserOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);



	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>

			{errorMessage &&
				<Message colour="red" text={errorMessage}/>
			}

			<ScreenTitle title="Times">
				<Button url="/times/create" label="Create Time" colour="blue"/>
			</ScreenTitle>

			<h3 className='Times__list__subtitle'>Filters:</h3>

			<div className='Times__list__filters'>

				<div className="Times__list__filters__filter">
					<label className="Times__list__filters__filter__label form__field__label">Account</label>
					<SelectList options={accountOptions} blank={true} onChange={(e) => {
						setAccountId(e.target.value);
					}} value={accountId} />
				</div>
				
				<div className="Times__list__filters__filter">
					<label className="Times__list__filters__filter__label form__field__label">Contract</label>
					<SelectList options={contractOptions} blank={true} onChange={(e) => {
						setContractId(e.target.value);
					}} value={contractId} />
				</div>

				<div className="Times__list__filters__filter">
					<label className="Times__list__filters__filter__label form__field__label">User</label>
					<SelectList options={userOptions} blank={true} onChange={(e) => {
						setUserId(e.target.value);
					}} value={userId} />
				</div>

				<div className="Times__list__filters__filter">
					<label className="form__field__label">Date after</label>
					<DatePicker 
						selected={dateAfter !== null ? dateAfter : ''} 
						onChange={(date) => setDateAfter(date)} 
						dateFormat="yyyy-MM-dd"
						className="Times__list__filters__filter__date form__field__input"/>
				</div>

				<div className="Times__list__filters__filter">
					<label className="form__field__label">Date before</label>
					<DatePicker 
						selected={dateBefore !== null ? dateBefore : ''} 
						onChange={(date) => setDateBefore(date)} 
						dateFormat="yyyy-MM-dd"
						className="Times__list__filters__filter__date form__field__input"/>
				</div>

			</div>

			<TimesList items={times}/>

			{totalTime > 0 &&
			
				<p className='Times__list__total'>Total time: <Duration seconds={totalTime} /></p>

			}

		</>
	);
}

export default TimeListScreen;