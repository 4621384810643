// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import TemplatesList from 'components/Lists/Templates';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const TemplateListScreen = () => {

	// State
	const [templates, setTemplates] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		Api.templates.list({
			params: {
			},
			success: (response) => {
				setTemplates(response.data);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title="Templates">
				<Button url="/templates/create" label="Create Template" colour="blue"/>
			</ScreenTitle>

			<TemplatesList items={templates}/>

		</>
	);
}

export default TemplateListScreen;