// Packages
import React from 'react';

// Assets
import './styles.css';


const Component = props => {
	
	return (
		<div className="Saving">

		</div>
	);
}

export default Component;