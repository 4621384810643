// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/MeetingNote';


const Screen = () => {

    return (
        <>
        <ScreenTitle title="Create Meeting Note"/>

        <Form/>
        </>
    )
}

export default Screen;