// Packages
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import EmptyState from 'components/Lists/EmptyState';


const MeetingNotesList = props => {
	
	const { items } = props;

    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no meeting notes to display here"/>);
    }

	return (
		<table className="table">
		<thead>
		<tr>
			<th>Date</th>
			<th>Name</th>
			{ items[0] && items[0].account &&
				<th>Account</th>
			}
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (
					<tr key={item.id}>
						<td>{dayjs(item.created_at).format('ddd D MMM YYYY [at] HH:mm')}</td>
						<td><Link to={`/meetingnotes/${item.id}`}><strong>{item.name}</strong></Link></td>
						{ item.account &&
							<td>{item.account.name}</td>
						}
					</tr>
				);

			})}
		</tbody>
		</table>
	);
}

export default MeetingNotesList;