// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ComposedChart, Bar, Line, ReferenceLine, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import InfoPanel from 'components/UI/InfoPanel';
import Tabs from 'components/UI/Tabs';
import JobsList from 'components/Lists/Jobs';
import FilesList from 'components/Lists/Files';
import NotesList from 'components/Lists/Notes';
import LinksList from 'components/Lists/Links';
import FileUpload from 'components/FileUpload';
import NoteForm from 'components/Forms/Note';
import Duration from 'components/Duration';
import { Currency } from 'other/Utilities';


// Others
import Api from 'other/Api';
import './styles.css';


const ContractViewScreen = () => {

	// URL Parameters
	const { contractId } = useParams();

	// State
	const [contract, setContract] = useState(null);
	const [loading, setLoading] = useState(true);

	const [jobs, setJobs] = useState([]);
	const [jobsLoading, setJobsLoading] = useState(true);

    const [files, setFiles] = useState([]);
    const [filesLoading, setFilesLoading] = useState(true);

    const [notes, setNotes] = useState([]);
    const [notesLoading, setNotesLoading] = useState(true);

    const [links, setLinks] = useState([]);
    const [linksLoading, setLinksLoading] = useState(true);

    const[usageData, setUsageData] = useState(null);
    const [usageLoading, setLUsageLoading] = useState(true);
    const[startDate, setStartDate] = useState(null);
    const[endDate, setEndDate] = useState(null);
    const[annualUsageData, setAnnualUsageData] = useState(null);
    const [annualUsageLoading, setAnnualUsageLoading] = useState(true);
    const [annualReportYear, setAnnualReportYear] = useState(2023);


	useEffect(() => {

		Api.contracts.get(contractId, {
			params: {
				with: ['account']
			},
			success: (response) => {
				setContract(response);
			},
			after: () => {
				setLoading(false);
			}
		});

		Api.jobs.list({
			params: {
				contract_id: contractId,
				with: ['account', 'stage', 'user', 'contract', 'person'],
			},
			success: (response) => {
				setJobs(response.data);
			},
			after: () => {
				setJobsLoading(false);
			}
		});


        loadFiles();
        loadNotes();
        loadLinks();
        // eslint-disable-next-line
	}, [contractId]);


    // Load usage data + filter handling
    useEffect(() => {

        Api.contracts.usage(contractId, {
            params: {
                'start_date': startDate,
                'end_date': endDate
            },
            success: (response) => {
                setUsageData(response);
            },
            after: () => {
                setLUsageLoading(false);
            }
        })
    }, [contractId, startDate, endDate]);


    // Load annual usage data + filter handling
    useEffect(() => {

        Api.contracts.annualUsage(contractId, {
            params: {
                'year': annualReportYear,
            },
            success: (response) => {
                setAnnualUsageData(response);
            },
            after: () => {
                setAnnualUsageLoading(false);
            }
        })
    }, [contractId, annualReportYear]);

    const downloadCSV = () => {

        Api.contracts.annualUsage(contractId, {
            params: {
                year: annualReportYear,
                type: 'csv',
            },
            responseType: 'blob',
            success: (response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', contract.name + '_' + annualReportYear + '.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            },
            error: (error) => {
                window.alert(error.message);
            }
        })

    }

    const loadNotes = () => {

        Api.notes.list({
            params: {
                contract_id: contractId
            },
            before: () => {
                setNotesLoading(true);
            },
            success: (response) => {
                setNotes(response.data);
            },
            after: () => {
                setNotesLoading(false);
            }
        }); 
    }

    const loadLinks = () => {

        Api.links.list({
            params: {
                contract_id: contractId
            },
            before: () => {
                setLinksLoading(true);
            },
            success: (response) => {
                setLinks(response.data);
            },
            after: () => {
                setLinksLoading(false);
            }
        }); 
    }


    const loadFiles = () => {

        Api.files.list({
            params: {
                contract_id: contractId
            },
            before: () => {
                setFilesLoading(true);
            },
            success: (response) => {
                setFiles(response.data);
            },
            after: () => {
                setFilesLoading(false);
            }
        }); 
    }


	if ( loading )
	{
		return <Loading/>;
	}


	return (
		<>
			<ScreenTitle title={contract.name} breadcrumb={[
				{label: 'Contracts', url: '/contracts'}
			]}>
				<Button url={`/contracts/${contract.id}/edit`} label="Edit Contract" colour="blue"/>
                <Button url={`/contracts/${contract.id}/statement`} label="Generate Statement" colour="orange"/>
			</ScreenTitle>


			<InfoPanel.Wrapper>

				<InfoPanel.Column>

					<InfoPanel.Item label="Account" value={contract.account.name} link={`/accounts/${contract.account_id}`}/>

                    {contract.rollover_seconds > 0 && 

                        <InfoPanel.Item label="Rollover Hours">
                            <Duration seconds={contract.rollover_seconds}/>
                        </InfoPanel.Item>
                    }

				</InfoPanel.Column>

				<InfoPanel.Column>
					<InfoPanel.Item label="Price" value={`${Currency(contract.price)} per ${contract.interval}`}/>
					<InfoPanel.Item label="Time">
                        <Duration seconds={contract.seconds}/> per {contract.interval}
                    </InfoPanel.Item>
				</InfoPanel.Column>

			</InfoPanel.Wrapper>


			{ contract.summary &&
				<InfoPanel.Wrapper title="Contract Summary">
					<InfoPanel.Item value={contract.summary}/>
				</InfoPanel.Wrapper>
			}


			<Tabs.Wrapper defaultTab="usage" tabs={[
				{name: 'usage', label: 'Usage', count: null},
				{name: 'jobs', label: 'Jobs', count: jobs.length},
                {name: 'notes', label: 'Notes', count: notes.length},
                {name: 'files', label: 'Files', count: files.length},
                {name: 'links', label: 'Links', count: links.length},
                {name: 'annual', label: 'Annual Report'},

			]}>

				<Tabs.Panel name="usage">

                    { usageLoading &&
						<Loading message="Loading usage"/>
					}

                    { ! usageLoading && 

                        <div className='usage'>

                            <div className='usage__filters'>

                                <div className="usage__filters__filter">
                                    <label className="form__field__label">Date after</label>
                                    <DatePicker 
                                        selected={startDate !== null ? startDate : ''} 
                                        onChange={(date) => setStartDate(date)} 
                                        dateFormat="yyyy-MM-dd"
                                        className="usage__filters__filter__date form__field__input"/>
                                </div>

                                <div className="usage__filters__filter">
                                    <label className="form__field__label">Date before</label>
                                    <DatePicker 
                                        selected={endDate !== null ? endDate : ''} 
                                        onChange={(date) => setEndDate(date)} 
                                        dateFormat="yyyy-MM-dd"
                                        className="usage__filters__filter__date form__field__input"/>
                                </div>

                            </div>

                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                width={500}
                                height={800}
                                data={usageData.usage}
                                margin={{
                                    top: 20,
                                    right: 0,
                                    left: 10,
                                    bottom: 5,
                                }}
                                >
                                <XAxis dataKey="date" label={{ value: 'Month', position: 'insideBottom', offset: -12 }}/>
                                <YAxis label={{ value: 'Hours', angle: -90, position: 'insideLeft' }}/>
                                <Tooltip />
                                <Legend wrapperStyle={{ top: -80, right: 0, backgroundColor: '#F5F7FA', padding: '20px', border: '1px solid #d5d5d5', borderRadius: 3, lineHeight: '40px', width: 'max-content' }}/>
                                <Bar dataKey="hours used" stackId="a" fill="#1D428A" />
                                <Bar type="monotone" dataKey="rollover hours" stackId="a" fill="#FFDD25BF" opacity="1"/>
                                <Line type="monotone" dataKey="contracted hours" stroke="red" stroke-width="3" />
                                <ReferenceLine y={usageData.average} label={{ value: 'Average Usage', position: 'top' }} stroke="orange" strokeDasharray="3 3" textAnchor="bottom" />
                                </ComposedChart>
                            </ResponsiveContainer>

                        </div>
                    }
				
				</Tabs.Panel>


				<Tabs.Panel name="jobs">

					{ jobsLoading &&
						<Loading message="Loading jobs"/>
					}

					{ ! jobsLoading &&
						<JobsList items={jobs}/>
					}
				
				</Tabs.Panel>



                <Tabs.Panel name="notes">

                    <NoteForm 
                        onNoteCreate={loadNotes} 
                        contractId={contract.id}
                        accountId={contract.account_id}
                    />

                    <br/><br/>

                    { notesLoading &&
                        <Loading message="Loading notes"/>
                    }

                    { ! notesLoading &&
                        <NotesList items={notes} onChange={loadNotes}/>
                    }           

                </Tabs.Panel>




                <Tabs.Panel name="files">


                    <FileUpload accountId={contract.account_id} contractId={contract.id} onFileUpload={() => {
                        loadFiles();
                    }}/>


                    { filesLoading &&
                        <Loading message="Loading files"/>
                    }

                    { ! filesLoading &&
                        <FilesList items={files} onChange={loadFiles}/>
                    }
                
                </Tabs.Panel>


                <Tabs.Panel name="links">

                    <Button url={`/links/create?account_id=${contract.account_id}&contract_id=${contract.id}`} size="small" label="Create Link"/>

                    <br/>
                    <br/>

                    { linksLoading &&
                        <Loading message="Loading links"/>
                    }

                    { ! linksLoading &&
                        <LinksList items={links} onChange={loadLinks}/>
                    }

                </Tabs.Panel>

                <Tabs.Panel name="annual">

                    <Button onClick={() => downloadCSV()} label="Generate CSV" colour="orange" style={{marginBottom: '20px', float: 'right'}}/>

                    { annualUsageLoading ??
                    
                        <Loading/>
                    }

                    { annualUsageData &&

                        <>
                        <label>Select Year: </label>
                        <select className='annualUsage__filter' value={annualReportYear} onChange={(e) => {setAnnualReportYear(e.target.value)}}>
                            <option value={2023}>2023</option>
                            <option value={2022}>2022</option>
                            <option value={2021}>2021</option>
                            <option value={2020}>2020</option>
                        </select>
                    
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Job</th>
                                    <th>Created</th>
                                    <th>Assigned</th>
                                    <th>Total Time</th>
                                    <th>Resolution Time</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {annualUsageData.map((job) => {
                                    return(
                                        <tr key={job.id}>
                                            <td>{job.name ?? ''}</td>
                                            <td>{dayjs(job.created_at).format('DD/MM/YYYY')}</td>
                                            <td>{job.user && job.user.name ? job.user.name : ''}</td>
                                            <td><Duration seconds={job.total_time ?? ''}/></td>
                                            <td><Duration seconds={job.resolution_time ?? ''}/></td>
                                            <td>{job.status ?? ''}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>

                        </>
                    }
                </Tabs.Panel>



			</Tabs.Wrapper>



		</>
	);
}

export default ContractViewScreen;