// Packages
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import dayjs from 'dayjs';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';


// Other
import Api from 'other/Api';


// Assets
import "react-datepicker/dist/react-datepicker.css";


const RecurringJobForm = props => {

	// Props
	const { recurringJobId } = props;


	// URL parameters
	const [ queryParams ] = useSearchParams();


	// State - Form fields
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [accountId, setAccountId] = useState(queryParams.get('account_id')??'');
	const [contractId, setContractId] = useState(queryParams.get('contract_id')??'');
	const [personId, setPersonId] = useState(queryParams.get('person_id')??'');
    const [userId, setUserId] = useState('');
    const [startFrom, setStartFrom] = useState('');
    const [intervalId, setIntervalId] = useState(1);
    const [period, setPeriod] = useState(1);
	const [billingStatus, setBillingStatus] = useState('');


	// state - Select options
	const [ accountOptions, setAccountOptions] = useState(null);
	const [ contractOptions, setContractOptions] = useState([]);
	const [ personOptions, setPersonOptions] = useState([]);
    const [ userOptions, setUserOptions] = useState([]);
    const [ intervalOptions, setIntervalOptions] = useState([]);
	const billingStatusOptions = [
		{id: 'not applicable', name: 'Not Applicable'},
		{id: 'contracted', name: 'Contracted'},
		{id: 'pending', name: 'Pending'},
		{id: 'billed', name: 'Billed'}
	];
	

	// State
	const [loading, setLoading] = useState(accountId);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {

		/**	
		 * Build generic request to either create or update
		 * the recurring job
		 */


		const request = {
			data: {
				name,
				description,
				account_id: accountId,
				contract_id: contractId,
				person_id: personId,
                start_from: startFrom !== '' ? dayjs(startFrom).format('YYYY-MM-DD') + ' 00:00:00' : null,
                user_id: userId !== '' && userId > 0 ? userId : null,
                interval_id: intervalId,
                period: period,
				billing_status: billingStatus
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				navigate('/recurringjobs/' + response.id);
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( recurringJobId )
		{
			// Update existing recurring job
			Api.recurringjobs.update(recurringJobId, request);
	    }
	    else
	    {
	    	// Add a new recurring job
	    	Api.recurringjobs.create(request);
	    }
	}


	useEffect(() => {

		if ( recurringJobId )
		{
			/**
			 * Load the job into the form
			 */
	        Api.recurringjobs.get(recurringJobId, {
	            success: (response) => {
	                setName(response.name);
					setDescription(response.description);
					setAccountId(response.account_id);
					setContractId(response.contract_id);
					setPersonId(response.person_id);
					setIntervalId(response.interval_id);
					setPeriod(response.period);
                    setUserId(response.user_id);
                    setStartFrom(response.start_from !== null ? new Date(response.start_from) : '');
					setBillingStatus(response.billing_status);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

	    
    }, [recurringJobId]);




    useEffect(() => {

    	/**
		 * Load the account options
		 */
        Api.accounts.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setAccountOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


        /**
         * Load the user options
         */
        Api.users.list({
            params: {
                per_page: 500
            },
            success: (response) => {
                setUserOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });

		 /**
		 * Load the interval options
		 */
		  Api.intervals.list({
        	params: {
        		per_page: 500,
        	},
            success: (response) => {
                setIntervalOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);


    useEffect(() => {

    	if ( accountId === '' )
    	{
    		setContractOptions([]);
    		setPersonOptions([]);
    		return;
    	}

    	/**
		 * Load the contract options
		 */
        Api.contracts.list({
        	params: {
        		per_page: 500,
        		account_id: accountId
        	},
            success: (response) => {
                setContractOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


         /**
		 * Load the person options
		 */
        Api.persons.list({
        	params: {
        		per_page: 500,
        		account_id: accountId
        	},
            success: (response) => {
                setPersonOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, [accountId])

        


	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>


			<div className={`form__field ${errors.account_id?'form__field--error':''}`}>
				<label className="form__field__label">Account</label>
				<SelectList options={accountOptions} blank={true} onChange={(e) => {
					setAccountId(e.target.value);
				}} value={accountId}/>
				{ errors.account_id &&
					<div className="form__field__error">
						{errors.account_id.join('<br/>')}
					</div>
				}
			</div>


			{ personOptions.length > 0 &&
				<div className={`form__field ${errors.person_id?'form__field--error':''}`}>
					<label className="form__field__label">Person</label>
					<SelectList options={personOptions} blank={true} onChange={(e) => {
						setPersonId(e.target.value);
					}} value={personId}/>
					{ errors.person_id &&
						<div className="form__field__error">
							{errors.person_id.join('<br/>')}
						</div>
					}
				</div>
			}


			{ contractOptions.length > 0 &&
				<div className={`form__field ${errors.contract_id?'form__field--error':''}`}>
					<label className="form__field__label">Contract</label>
					<SelectList options={contractOptions} blank={true} onChange={(e) => {
						setContractId(e.target.value);
					}} value={contractId}/>
					{ errors.contract_id &&
						<div className="form__field__error">
							{errors.contract_id.join('<br/>')}
						</div>
					}
				</div>
			}


			
			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Recurring Job name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>



			<div className={`form__field ${errors.description?'form__field--error':''}`}>
				<label className="form__field__label">Description</label>
				<textarea onChange={(e) => {
					setDescription(e.target.value)
				}} name="description" rows="10" value={description ?? ''} className="form__field__input"/>
				{ errors.description &&
					<div className="form__field__error">
						{errors.description.join('<br/>')}
					</div>
				}
			</div>

			{ billingStatusOptions.length > 0 &&
				<div className={`form__field ${errors.billing_status?'form__field--error':''}`}>
					<label className="form__field__label">Billing Status</label>
					<SelectList options={billingStatusOptions} blank={true} onChange={(e) => {
						setBillingStatus(e.target.value);
					}} value={billingStatus}/>
					{ errors.billing_status &&
						<div className="form__field__error">
							{errors.billing_status.join('<br/>')}
						</div>
					}
				</div>
			}


			<div className="form__columnContainer">

				<div className={`form__field ${errors.period?'form__field--error':''}`}>
					<label className="form__field__label">Period</label>
					<input onChange={(e) => {
						setPeriod(e.target.value)
					}} name="period" type="number" value={period} className="form__field__input"/>
					{ errors.period &&
						<div className="form__field__error">
							{errors.period.join('<br/>')}
						</div>
					}
				</div>
			
				<div className={`form__field ${errors.interval?'form__field--error':''}`}>
					<label className="form__field__label">Interval</label>
					<SelectList options={intervalOptions} blank={false} onChange={(e) => {
						setIntervalId(e.target.value);
					}} value={intervalId}/>
					{ errors.interval &&
						<div className="form__field__error">
							{errors.interval.join('<br/>')}
						</div>
					}
				</div>

			</div>


            <div className={`form__field ${errors.start_from?'form__field--error':''}`}>
                <label className="form__field__label">Start From</label>
                
                <DatePicker 
                    selected={startFrom !== '' ? startFrom : null} 
                    onChange={(date) => setStartFrom(date)} 
                    dateFormat="yyyy-MM-dd" 
                    className="form__field__input"/>
               
                { errors.start_from &&
                    <div className="form__field__error">
                        {errors.start_from.join('<br/>')}
                    </div>
                }
            </div>



            <div className={`form__field ${errors.user_id?'form__field--error':''}`}>
                <label className="form__field__label">User Assignment</label>
                <SelectList options={userOptions} blank={true} onChange={(e) => {
                    setUserId(e.target.value);
                }} value={userId}/>
                { errors.user_id &&
                    <div className="form__field__error">
                        {errors.user_id.join('<br/>')}
                    </div>
                }
            </div>





			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Recurring Job" onClick={submit}/>
				}
			</div>

		</form>
		</>
	);
}



export default RecurringJobForm;