// Packages
import Filesize from 'filesize';


// Components
import EmptyState from 'components/Lists/EmptyState';


// Other
import Api from 'other/Api';



const FilesList = props => {
	
    // Props
	const { items, onChange } = props;


    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no files to display here"/>);
    }


    const downloadFile = (id) => {
        Api.files.get(id, {
            success: (file) => {
                Api.files.download(id, {
                    success: (binary) => {
                        const url = window.URL.createObjectURL(new Blob([binary]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', file.name); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                });  
            }
        });
    }

    const deleteFile = (id) => {

        if ( ! window.confirm('Are you sure that you want to delete this file?') )
        {
            return;
        }

        Api.files.delete(id, {
            success: (response) => {
                onChange();
            }
        })
    }

	return (
		<table className="table">
		<thead>
		<tr>
			<th>Name</th>
			<th>Type</th>
			<th>Size</th>
            <th></th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (
					<tr key={item.id}>
						<td><a href="/download-file" onClick={(e) => {
                            e.preventDefault();
                            downloadFile(item.id);
                        }}><strong>{item.name}</strong></a></td>
						<td>{item.extension}</td>
						<td>{Filesize(item.size)}</td>
                        <td><div onClick={() => {
                            deleteFile(item.id);
                        }} className="table-delete"/></td>
					</tr>
				);

			})}
		</tbody>
		</table>
	);
}

export default FilesList;