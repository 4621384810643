// Packages
import { useState, useEffect } from 'react';


// Assets
import './styles.css';


const Wrapper = props => {

	// Props
	const { tabs, defaultTab, children } = props;

	// We need to ensure children is an array
	let theChildren = Array.isArray(children) ? children : [children];


	// State
	const [selectedTab, setSelectedTab] = useState('');


	useEffect(() => {

        setSelectedTab(defaultTab);

	}, [defaultTab]);


	
	return (
		<div className="Tabs">
			<ul className="Tabs__list">
				{tabs.map((item) => {
					return (
						<li onClick={() => {

							setSelectedTab(item.name);

						}} key={item.name} className={`Tabs__list__item ${selectedTab===item.name ? 'Tabs__list__item--selected' : ''}`}>{item.label}

                        { item.count !== null && item.count > 0 &&
                            <span className="Tabs__list__item__count">({item.count})</span>
                        }

                        </li>
					);
				})}
			</ul>

			{theChildren.map((child, index) => {

				if ( child.props && child.props.name === selectedTab )
				{
					return (
                        <div key={child.props.name}>
                        {child}
                        </div>);
				}
				else
				{
					return (<div key={index}></div>);
				}

			})}

		</div>
	);
	

}


const Panel = props => {

	return (
		<div className="Tabs__panel">

			{props.children}

		</div>
	);

}


const output = {
	Wrapper,
	Panel
};

export default output;