// Packages
import { useState, useEffect } from 'react';

// Components
import Loading from 'components/UI/Loading';

// Assets
import './styles.css';


const DocmumentatioMenu = props => {

    // Props
	const { documentation, onChange, firstPage } = props;

	

    // State
    const [ selected, setSelected ] = useState(firstPage);
    const [ documentationPages, setDocumentationPages] = useState([]);


    useEffect(() => {

        onChange(selected);

    }, [selected, onChange]);


    useEffect(() => {

        setDocumentationPages(documentation);

    }, [documentation]);

    // helper function to get pages for select options
	// var flattenedArray = [];
	// function flattenPages(pageArray) {

	// 	for( let i = 0; i < pageArray.length; i++ ) {

	// 		flattenedArray.push(pageArray[i]);

	// 		if( pageArray[i].hasChild === 'y' ) {

	// 			flattenPages(pageArray[i].pages);
	// 		}
	// 	}

	// 	return flattenedArray;
	// }

    if ( ! documentationPages )
	{
		return <Loading/>
	}



	return (
		<div id="DocumentationMenu" className="DocumentationMenu">

            <h3 onClick={() => {
                document.getElementById('DocumentationMenu').classList.toggle('active');
            }}className='DocumentationMenu__title'>Contents</h3>

			<ul className="DocumentationMenu__list">

				{documentationPages.map((topLevelPage) => {

                    // let childPages = topLevelPage.hasChild === 'y' ? flattenPages(topLevelPage.pages) : null;

					return (
                        <li key={topLevelPage.id}  className="DocumentationMenu__list__item DocumentationMenu__list__item-topLevel" >

                            <span onClick={() => {
                                    setSelected(topLevelPage)
                                }}> {topLevelPage.name} {topLevelPage.is_homepage === 'y' ? ' *' : ''}
                            </span>

                            {topLevelPage.pages &&

                                topLevelPage.pages.map((childPage) => {

                                    
                                    return (
                                        <ul key={childPage.id}  className="DocumentationMenu__list">

                                            <li className="DocumentationMenu__list__item DocumentationMenu__list__item-child" onClick={() => {
                                            setSelected(childPage)
                                            }}>
                                            - {childPage.name} {childPage.is_homepage === 'y' ? ' *' : ''}
                                            </li>

                                                {childPage.hasChild === 'y' &&
            
                                                    childPage.pages.map((grandchildPage) => {
                                                        
                                                        return (
                                                            <ul key={grandchildPage.id}  className="DocumentationMenu__list">

                                                                <li className="DocumentationMenu__list__item DocumentationMenu__list__item-grandchild" onClick={() => {
                                                                    setSelected(grandchildPage)
                                                                    }}> - {grandchildPage.name} {grandchildPage.is_homepage === 'y' ? ' *' : ''}
                                                                </li>

                                                                {grandchildPage.hasChild === 'y' &&
                                                                            
                                                                    grandchildPage.pages.map((greatGrandchildPage) => {
                                                                        
                                                                        return (
                                                                            <ul key={greatGrandchildPage.id} className="DocumentationMenu__list">

                                                                                <li className="DocumentationMenu__list__item DocumentationMenu__list__item-greatGrandchildPage" onClick={() => {
                                                                                    setSelected(greatGrandchildPage)
                                                                                    }}> - {greatGrandchildPage.name} {greatGrandchildPage.is_homepage === 'y' ? ' *' : ''}
                                                                                </li>

                                                                                {greatGrandchildPage.hasChild === 'y' &&
                                                                            
                                                                                    greatGrandchildPage.pages.map((greatGreatGrandchildPage) => {
                                                                                        
                                                                                        return (
                                                                                            <ul key={greatGreatGrandchildPage.id} className="DocumentationMenu__list">

                                                                                                <li className="DocumentationMenu__list__item DocumentationMenu__list__item-greatGreatGrandchildPage" onClick={() => {
                                                                                                    setSelected(greatGreatGrandchildPage)
                                                                                                    }}> - {greatGreatGrandchildPage.name} {greatGreatGrandchildPage.is_homepage === 'y' ? ' *' : ''}
                                                                                                </li>

                                                                                            </ul>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            
                                                                            </ul>
                                                                        )
                                                                    })
                                                                }

                                                            
                                                        </ul>

                                                        )
                                                    })
                                                }

                                            
                                        </ul>
                                    )
                                })
                            
                            }
						
                        </li>
					);
				})}

            </ul>

		</div>
	);
}

export default DocmumentatioMenu;