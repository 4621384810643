// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import AccountsList from 'components/Lists/Accounts';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const AccountListScreen = () => {

	// State
	const [accounts, setAccounts] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		Api.accounts.list({
			success: (response) => {
				setAccounts(response.data);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title="Accounts">
				<Button url="/accounts/create" label="Create Account" colour="blue"/>
			</ScreenTitle>

			<AccountsList items={accounts}/>

		</>
	);
}

export default AccountListScreen;