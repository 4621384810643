// Packages
import { Link } from 'react-router-dom';


// Components
import EmptyState from 'components/Lists/EmptyState';


// Other
import Api from 'other/Api';


const LinksList = props => {
    
    const { items, onChange, showSource } = props;


    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no links to display here"/>);
    }


    const deleteLink = (id) => {

        if ( ! window.confirm('Are you sure that you want to delete this link?') )
        {
            return;
        }

        Api.links.delete(id, {
            success: (response) => {
                onChange();
            }
        })
    }

    return (
        <table className="table">
        <thead>
        <tr>
            <th>Name</th>
            <th>URL</th>
            { showSource &&
                <th>Source</th>
            }
            <th></th>
        </tr>
        </thead>
        <tbody>

            {items.map((item) => {

                return (

                    <tr key={item.id}>
                        <td><Link to={`/links/${item.id}`}><strong>{item.name}</strong></Link></td>
                        <td><a href={item.link} target="_blank" rel="noreferrer">{item.link}</a></td>
                        { item.account &&
                            <td>{item.account.name}</td>
                        }
                        { item.documentation &&
                            <td>{item.documentation.name}</td>
                        }
                        <td><div onClick={() => {
                            deleteLink(item.id);
                        }} className="table-delete"/></td>
                    </tr>

                );

            })}
        </tbody>
        </table>
    );
}

LinksList.defaultProps = {
    showSource: false
}

export default LinksList;