// Packages
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

// Components
import Duration from 'components/Duration';

const TimesList = props => {
	
	const { items } = props;

	return (
		<table className="table">
		<thead>
		<tr>
			<th>Date Started</th>
			<th>Job Name</th>
			<th>Account Name</th>
			<th>Contract</th>
			<th>User</th>
			<th>Time Taken</th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (

					<tr key={item.id}>
						<td><Link to={`/times/${item.id}/edit`}>{dayjs(item.started_at).format('DD MM YYYY')}</Link></td>
						<td><Link to={`/jobs/${item.job_id}`}><strong>{item.job.name}</strong></Link></td>
						<td><Link to={`/accounts/${item.account_id}`}><strong>{item.account.name}</strong></Link></td>
						<td><Link to={`/contracts/${item.contract_id}`}><strong>{item.contract ? item.contract.name : ''}</strong></Link></td>
						<td>{item.user.name}</td>
						<td><Duration seconds={item.seconds}/></td>
					</tr>

				);

			})}
		</tbody>
		</table>
	);
}

export default TimesList;