// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';


// Other
import Api from 'other/Api';


const DocumentationForm = props => {

	// Props
	const { documentationId } = props;
	
	// State - Form fields
	const [name, setName] = useState('');

	// State
	const [loading, setLoading] = useState(documentationId);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {


		/**	
		 * Build generic request to either create or update
		 * the documentation
		 */
		const request = {
			data: {
				name: name,
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				navigate('/documentations/' + response.data.id);
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( documentationId )
		{
			// Update existing documentation
			Api.documentations.update(documentationId, request);
	    }
	    else
	    {
	    	// Add a new documentation
	    	Api.documentations.create(request);
	    }
	}


	useEffect(() => {

		if ( documentationId )
		{
			/**
			 * Load the documentation into the form
			 */
	        Api.documentations.get(documentationId, {
	            success: (response) => {
					setName(response.data.name);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

    }, [documentationId]);


	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>
			
			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>
			

			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Documentation"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
DocumentationForm.propTypes = {
	documentationId: PropTypes.string
}


export default DocumentationForm;