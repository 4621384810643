// Packages
import dayjs from 'dayjs';

import './styles.css';


const MonitorChecksList = props => {
	
	const { items } = props;

	return (
		<table className="table monitorChecksTable">
		<thead>
		<tr>
			<th>Date / Time</th>
			<th className='c'>Status</th>
			<th className='c'>Response Time</th>
			<th className='c'>Rsponse Code</th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (

					<tr key={item.id}>
						<td>{dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}</td>
						<td className='c'>{item.status}</td>
						<td className='c'>{item.response_time}</td>
						<td className='c'>{item.response_code}</td>
					</tr>

				);

			})}
		</tbody>
		</table>
	);
}

export default MonitorChecksList;