// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import nl2br from 'react-nl2br';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import InfoPanel from 'components/UI/InfoPanel';

// Others
import Api from 'other/Api';


// Assets
import './styles.css';


const RecurringJobViewScreen = () => {

	// URL Parameters
	const { recurringJobId } = useParams();


	// State
	const [recurringJob, setRecurringJob] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {

		Api.recurringjobs.get(recurringJobId, {
			params: {
				with: ['account', 'contract', 'person', 'user', 'interval']
			},
			success: (response) => {
				setRecurringJob(response);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, [recurringJobId]);

    const stopRecurringJob = () => {
        Api.recurringjobs.stop(recurringJobId, {
            success: () => {
                window.reload();
            },
            error: (response) => {
                alert("Error stopping recurring job: " + response.message);
            }
        })
    }

    const restartRecurringJob = () => {
        Api.recurringjobs.restart(recurringJobId, {
            success: () => {
                window.reload();
            },
            error: (response) => {
                alert("Error restarting recurring job: " + response.message);
            }
        })
    }


	if ( loading )
	{
		return <Loading/>;
	}



    const startFrom = recurringJob.start_from !== null ? dayjs(recurringJob.start_from).format('ddd D MMM YYYY [at] HH:mm') : '--';
    const lastCreation = recurringJob.last_creation_at !== null ? dayjs(recurringJob.last_creation_at).format('ddd D MMM YYYY [at] HH:mm') : '--';
    const nextCreation = recurringJob.next_creation_at !== null ? dayjs(recurringJob.next_creation_at).format('ddd D MMM YYYY [at] HH:mm') : '--';


	return (
		<>
			<ScreenTitle title={recurringJob.name} breadcrumb={[
				{label: 'Recurring Jobs', url: '/recurringjobs'}
			]}>
				<Button url={`/recurringjobs/${recurringJob.id}/edit`} label="Edit Recurring Job" colour="blue"/>

                { recurringJob.is_active && 
                    <Button onClick={() => {

                        if ( window.confirm('Are you sure you want to pause this recurring job?') )
                        {
                            stopRecurringJob();
                        }

                    }} label="Pause Recurring Job" colour="grey"/>
                }

                { ! recurringJob.is_active && 
                    <Button onClick={() => {

                        if ( window.confirm('Are you sure you want to restart this recurring job?') )
                        {
                            restartRecurringJob();
                        }

                    }} label="Restart Recurring Job" colour="orange"/>
                }



			</ScreenTitle>

			
			<InfoPanel.Wrapper>

				<InfoPanel.Column>
					<InfoPanel.Item label="Account" value={recurringJob.account !== null ? recurringJob.account.name : ''} link={`/accounts/${recurringJob.account_id}`}/>
					{ recurringJob.person &&
						<InfoPanel.Item label="Person" value={`${recurringJob.person !== null ? recurringJob.person.firstname : ''} ${recurringJob.person !== null ? recurringJob.person.lastname : ''}`} link={`/persons/${recurringJob.person_id}`}/>
					}

					{ recurringJob.contract &&
						<InfoPanel.Item label="Contract" value={recurringJob.contract !== null ? recurringJob.contract.name : ''} link={`/contracts/${recurringJob.contract_id}`}/>
					}

					<InfoPanel.Item label="Billing Status" value={recurringJob.billing_status ?? ''}/>
				</InfoPanel.Column>


				<InfoPanel.Column>
					<InfoPanel.Item label="Start From" value={startFrom}/>
				    <InfoPanel.Item label="Last Creation" value={lastCreation}/>
				    <InfoPanel.Item label="Next Creation" value={nextCreation}/>
				</InfoPanel.Column>


                <InfoPanel.Column>
                    <InfoPanel.Item label="Interval" value={recurringJob.interval.name !== null ? recurringJob.interval.name : ''}/>
                    <InfoPanel.Item label="Period" value={recurringJob.period}/>

					<InfoPanel.Item label="Assigned To" value={recurringJob.user ? recurringJob.user.name : '--'}/>
                </InfoPanel.Column>

			</InfoPanel.Wrapper>




			{ recurringJob.description &&
				<InfoPanel.Wrapper title="Description">
					<InfoPanel.Item value={nl2br(recurringJob.description)}/>
				</InfoPanel.Wrapper>
			}

		</>
	);
}

export default RecurringJobViewScreen;