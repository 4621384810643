// Packages


// Components
import Item from './Item';
import EmptyState from 'components/Lists/EmptyState';


// Assets
import './styles.css';



const JobsList = props => {
	
	const { items } = props;


    if ( items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no jobs to display here"/>);
    }

	return (
        <div className="JobsList">

            {items.map((item) => {

                return (
                    <Item key={item.id} job={item}/>
                );

            })}

        </div>
	);
}

export default JobsList;