// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';


// Others
import Api from 'other/Api';


const LinkViewScreen = () => {

    // URL Parameters
    const { linkId } = useParams();


    // State
    const [link, setLink] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        Api.links.get(linkId, {
            params: {
                with: ['account', 'documentation']
            },
            success: (response) => {
                setLink(response);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, [linkId]);


    if ( loading )
    {
        return <Loading/>;
    }

    return (
        <>
            <ScreenTitle title={`${link.name}`} breadcrumb={[
                {label: 'Links', url: '/links'}
            ]}>
                <Button url={`/links/${link.id}/edit`} label="Edit Link" colour="blue"/>
            </ScreenTitle>

            {link.account && 
            
                <h2>Belongs to: {link.account.name}</h2>
            }

            {link.documentation && 
            
                <h2>Belongs to: {link.documentation.name}</h2>
            }



            

        </>
    );
}

export default LinkViewScreen;