// Packages
import { useState, useEffect } from 'react';

// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import StageList from 'components/Lists/Stage';
import Button from 'components/UI/Button';
import Message from 'components/UI/Message';


// Others
import Api from 'other/Api';

// Assets
import './styles.css';


const StageListScreen = () => {

	// State
	const [stages, setStages] = useState([]);
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');


	useEffect(() => {

		Api.stages.list({
			params: {
				with: ['pipeline']
			},
			success: (response) => {
				setStages(response.data);
			},
			error: (error) => {
				setErrorMessage(error.message);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);





	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>

			{errorMessage &&
				<Message colour="red" text={errorMessage}/>
			}

			<ScreenTitle title="Stages">
				<Button url="/stages/create" label="Create Stage" colour="blue"/>
			</ScreenTitle>

			<StageList items={stages}/>


		</>
	);
}

export default StageListScreen;