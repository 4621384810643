// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Contract';


const Screen = () => {

    return (
        <>
        <ScreenTitle title="Create Contract" breadcrumb={[
            {label: 'Contracts', url: '/contracts'}
        ]}/>

        <Form/>
        </>
    )
}

export default Screen;