// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';


// Other
import Api from 'other/Api';


const LinkForm = props => {

    // Props
    const { linkId } = props;
    

    // URL parameters
    const [ queryParams ] = useSearchParams();


    // State - Form fields
    const [name, setName] = useState('');
    const [accountId, setAccountId] = useState(queryParams.get('account_id')??null);
    const [contractId, setContractId] = useState(queryParams.get('contract_id')??null);
    const [jobId, setJobId] = useState(queryParams.get('job_id')??null);
    const [documentationId, setDocumentationId] = useState(queryParams.get('documentation_id')??null);
    const [link, setLink] = useState('');


    // State
    const [loading, setLoading] = useState(linkId);
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState({});


    const navigate = useNavigate();

    
    /**
     * Form submit
     */
    const submit = () => {

        /** 
         * Build generic request to either create or update
         * the contract
         */
        const request = {
            data: {
                name,
                link,
                account_id: accountId,
                contract_id: contractId,
                job_id: jobId,
                documentation_id: documentationId
            },
            before: () => {
                setSaving(true);
            },
            successRedirect: () => {
                if( accountId ) {
                    navigate('/accounts/' + accountId);
                }
                else if( contractId ) {
                    navigate('/contracts/' + contractId);
                }
                else if( jobId ) {
                    navigate('/jobs/' + jobId);
                }
                else if( documentationId ) {
                    navigate('/documentations/' + documentationId);
                }
                else {
                    navigate('/links');
                }
            },
            error: (error) => {
                setErrorMessage(error.message);
                setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
        };

        if ( linkId )
        {
            // Update existing link
            Api.links.update(linkId, request);
        }
        else
        {
            // Add a new link
            Api.links.create(request);
        }
    }


    useEffect(() => {

        if ( linkId )
        {
            /**
             * Load the link into the form
             */
            Api.links.get(linkId, {
                success: (response) => {
                    setName(response.name);
                    setLink(response.link);
                    setAccountId(response.account_id);
                    setContractId(response.contract_id);
                    setJobId(response.job_id);
                    setDocumentationId(response.documentation_id);
                },
                error: (error) => {
                    setErrorMessage(error.message);
                },
                after: () => {
                    setLoading(false);
                }
            });
        }
        
    }, [linkId]);


    if ( loading )
    {
        return <Loading/>
    }


    return (
        <>
        
        {errorMessage &&
            <Message colour="red" text={errorMessage}/>
        }
        
        <form className="form" autoComplete="off" onSubmit={(e) => {
            e.preventDefault();
            submit();
        }}>
            
            <div className={`form__field ${errors.name?'form__field--error':''}`}>
                <label className="form__field__label">Name</label>
                <input onChange={(e) => {
                    setName(e.target.value)
                }} name="name" value={name ?? ''} className="form__field__input"/>
                { errors.name &&
                    <div className="form__field__error">
                        {errors.name.join('<br/>')}
                    </div>
                }
            </div>

            <div className={`form__field ${errors.link?'form__field--error':''}`}>
                <label className="form__field__label">Link / URL:</label>
                <input onChange={(e) => {
                    setLink(e.target.value)
                }} name="link" value={link ?? ''} className="form__field__input"/>
                { errors.link &&
                    <div className="form__field__error">
                        {errors.link.join('<br/>')}
                    </div>
                }
            </div>




            <div className="form__footer">
                { saving &&
                    <Saving/>
                }

                { ! saving &&
                    <Button label="Save Link"/>
                }
            </div>

        </form>
        </>
    );
}


// Prop Types
LinkForm.propTypes = {
    linkId: PropTypes.string
}


export default LinkForm;