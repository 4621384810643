// Packages
import { Link } from 'react-router-dom';

const StageList = props => {
	
	const { items } = props;

	return (
		<table className="table">
		<thead>
		<tr>
			<th>Name</th>
			<th>Colour</th>
			<th>Pipeline</th>
			<th>Sequence</th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (

					<tr key={item.id}>
						<td><Link to={`/stages/${item.id}/edit`}>{item.name}</Link></td>
						<td>{item.colour}</td>
						<td>{item.pipeline.name}</td>
						<td>{item.sequence}</td>
					</tr>

				);

			})}
		</tbody>
		</table>
	);
}

export default StageList;