// Packages
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import Stage from 'components/UI/Stage';
import JobPriority from 'components/Helpers/JobPriority';
import Avatar from 'components/Avatar';
import Duration from 'components/Duration';


// Assets
import './styles.css';



const Item = props => {
    
    const { job } = props;


    const dueDate = job.due_at !== null ? dayjs(job.due_at).format('DD MMM YYYY') : '';



    return (
        
        <div className="JobItem">

            <div className="JobItem__body">
                <div>
                    <div className="JobItem__name">
                        <Link to={`/jobs/${job.id}`}>{job.name ? job.name : ''}</Link>
                    </div>
                    <div className="JobItem__account">
                        { job.account &&
                            <> / {job.account.name}</>
                        }
                        { job.contract &&
                            <> / {job.contract.name}</>
                        }
                    </div>
                </div>

                <div>
                    { job.user &&
                        <Avatar
                            name={job.user ? job.user.name : ''}
                            showName={false}
                            size="small"
                        />
                    }
                </div>
            </div>

            <div className="JobItem__footer">

                <div className="JobItem__info">
                    <div className="JobItem__info__value"><Stage {...job.stage}/></div>
                </div>
            

                { job.priority !== 2 &&
                    <div className="JobItem__info">
                        <div className="JobItem__info__value"><JobPriority priority={job.priority}/></div>
                    </div>
                }
                

                { dueDate !== '' &&
                    <div className="JobItem__info">
                        <div className="JobItem__info__name">Due</div>
                        <div className="JobItem__info__value">
                            { job.is_overdue &&
                                <span style={{color: '#AE0303', fontWeight: 'bold'}}>{dueDate}</span>
                            }

                            { ! job.is_overdue &&
                                <>{dueDate}</>
                            }
                        </div>
                    </div>
                }

                { job.person &&
                    <div className="JobItem__info">
                        <div className="JobItem__info__name">With</div>
                        <div className="JobItem__info__value">{job.person.firstname} {job.person.lastname}</div>
                    </div>
                }

                { job.seconds_taken > 0 &&
                    <div className="JobItem__info JobItem__info-time">
                        <div className="JobItem__info__value"><Duration seconds={job.seconds_taken}/></div>
                    </div>
                }


            
            </div>

        </div>
    );
}

export default Item;