// Packages
import { Link } from 'react-router-dom';


// Components
import EmptyState from 'components/Lists/EmptyState';


const PersonsList = props => {
	
	const { items } = props;

    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no people to display here"/>);
    }

	return (
		<table className="table">
		<thead>
		<tr>
			<th>Name</th>
			{ items[0] && items[0].account &&
				<th>Account</th>
			}
			<th>Email</th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (

					<tr key={item.id}>
						<td><Link to={`/persons/${item.id}`}><strong>{item.firstname} {item.lastname}</strong></Link><br/>
							<small>{item.position}</small></td>
						{ item.account &&
							<td><Link to={`/accounts/${item.account_id}`}>{item.account.name}</Link></td>
						}
						<td>{item.email}</td>
					</tr>

				);

			})}
		</tbody>
		</table>
	);
}

export default PersonsList;