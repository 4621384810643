// Packages
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';


// Other
import Api from 'other/Api';


const StageForm = props => {

	// Props
	const { stageId } = props;


	// State - Form fields
	const [name, setName] = useState('');
	const [colour, setColour] = useState('');
	const [pipeline, setPipeline] = useState('');
	const [sequence, setSequence] = useState(null);

    
	// state - Select options
	const [pipelineOptions, setPipelineOptions] = useState([]);
	

	// State
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {

		/**	
		 * Build generic request to either create or update
		 * the stage
		 */
		const request = {
			data: {
				'name': name,
				'colour': colour,
				'pipeline_id': pipeline,
				'sequence': sequence,
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				navigate('/pipelines');
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( stageId )
		{
			// Update existing stage
			Api.stages.update(stageId, request);
	    }
	    else
	    {
	    	// Add a new time
	    	Api.stages.create(request);
	    }
	}

	useEffect(() => {

		// Load pipeline options
		Api.pipelines.list({
			params: {
			},
			success: (response) => {
				setPipelineOptions(response.data);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, []);


	useEffect(() => {

		if ( stageId )
		{
			/**
			 * Load the stage into the form
			 */
	        Api.stages.get(stageId, {
	            success: (response) => {
	                setName(response.name);
					setColour(response.colour);
					setPipeline(response.pipeline_id);
					setSequence(response.sequence);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

    }, [stageId]);


	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>

			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.colour?'form__field--error':''}`}>
				<label className="form__field__label">Colour</label>
				<input onChange={(e) => {
					setColour(e.target.value)
				}} name="colour" value={colour ?? ''} className="form__field__input"/>
				{ errors.colour &&
					<div className="form__field__error">
						{errors.colour.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.pipeline?'form__field--error':''}`}>
				<label className="form__field__label">Pipeline</label>
				<SelectList options={pipelineOptions} blank={false} onChange={(e) => {
					setPipeline(e.target.value);
				}} value={pipeline}/>
				{ errors.pipeline &&
					<div className="form__field__error">
						{errors.pipeline.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.sequence?'form__field--error':''}`}>
				<label className="form__field__label">Sequence</label>
				<input type="text" pattern="[0-9]*" onInput={(e) => {
					setSequence(e.target.value)
				}} name="sequence" value={sequence ?? ''} className="form__field__input"/>
				{ errors.sequence &&
					<div className="form__field__error">
						{errors.sequence.join('<br/>')}
					</div>
				}
			</div>





			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Stage"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
StageForm.propTypes = {
	stageId: PropTypes.string
}


export default StageForm;