// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import UsersList from 'components/Lists/Users';


// Others
import Api from 'other/Api';


const UserListScreen = () => {

    // State
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        Api.users.list({
            params: {
            },
            success: (response) => {
                setUsers(response.data);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, []);


    if ( loading )
    {
        return <Loading/>;
    }

    return (
        <>
            <ScreenTitle title="Users"/>

            <UsersList items={users}/>

        </>
    );
}

export default UserListScreen;