// Packages
import { useParams, useNavigate } from 'react-router-dom';



// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Template';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { templateId } = useParams();

    const navigate = useNavigate();


    const deleteTemplate = () => {

        if ( ! window.confirm('Are you sure that you want to delete this template?') )
        {
            return false;
        }

        Api.templates.delete(templateId, {
            successRedirect: () => {
                
                navigate('/templates');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Template"/>

        <Form templateId={templateId}/>

        <Button onClick={deleteTemplate} type="delete" label="Delete Template"/>
        </>
    )
}

export default EditScreen;