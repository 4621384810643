// Packages
import { useParams, useNavigate } from 'react-router-dom';



// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Documentation';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { documentationId } = useParams();

    const navigate = useNavigate();


    const deleteDocumentation = () => {

        if ( ! window.confirm('Are you sure that you want to delete this documentation?') )
        {
            return false;
        }

        Api.documentations.delete(documentationId, {
            successRedirect: () => {
                
                navigate('/documentations');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Documentation"/>

        <Form documentationId={documentationId}/>

        <Button onClick={deleteDocumentation} type="delete" label="Delete Documentation"/>
        </>
    )
}

export default EditScreen;