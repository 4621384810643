// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import UnbilledList from 'components/Lists/Jobs/Unbilled';
import Tabs from 'components/UI/Tabs';
import Button from 'components/UI/Button';


// Assets
import './styles.css';

// Others
import Api from 'other/Api';
import Message from 'components/UI/Message';


const ReportListScreen = () => {

	// State
	const [unbilledJobs, setUnbilledJobs] = useState([]);
	const [unbilledLoading, setUnbilledLoading] = useState(true);
	const [total, setTotal] = useState(null);
	const [successMessage, setSuccessMessage] = useState(null);

	useEffect(() => {

		// Load jobs
		Api.jobs.listUnbilled({
			params: {
				with: ['account', 'stage', 'user', 'contract'],
			},
			success: (response) => {
				setUnbilledJobs(response.data.filter(removeContractedJobs));
				// setUnbilledJobs(response.data);
			},
			after: () => {
				setUnbilledLoading(false);
			}
		});

	}, []);

	// Filter out contracted hours jobs
	function removeContractedJobs(job) {

		return job.contract ? job.contract.seconds === 0: true;
	}
	useEffect(() => {

		if( unbilledJobs ) {


			// setUnbilledJobs(unbilledJobs.filter(removeContractedJobs));
			
			// Calculate total
			let totalPrice = 0;
				for( let i = 0; i < unbilledJobs.length; i++ ) {
					totalPrice += parseFloat(unbilledJobs[i].price);
				}
				setTotal(totalPrice);
		}
	}, [unbilledJobs]);


	function markAsBilled() {

		var checkboxes = document.getElementsByName('unbilled');
		var checkboxesChecked = [];

		for (var i=0; i<checkboxes.length; i++) {
		
			if (checkboxes[i].checked) {
				let id = parseInt(checkboxes[i].id.replace('unbilled-', ''));
				checkboxesChecked.push(id);
			}
		}
		

		Api.jobs.markBilled({
			data: {
				'jobIds': checkboxesChecked
			},
			success: (response) => {
				setSuccessMessage(response);

				var filteredJobs = unbilledJobs.filter(job => ! checkboxesChecked.includes(job.id));
				setUnbilledJobs(filteredJobs);
			}
		});



		
	}


	return (
		<>
			<ScreenTitle title="Reports"></ScreenTitle>

			{successMessage && 
			
				<Message text={successMessage} colour="green"/>
			}

			<Tabs.Wrapper defaultTab="unbilled" tabs={[
				{name: 'unbilled', label: 'Unbilled Jobs'},
			]}>

				<Tabs.Panel name="unbilled">

					{ unbilledLoading &&

						<Loading message="Loading unbilled jobs"/>
					}
					{ ! unbilledLoading && unbilledJobs.length > 0 &&
						<>
						
						<UnbilledList items={unbilledJobs}/>
					

						{total > 0 &&
				
							<p className='Reports__list__total'>Total unbilled revenue: £{total.toFixed(2)}</p>
							
						}

						<Button label='Save checked as billed' style={{marginTop: '20px'}} onClick={() => {
							markAsBilled();
						}} />

						</>
					}

					{ unbilledJobs.length === 0 && 
					
						<div className='Reports__message-noItems'>There are currently no unbilled jobs to display.</div>

					}
				
				</Tabs.Panel>



			</Tabs.Wrapper>

		</>
	);
}

export default ReportListScreen;