// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';


// Other
import Api from 'other/Api';


// Assets
import './styles.css';


const TemplateForm = props => {

	// Props
	const { templateId } = props;
	

	// State - Form fields
	const [name, setName] = useState('');
	const [type, setType] = useState('email');
	const [body, setBody] = useState('');


	// State
	const [loading, setLoading] = useState(templateId);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();


	/**
	 * Form submit
	 */
	const submit = () => {


		/**	
		 * Build generic request to either create or update
		 * the template
		 */
		const request = {
			data: {
				name, 
				body: body,
				type
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: () => {
				navigate('/templates');
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( templateId )
		{
			// Update existing template
			Api.templates.update(templateId, request);
	    }
	    else
	    {
	    	// Add a new template
	    	Api.templates.create(request);
	    }
	}


	useEffect(() => {

		if ( templateId )
		{
			/**
			 * Load the template into the form
			 */
	        Api.templates.get(templateId, {
	            success: (response) => {
	                setName(response.name);
	               	setType(response.type);
					setBody(response.body);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }	    

    }, [templateId]);


	if ( loading )
	{
		return <Loading/>
	}




	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>


		<div className={`form__field ${errors.type?'form__field--error':''}`}>
			<label className="form__field__label">Type</label>
			<select onChange={(e) => {
				setType(e.target.value)
			}} value={type ?? ''} name="type" className="form__field__input form__field__input--size-small">
				<option value="email">Email</option>
				<option value="pdf">PDF</option>
			</select>

			{ errors.type &&
				<div className="form__field__error">
					{errors.type.join('<br/>')}
				</div>
			}
		</div>

			
			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>

		
			<div className='form__field__textEditor'>
				<Editor
					apiKey={'zk1783nnv3566cb03f9k4ze615o2ofnhtezwz0jobn5c790t'}
					value={body}
					onEditorChange={(e) => setBody(e)}
					init={{
						menubar: true,
						plugins: [
						'advlist', 'autolink', 'lists', 'link', 'charmap',
						'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
						'insertdatetime', 'media', 'table', 'help', 'wordcount'
						],
						toolbar: 'undo redo | blocks | ' +
						'bold italic forecolor | alignleft aligncenter ' +
						'alignright alignjustify | bullist numlist outdent indent | ' +
						'removeformat | help',
					content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
					}}
				/>
			</div>


			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Template"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
TemplateForm.propTypes = {
	templateId: PropTypes.string
}


export default TemplateForm;