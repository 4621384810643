// Packages
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';


// Other
import Api from 'other/Api';


const AccountForm = props => {

	// Props
	const { accountId } = props;


	// State - Form fields
	const [name, setName] = useState('');
	const [countryId, setCountryId] = useState(null);
	const [address, setAddress] = useState('');
	const [city, setCity] = useState('');
	const [postcode, setPostcode] = useState('');
	const [timeRounding, setTimeRounding] = useState('');
    const [domains, setDomains] = useState('');


	// state - Select options
	const [ countryOptions, setCountryOptions] = useState(null);
	const [ timeRoundingOptions, setTimeRoundingOptions] = useState({});


	// State
	const [loading, setLoading] = useState(accountId);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {

		/**	
		 * Build generic request to either create or update
		 * the account
		 */
		const request = {
			data: {
				name,
				address,
				city,
				postcode,
				country_id: countryId,
				time_rounding: parseInt(timeRounding),
                domains: domains.split("\n")
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				navigate('/accounts/' + response.id);
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( accountId )
		{
			// Update existing account
			Api.accounts.update(accountId, request);
	    }
	    else
	    {
	    	// Add a new account
	    	Api.accounts.create(request);
	    }
	}


	useEffect(() => {

		if ( accountId )
		{
			/**
			 * Load the account into the form
			 */
	        Api.accounts.get(accountId, {
	            success: (response) => {
	                setName(response.name);
	                setAddress(response.address);
	                setCity(response.city);
	                setPostcode(response.postcode);
					setCountryId(response.country_id);
					setTimeRounding(response.time_rounding);
                    setDomains(response.domains.join("\n"));
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

	    

    }, [accountId]);


    useEffect(() => {

    	/**
		 * Load the country options
		 */
        Api.countries.list({
        	params: {
        		per_page: 500
        	},
            success: (response) => {
                setCountryOptions(response.data);
            },
            error: (error) => {
                setErrorMessage(error.message);
            },
            after: () => {
                setLoading(false);
            }
        });


    }, []);


	useEffect(() => {

		/**
		 * Load time rounding options
		 */
		setLoading(true);

		setTimeRoundingOptions([
			{id: 0, name: '0'},
			{id: 5, name: '5'},
			{id: 15, name: '15'},
			{id: 30, name: '30'},
			{id: 60, name: '60'}
		]);

		setLoading(false);

	}, []);


	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" autoComplete="off" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>
			
			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.address?'form__field--error':''}`}>
				<label className="form__field__label">Address</label>
				<textarea onChange={(e) => {
					setAddress(e.target.value)
				}} name="address" rows="4" value={address ?? ''} className="form__field__input"/>
				{ errors.address &&
					<div className="form__field__error">
						{errors.address.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.city?'form__field--error':''}`}>
				<label className="form__field__label">City</label>
				<input onChange={(e) => {
					setCity(e.target.value)
				}} name="city" value={city ?? ''} className="form__field__input"/>
				{ errors.city &&
					<div className="form__field__error">
						{errors.city.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.postcode?'form__field--error':''}`}>
				<label className="form__field__label">Postcode</label>
				<input onChange={(e) => {
					setPostcode(e.target.value)
				}} name="postcode" value={postcode ?? ''} className="form__field__input"/>
				{ errors.postcode &&
					<div className="form__field__error">
						{errors.postcode.join('<br/>')}
					</div>
				}
			</div>


			<div className={`form__field ${errors.country_id?'form__field--error':''}`}>
				<label className="form__field__label">Country</label>
				<SelectList options={countryOptions} blank={true} onChange={(e) => {
					setCountryId(e.target.value);
				}} value={countryId}/>
				{ errors.country_id &&
					<div className="form__field__error">
						{errors.country_id.join('<br/>')}
					</div>
				}
			</div>

			{timeRoundingOptions.length > 0 && 
				<div className={`form__field ${errors.time_rounding?'form__field--error':''}`}>
					<label className="form__field__label">Time Rounding</label>
					<SelectList options={timeRoundingOptions} blank={true} onChange={(e) => {
						setTimeRounding(e.target.value);
					}} value={timeRounding}/>
					{ errors.time_rounding &&
						<div className="form__field__error">
							{errors.time_rounding.join('<br/>')}
						</div>
					}
				</div>
			}

            <div className={`form__field ${errors.domains?'form__field--error':''}`}>
                <label className="form__field__label">Domains - One per line</label>
                <textarea onChange={(e) => {
                    setDomains(e.target.value)
                }} name="domains" rows="4" value={domains ?? ''} className="form__field__input"/>
                { errors.domains &&
                    <div className="form__field__error">
                        {errors.domains.join('<br/>')}
                    </div>
                }
            </div>



			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Account"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
AccountForm.propTypes = {
	accountId: PropTypes.string
}


export default AccountForm;