// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import CreateScreen from 'screens/DocumentationPages/Create';
import EditScreen from 'screens/DocumentationPages/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const DocumentationPagesRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/" element={<CreateScreen/>}/>
				<Route exact path="/:documentationPageId/edit" element={<EditScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default DocumentationPagesRoutes