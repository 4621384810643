// Packages
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import dayjs from 'dayjs';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import Stage from 'components/UI/Stage';
import Duration from 'components/Duration';
import SelectList from 'components/UI/SelectList';


// Others
import Api from 'other/Api';


const ContractStatementScreen = () => {

    // URL Parameters
    const { contractId } = useParams();


    // State
    const [contract, setContract] = useState([]);
    const [loading, setLoading] = useState(true);

    const [statement, setStatement] = useState({});
    const [statementLoading, setStatementLoading] = useState(true);

    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);

    // Select options
    const [yearOptions, setYearOptions] = useState([]);
    const [monthOptions, setMonthOptions] = useState([]);


    useEffect(() => {

        // Set month options

        setMonthOptions([
            {id: 1,
            name: 'January'
            },
            {id: 2,
            name: 'February'
            },
            {id: 3,
            name: 'March'
            },
            {id: 4,
            name: 'April'
            },
            {id: 5,
            name: 'May'
            },
            {id: 6,
            name: 'June'
            },
            {id: 7,
            name: 'July'
            },
            {id: 8,
            name: 'August'
            },
            {id: 9,
            name: 'September'
            },
            {id: 10,
            name: 'October'
            },
            {id: 11,
            name: 'November'
            },
            {id: 12,
            name: 'December'
            }
        ]);

        setMonth((dayjs().month()) + 1);

    }, []);

    useEffect(() => {

        // Set year options
        let options = [];
        let year = dayjs().format('YYYY');
        for(let i=1; i<11; i++) {

            options.push({id: i, name: year});
            year = (parseInt(year) - 1).toString();
        }

        setYearOptions(options);
        setYear(1);

    }, []);





    const downloadPdf = () => {
        Api.contracts.statementPdf(contractId, {
            params: {
                'year': year,
                'month': month,
            },
            success: (response) => {
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', contract.name + '.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            }
        });
    }


    useEffect(() => {

        Api.contracts.get(contractId, {
            params: {
                with: ['account'],
            },
            success: (response) => {
                setContract(response);
            },
            after: () => {
                setLoading(false);
            }
        });

    }, [contractId]);


    useEffect(() => {

        Api.contracts.statement(contractId, {
            params: {
                'year': year,
                'month': month,
            },
            success: (response) => {
                setStatement(response);
            },
            after: () => {
                setStatementLoading(false);
            }
        });

    }, [contractId, year, month]);





    if ( loading )
    {
        return <Loading/>;
    }


    return (
        <>
            <ScreenTitle title={`Statement - ${contract.name}`} breadcrumb={[
                {label: 'Contracts', url: '/contracts'},
                {label: contract.name, url: '/contracts/' + contract.id },
                {label: 'Statement', url: '/contracts/' + contract.id + '/statement' }
            ]}>
                <Button onClick={() => {
                    downloadPdf()
                }} label="Download PDF" colour="blue"/>
            </ScreenTitle>
            
            <h3 className='statement__subtitle'>Filters:</h3>

            <div className='statement__filters'>

				<div className="statement__filters__filter">
					<label className="statement__filters__filter__label form__field__label">Month</label>
					<SelectList options={monthOptions} blank={false} onChange={(e) => {
						setMonth(e.target.value);
					}} value={month} />
				</div>

                <div className="statement__filters__filter">
					<label className="statement__filters__filter__label form__field__label">Year</label>
					<SelectList options={yearOptions} blank={false} onChange={(e) => {
						setYear(e.target.value);
					}} value={year} />
				</div>
				
			</div>


            { ! statementLoading && statement.times &&

                <table className="table">
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Requestor</th>
                    <th>Assigned To</th>
                    <th className="r">Duration</th>
                    <th>Status</th>
                </tr>
                </thead>
                <tbody>

                {statement.times.map((time) => {

                    return (
                        <tr key={time.id}>
                            <td>{dayjs(time.started_at).format('DD/MM/YYYY')}</td>
                            <td><Link to={`/jobs/${time.job.id}`}>{time.job.name}</Link></td>
                            <td>{time.job.person ? time.job.person.firstname : ''} {time.job.person ? time.job.person.lastname : ''}</td>
                            <td>{time.user ? time.user.name : ''}</td>
                            <td className="r"><Duration seconds={time.seconds}/></td>
                            <td><Stage {...time.job.stage}/></td>
                        </tr>
                    );
                })}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan="4" className="r"><strong>Total:</strong></td>
                    <td className="r"><strong><Duration seconds={statement.seconds_used}/></strong></td>
                </tr>
                </tfoot>
                </table>
            }

            { ! statementLoading && ! statement.times &&

                <h4 className='statement_noJobsMessage'>There were no times recorded in this period.</h4>

            }


        </>
    );
}

export default ContractStatementScreen;