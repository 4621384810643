// Packages
import { useEffect, useState } from 'react';


// Context
import TimerContext from './index';


// Other
import Api from 'other/Api';


const TimerState = props => {

    // State
    const [ job, setJob ] = useState(null);
    const [ time, setTime ] = useState(null);
    const [ lastChange, setLastChange ] = useState(new Date())

    useEffect(() => {
        setLastChange(new Date());
    }, [job, time]);
    

    /**
     * Check in with the API to get the status of the user's timer
     */
    const checkTimer = () => {
        Api.timer.getStatus({
            success: (response) => {
                setJob(response.job ? response.job : null);
                setTime(response.time ? response.time : null);
            },
            after: () => {
                setTimeout(() => {
                    checkTimer()
                }, 60000);
            }
        });
    }

    const stopTimer = () => {
        Api.timer.stop({
            success: (response) => {
                setTime(null);
                setJob(null);
            }
        });
    }

    const pauseTimer = () => {
        Api.timer.pause({
            success: (response) => {
                setTime(null);
            }
        });
    }

    const continueTimer = () => {
        Api.timer.continue({
            success: (response) => {
                setTime(response);
            }
        });
    }


    useEffect(() => {
        checkTimer();
        // eslint-disable-next-line
    }, []);


    return (
        <TimerContext.Provider value={{
            show: job !== null,
            job,
            time,
            check: checkTimer,
            stop: stopTimer,
            pause: pauseTimer,
            continue: continueTimer,
            lastChange
        }}>
        {props.children}
        </TimerContext.Provider>
    );


}

export default TimerState;