// Libraries
import PropTypes from 'prop-types';


// Components
import Breadcrumb from 'components/UI/Breadcrumb';


// Assets
import './styles.css';


const ScreenTitle = props => {

	// Props
	const { title, breadcrumb } = props;


	return (
		<div className="ScreenTitle">

			<div className="ScreenTitle__header">
				{ breadcrumb &&
					<Breadcrumb items={breadcrumb}/>
				}
				<h1 className="ScreenTitle__header__title">{title}</h1>
			</div>

			<div className="ScreenTitle__body">
				{props.children}
			</div>

		</div>
	);
}


// Prop Tyles
ScreenTitle.propTypes = {
	title: PropTypes.string.isRequired,
	breadcrumb: PropTypes.array
}


export default ScreenTitle;