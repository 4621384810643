// Packages
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import Button from 'components/UI/Button';
import InfoPanel from 'components/UI/InfoPanel';


// Others
import Api from 'other/Api';


const TemplateViewScreen = () => {

	// URL Parameters
	const { templateId } = useParams();


	// State
	const [template, setTemplate] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {

		Api.templates.get(templateId, {
			params: {
			},
			success: (response) => {
				setTemplate(response);
			},
			after: () => {
				setLoading(false);
			}
		});

	}, [templateId]);


	const downloadPdf = () => {
		Api.templates.pdf(templateId, {
			success: (response) => {
				const url = window.URL.createObjectURL(new Blob([response]));
    			const link = document.createElement('a');
    			link.href = url;
    			link.setAttribute('download', template.name + '.pdf'); //or any other extension
    			document.body.appendChild(link);
    			link.click();
			}
		});
	}


	if ( loading )
	{
		return <Loading/>;
	}

	return (
		<>
			<ScreenTitle title={`${template.name}`} breadcrumb={[
				{label: 'Templates', url: '/templates'}
			]}>
				<Button url={`/templates/${template.id}/edit`} label="Edit Template" colour="blue"/>
				{ template.type === 'pdf' &&
					<Button onClick={downloadPdf} label="Download PDF" colour="orange"/>
				}
			</ScreenTitle>

			<InfoPanel.Wrapper>
				<InfoPanel.Column>
					<InfoPanel.Item label="Template Type" value={template.type}/>
				</InfoPanel.Column>
			</InfoPanel.Wrapper>

			<InfoPanel.Wrapper title="Template">
				<InfoPanel.Item value={<div dangerouslySetInnerHTML={{ __html: template.body }} />}/>
			</InfoPanel.Wrapper>			

		</>
	);
}

export default TemplateViewScreen;