// Packages
import { useParams, useNavigate } from 'react-router-dom';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Form from 'components/Forms/Monitor';
import Button from 'components/UI/Button';


// API
import Api from 'other/Api';


const EditScreen = () => {

    // URL Params
    const { monitorId } = useParams();

    const navigate = useNavigate();


    const deleteMonitor = () => {

        if ( ! window.confirm('Are you sure that you want to delete this monitor?') )
        {
            return false;
        }

        Api.monitors.delete(monitorId, {
            successRedirect: () => {
                navigate('/monitors');
            }
        })
    }


    return (
        <>
        <ScreenTitle title="Edit Monitor"/>

        <Form monitorId={monitorId}/>

        <Button onClick={deleteMonitor} type="delete" label="Delete Monitor"/>
        </>
    )
}

export default EditScreen;