// Packages
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';


// Components
import Button from 'components/UI/Button';
import Loading from 'components/UI/Loading';
import Saving from 'components/UI/Saving';
import Message from 'components/UI/Message';
import SelectList from 'components/UI/SelectList';



// Other
import Api from 'other/Api';


const MonitorForm = props => {

	// Props
	const { monitorId } = props;


	// State - Form fields
	const [name, setName] = useState('');
	const [type, setType] = useState('http');
	const [host, setHost] = useState('');
	const [interval, setInterval] = useState(5);

    
	// state - Select options
	const typeOptions = [{id: 1, name: 'http'}];
	const intervalOptions = [
		{id: 5, name: 5},
		{id: 15, name: 15},
		{id: 30, name: 30},
		{id: 60, name: 60},
		{id: 1440, name: 1440},
	];
	

	// State
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [errors, setErrors] = useState({});


	const navigate = useNavigate();

	
	/**
	 * Form submit
	 */
	const submit = () => {

		/**	
		 * Build generic request to either create or update
		 * the monitor
		 */
		const request = {
			data: {
				'name': name,
				'type': type,
				'host': host,
				'interval': parseInt(interval),
			},
			before: () => {
				setSaving(true);
			},
            successRedirect: (response) => {
				navigate('/monitors/' + response.id);
			},
            error: (error) => {
             	setErrorMessage(error.message);
				setErrors(error.errors);
            },
            after: () => {
                setSaving(false);
            }
		};

		if ( monitorId )
		{
			// Update existing time
			Api.monitors.update(monitorId, request);
	    }
	    else
	    {
	    	// Add a new time
	    	Api.monitors.create(request);
	    }
	}


	useEffect(() => {

		if ( monitorId )
		{
			/**
			 * Load the time into the form
			 */
	        Api.monitors.get(monitorId, {
				before: () => {
					setLoading(true);
				},
	            success: (response) => {
	                setName(response.name);
					setType(response.type);
					setHost(response.host);
					setInterval(response.interval);
	            },
	            error: (error) => {
	                setErrorMessage(error.message);
	            },
	            after: () => {
	                setLoading(false);
	            }
	        });
	    }

	    

    }, [monitorId]);



	if ( loading )
	{
		return <Loading/>
	}


	return (
		<>
		
		{errorMessage &&
			<Message colour="red" text={errorMessage}/>
		}
		
		<form className="form" onSubmit={(e) => {
			e.preventDefault();
			submit();
		}}>

			<div className={`form__field ${errors.name?'form__field--error':''}`}>
				<label className="form__field__label">Monitor name</label>
				<input onChange={(e) => {
					setName(e.target.value)
				}} name="name" value={name ?? ''} className="form__field__input"/>
				{ errors.name &&
					<div className="form__field__error">
						{errors.name.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.type?'form__field--error':''}`}>
				<label className="form__field__label">Type</label>
				<SelectList options={typeOptions} blank={false} onChange={(e) => {
					setType(e.target.value);
				}} value={type}/>
				{ errors.type &&
					<div className="form__field__error">
						{errors.type.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.host?'form__field--error':''}`}>
				<label className="form__field__label">Host</label>
				<input onChange={(e) => {
					setHost(e.target.value)
				}} name="host" value={host ?? ''} className="form__field__input"/>
				{ errors.host &&
					<div className="form__field__error">
						{errors.host.join('<br/>')}
					</div>
				}
			</div>

			<div className={`form__field ${errors.interval?'form__field--error':''}`}>
				<label className="form__field__label">Interval</label>
				<SelectList options={intervalOptions} blank={false} onChange={(event) => {
					setInterval(event.target.value);
				}} value={interval}/>
				{ errors.interval &&
					<div className="form__field__error">
						{errors.interval.join('<br/>')}
					</div>
				}
			</div>


			<div className="form__footer">
				{ saving &&
					<Saving/>
				}

				{ ! saving &&
					<Button label="Save Monitor"/>
				}
			</div>

		</form>
		</>
	);
}


// Prop Types
MonitorForm.propTypes = {
	monitorId: PropTypes.string
}


export default MonitorForm;