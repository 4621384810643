// Libraries
import { Routes, Route } from "react-router-dom";


// Screens
import ListScreen from 'screens/RecurringJobs/List';
import ViewScreen from 'screens/RecurringJobs/View';
import CreateScreen from 'screens/RecurringJobs/Create';
import EditScreen from 'screens/RecurringJobs/Edit';


// Layouts
import DefaultLayout from 'layouts/Default';


const RecurringJobRoutes = () => 
{
	return (
		<DefaultLayout>
			<Routes>
				<Route exact path="/create" element={<CreateScreen/>}/>
				<Route exact path="/" element={<ListScreen/>}/>
				<Route exact path="/:recurringJobId" element={<ViewScreen/>}/>
				<Route exact path="/:recurringJobId/edit" element={<EditScreen/>}/>
			</Routes>
		</DefaultLayout>
	)
}


export default RecurringJobRoutes