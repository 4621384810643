// Packages
import PropTypes from 'prop-types';

// other
import './styles.css';

const Snackbar = ({ message, type }) => {

    return (
        <div className={`BTDSnackbar ${type}`}>
            {type === 'success' ?
                <div className='BTDSnackbar__icon BTDSnackbar__icon-tick'></div>
            :
                <div className='BTDSnackbar__icon BTDSnackbar__icon-exclamation'></div>
            }
            <span className="BTDSnackbar__text">{message}</span>
        </div>
    );
};

Snackbar.propTypes = {
    message: PropTypes.string,
    onClose: PropTypes.func,
    type: PropTypes.string
}

Snackbar.defaultProps = {
    message: '',
    onClose: () => {},
    type: 'info'
}

export default Snackbar;