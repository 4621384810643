// Packages
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


// Components
import EmptyState from 'components/Lists/EmptyState';


// Other
import { Currency } from 'other/Utilities';


const ContractsList = props => {
	
	const { items } = props;

	// State
	const [total, setTotal] = useState(0);

	// Calculate total
	useEffect(() => {

		if( items ) {

			var totalPrice = 0;

			for( let i = 0; i < items.length; i++ ) {


				if( items[i].interval === 'month' ) {

					totalPrice += (items[i].price * 12);
				} 
				else if( items[i].interval === 'quarter' ) {

					totalPrice += (items[i].price * 4);
				}
				else {

					totalPrice += items[i].price * 4;
				}
			}

			setTotal(totalPrice);
		}

	}, [items]);


    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no contracts to display here"/>);
    }

	return (
		<table className="table">
		<thead>
		<tr>
			<th>Name</th>
			{ items[0] && items[0].account &&
				<th>Account</th>
			}
			<th>Interval</th>
			<th className="r">Price</th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (

					<tr key={item.id}>
						<td><Link to={`/contracts/${item.id}`}><strong>{item.name}</strong></Link></td>
						{ item.account &&
							<td>{item.account.name}</td>
						}
						<td>{item.interval}</td>
						<td className="r">{Currency(item.price)}</td>
					</tr>

				);

			})}

			<tr>
				<td colSpan={4} className="r"><b>Total (year): {Currency(total)}</b></td>
			</tr>
		</tbody>
		</table>
	);
}

export default ContractsList;