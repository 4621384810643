// Packages
import { useState, useEffect } from 'react';


// Components
import ScreenTitle from 'components/UI/ScreenTitle';
import Loading from 'components/UI/Loading';
import JobsList from 'components/Lists/Jobs';
import Button from 'components/UI/Button';
// import Tabs from 'components/UI/Tabs';
import ListFilter from 'components/ListFilter';


// Others
import Api from 'other/Api';


const JobListScreen = () => {

	// State
	const [deliveryJobs, setDeliveryJobs] = useState([]);
	const [deliveryLoading, setDeliveryLoading] = useState(true);
    const [deliveryJobStatus, setDeliveryJobStatus] = useState('open');

	// const [salesJobs, setSalesJobs] = useState([]);
	// const [salesLoading, setSalesLoading] = useState(true);
    // const [salesJobStatus, setSalesJobStatus] = useState('open');


    // useEffect(() => {

    //     Api.jobs.list({
    //         params: {
    //             with: ['account', 'stage', 'user'],
    //             pipeline_id: 1,
    //             status: salesJobStatus
    //         },
    //         success: (response) => {
    //             setSalesJobs(response.data);
    //         },
    //         after: () => {
    //             setSalesLoading(false);
    //         }
    //     });

    // }, [salesJobStatus]);



	useEffect(() => {

		Api.jobs.list({
			params: {
				with: ['account', 'stage', 'user', 'contract', 'person'],
				pipeline_id: 2,
                status: deliveryJobStatus
			},
			success: (response) => {
				setDeliveryJobs(response.data);
			},
			after: () => {
				setDeliveryLoading(false);
			}
		});

	}, [deliveryJobStatus]);



	return (
		<>
			<ScreenTitle title="Jobs">
				<Button url="/jobs/create" label="Create Job" colour="blue"/>
			</ScreenTitle>


			{/* <Tabs.Wrapper defaultTab="delivery" tabs={[
				{name: 'delivery', label: 'Delivery Jobs'},
				{name: 'sales', label: 'Sales Jobs'}
			]}>

				<Tabs.Panel name="delivery"> */}

					{ deliveryLoading &&
						<Loading message="Loading delivery jobs"/>
					}

					{ ! deliveryLoading &&

                        <>

                        <ListFilter options={[
                            { name: 'open', label: 'Open Delivery Jobs', colour: 'orange' },
                            { name: 'closed', label: 'Closed Delivery Jobs', colour: 'green' },
                        ]} onChange={(option) => {
                            setDeliveryJobStatus(option);
                        }} selected={deliveryJobStatus}/>
						
                        <JobsList items={deliveryJobs}/>

                        </>
					}
				
				{/* </Tabs.Panel>

				<Tabs.Panel name="sales">

					{ salesLoading &&
						<Loading message="Loading sales jobs"/>
					}

					{ ! salesLoading &&
                        <>
                        <ListFilter options={[
                            { name: 'open', label: 'Open Sales Jobs', colour: 'orange' },
                            { name: 'closed', label: 'Closed Sales Jobs', colour: 'green' },
                        ]} onChange={(option) => {
                            setSalesJobStatus(option);
                        }} selected={salesJobStatus}/>

						<JobsList items={salesJobs}/>
					   </>
                    }
				
				</Tabs.Panel>


			</Tabs.Wrapper> */}

		</>
	);
}

export default JobListScreen;