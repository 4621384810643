// Packages
import { Link } from 'react-router-dom';

// Assets
import './styles.css';

const PipelineList = props => {
	
	const { items } = props;

	return (
		<table className="table table-pipelines">
		<thead>
		<tr>
			<th>Name</th>
			<th className='pipelines__centreColumn'>Is Default</th>
		</tr>
		</thead>
		<tbody>

			{items.map((item) => {

				return (

					<tr key={item.id}>
						<td><Link to={`/pipelines/${item.id}/edit`}>{item.name}</Link></td>
						<td className='pipelines__centreColumn'>{item.is_default}</td>
					</tr>

				);

			})}
		</tbody>
		</table>
	);
}

export default PipelineList;