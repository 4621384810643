// Packages
import { Link } from 'react-router-dom';


// Components
import EmptyState from 'components/Lists/EmptyState';


// Other
import { uppercaseFirstLetter } from 'other/Utilities';


// Assets
import './styles.css';


const SearchResultsList = props => {
    
    const { items, onResultClick } = props;


    if ( ! items || items.length === 0 )
    {
        return (<EmptyState 
                    message="There are no search results to display here"/>);
    }

    return (
        <div className="SearchResults">
        
            {items.map((item) => {

                let fields = [];

                for ( var name in item.search_fields )
                {
                    fields.push(item.search_fields[name]);
                }

                return (
                    <div key={`${item.type}-${item.id}`} className="SearchResults__result">
                        <Link onClick={() => {
                            onResultClick();
                        }} to={item.url}>
                        <div className="SearchResults__result__type">{uppercaseFirstLetter(item.type)}</div>
                        <div className="SearchResults__result__name">{item.name}</div>
                        <div className="SearchResults__result__text">{fields.join(' ')}</div> 
                        </Link>
                    </div>
                );

            })}
        </div>
    );
}

export default SearchResultsList;